import { AxiosRequestConfig } from 'axios';

import { API_URL } from 'config';

const config: AxiosRequestConfig = {
  baseURL: API_URL,
  transformResponse: (data) => {
    const json = JSON.parse(data) as ApiResponse;
    return json.data || json;
  },
};

export default config;
