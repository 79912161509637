import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import useAuth from 'hooks/useAuth';
import { stringifyUrlWithParam } from 'utils/routes';

import Text from 'shared_components/Text';
import { BidIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import { useOffersReceived } from 'api/offers';

type Props = {
  className?: string;
};

const OffersButton = (props: Props) => {
  const { className } = props;
  const history = useHistory();
  const { isAuth } = useAuth();

  const { data } = useOffersReceived();

  const total = data?.[0]?.total;

  const handleClick = useCallback(() => {
    history.push(stringifyUrlWithParam(history.location, { drawer: 'offers' }));
  }, [history]);

  return (
    <>
      {isAuth && (
        <div
          role="button"
          onClick={handleClick}
          className={cx(styles.offersButton, className)}
        >
          <div className={styles.offersContent}>
            <BidIcon
              size={20}
              className={total ? styles.iconActive : styles.icon}
            />
            {Boolean(total) && (
              <div className={styles.offersTag}>
                <Text
                  size="sm"
                  weight="extrabold"
                  type="white"
                  className={styles.offersTagNumber}
                >
                  {total}
                </Text>
              </div>
            )}
            <Text
              size="xs"
              weight="bold"
              uppercase
              type={'muted'}
              className={styles.offersText}
            >
              Buy Offers
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default OffersButton;
