import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { get, groupBy, keyBy } from 'lodash';
import ReactModal from 'react-modal';
import cx from 'classnames';

import useCardStatusChangeSocket from 'hooks/useCardStatusChangeSocket';
import useImxAssets from 'hooks/useImxAssets';
import useNotifications from 'hooks/useNotifications';
import useQueryParams from 'hooks/useQueryParams';

import AssetVaultItem from './AssetVaultItem';
import {
  ArrowRightIcon,
  CloseIcon,
  EthIconPlain,
  EthVaultIcon,
  ImmutableIcon,
  SignIcon,
  ZKRollUpIcon,
} from 'shared_components/SVGIcons';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';

import styles from './styles.module.scss';
import { getCollectionUserOwned } from 'api/collections';
import useCollectionsStore from 'hooks/useCollectionsStore';
import { useCurrentUser } from 'api/user';
import useAuth from 'hooks/useAuth';

const AssetVaultModal = () => {
  const history = useHistory();
  const { modal } = useQueryParams();
  const { addNotification } = useNotifications();
  const { isAuth } = useAuth();
  const { data: user } = useCurrentUser(!isAuth);
  const {
    assets,
    getAsset,
    fetchAssets,
    assetsProcessing,
    removeProcessAsset,
    getProcessingAsset,
  } = useImxAssets();

  const isOpen = modal === 'vault';

  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);
  const { collections } = useCollectionsStore();
  const [loading, setLoading] = useState(false);

  const [merged, setMerged] = useState<Card[]>([]);
  const fetchCollectionOwnedCards = async (collId: number, userId: number) => {
    const { data } = await getCollectionUserOwned(collId, userId);
    return data.cards;
  };
  const iterateCollections = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    let allCards: Card[] = [];
    for (const collectionId of Object.keys(collections)) {
      const cards = await fetchCollectionOwnedCards(
        Number(collectionId),
        user.id
      );
      allCards = [...allCards, ...cards];
    }
    setMerged(allCards);
    setLoading(false);
  }, [user, collections]);

  useEffect(() => {
    if (!isOpen) {
      setMerged([]);
      return;
    }
    iterateCollections();
  }, [isOpen, iterateCollections]);

  const cardsByUuid = useMemo(
    () =>
      keyBy(
        merged.filter(
          (i) =>
            i.status === 'available' ||
            i.status === 'imx_locked' ||
            i.status === 'eth_locked'
        ),
        'uuid'
      ),
    [merged]
  );

  const handlePoll = useCallback(
    () => Promise.all([fetchAssets(), iterateCollections()]),
    [fetchAssets, iterateCollections]
  );
  const poll = useRef<NodeJS.Timeout | null>(null);
  const clearPoll = useCallback(() => {
    if (poll.current) clearInterval(poll.current);
    poll.current = null;
  }, []);
  useEffect(() => {
    if (assetsProcessing.length) {
      const interval = assetsProcessing.some(
        (i) => i.type === 'transferToImx' || i.type === 'transferToKolectiv'
      )
        ? 1000 // IMX-Kolectiv transfers
        : 30000; // ETH transfers
      const trigger = assetsProcessing.some((i) => {
        const card = cardsByUuid[i.uuid];
        const asset = getAsset(card);
        if (card && card.status !== i.cardStatus) return true;
        if (asset?.status !== i.assetStatus) return true;
        if (card?.status === i.cardStatus && asset?.status === i.assetStatus) {
          addNotification({
            title: 'Asset transfer complete',
            subtitle: 'Your asset has been successfully transferred.',
            timeout: 5000,
          });
          removeProcessAsset(i.uuid);
        }
        return false;
      });
      if (trigger && !poll.current)
        poll.current = setInterval(handlePoll, interval);
      else clearPoll();
      return () => clearPoll();
    }
  }, [
    assetsProcessing,
    cardsByUuid,
    getAsset,
    addNotification,
    removeProcessAsset,
    handlePoll,
    clearPoll,
  ]);
  useCardStatusChangeSocket((data) => {
    if (
      assetsProcessing.length &&
      data.cards.some((c) =>
        assetsProcessing.some((a) => cardsByUuid[a.uuid]?.id === c.id)
      )
    ) {
      fetchAssets();
      iterateCollections();
    }
  });

  const imxAssets = groupBy<Asset>(
    assets?.filter((a) => {
      const card = cardsByUuid[get(a, 'metadata.uuid')];
      const processing = getProcessingAsset(card?.uuid);
      return (
        (card && card.status !== 'available') ||
        (card &&
          card.status === 'eth_locked' &&
          processing?.type !== 'transferToKolectiv')
      );
    }),
    'status'
  );

  const handleDismiss = useCallback(() => {
    history.push({
      pathname: history.location.pathname,
      hash: history.location.hash,
    });
  }, [history]);

  if (!isOpen) return null;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  // const nextRollUp = tomorrow.setHours(19, 50, 0);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.vaultModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.vaultModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white" className={styles.title}>
          <EthVaultIcon type="white" size={24} className={styles.icon} />
          Asset Vault
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.copy}>
          <Text size="xs">
            These are all your assets living away from Kolectiv. You can request
            these assets back at any time assuming they are in your linked
            Immutable or Ethereum Wallet.
          </Text>
        </div>
        <Tabs
          className={styles.reactTabs}
          selectedTabClassName={styles.tabSelected}
        >
          <TabList className={styles.tabList}>
            <Tab className={styles.tab}>
              <ImmutableIcon size={16} className={styles.icon} />{' '}
              <Text as="h2" weight="semibold" size="sm">
                Immutable Wallet
              </Text>
              {imxAssets.imx && (
                <span className={styles.secondary}>{imxAssets.imx.length}</span>
              )}
            </Tab>
            <ArrowRightIcon size={24} className={styles.arrowIcon} />
            <Tab className={styles.tab}>
              <ZKRollUpIcon size={18} className={styles.icon} />
              <Text as="h2" weight="semibold" size="sm">
                Awaiting Roll Up
              </Text>
              {imxAssets.preparing_withdrawal && (
                <span className={styles.secondary}>
                  {imxAssets.preparing_withdrawal.length}
                </span>
              )}
            </Tab>
            <ArrowRightIcon size={24} className={styles.arrowIcon} />
            <Tab className={styles.tab}>
              <SignIcon size={16} className={styles.icon} />
              <Text as="h2" weight="semibold" size="sm">
                Awaiting Signature
              </Text>{' '}
              {imxAssets.withdrawable && (
                <span className={styles.primary}>
                  {imxAssets.withdrawable.length}
                </span>
              )}
            </Tab>
            <ArrowRightIcon size={24} className={styles.arrowIcon} />
            <Tab className={styles.tab}>
              <EthIconPlain size={16} className={styles.icon} />
              <Text as="h2" weight="semibold" size="sm">
                Ethereum Wallet
              </Text>
              {imxAssets.eth && (
                <span className={styles.secondary}>{imxAssets.eth.length}</span>
              )}
            </Tab>
          </TabList>
          <div className={styles.panels}>
            {loading && !merged.length && <Spinner centered />}
            <TabPanel className={styles.tabPanel}>
              <div
                className={cx(
                  styles.body,
                  assets && imxAssets.imx && styles.grid
                )}
              >
                {assets && imxAssets.imx ? (
                  Object.values(imxAssets.imx).map((i) => (
                    <AssetVaultItem
                      key={i?.token_id}
                      asset={i}
                      card={cardsByUuid[i?.metadata?.uuid]}
                    />
                  ))
                ) : (
                  <div className={styles.emptyContent}>
                    <Text size="sm" type="muted" weight="medium">
                      Nothing lives here.
                    </Text>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <div className={styles.nextRollUp}>
                <Text
                  size="xs"
                  weight="semibold"
                  className={styles.mainnetTime}
                >
                  Assets Awaiting Roll Up are pushed to the ETH Mainnet daily
                </Text>
              </div>
              <div
                className={cx(
                  styles.body,
                  assets && imxAssets.preparing_withdrawal && styles.grid
                )}
              >
                {assets && imxAssets.preparing_withdrawal ? (
                  Object.values(imxAssets.preparing_withdrawal).map((i) => (
                    <AssetVaultItem
                      key={i?.token_id}
                      asset={i}
                      card={cardsByUuid[i?.metadata?.uuid]}
                    />
                  ))
                ) : (
                  <div className={styles.emptyContent}>
                    <Text size="sm" type="muted" weight="medium">
                      Nothing lives here.
                    </Text>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <div
                className={cx(
                  styles.body,
                  assets && imxAssets.withdrawable && styles.grid
                )}
              >
                {assets && imxAssets.withdrawable ? (
                  Object.values(imxAssets.withdrawable).map((i) => (
                    <AssetVaultItem
                      key={i?.token_id}
                      asset={i}
                      card={cardsByUuid[i?.metadata?.uuid]}
                    />
                  ))
                ) : (
                  <div className={styles.emptyContent}>
                    <Text size="sm" type="muted" weight="medium">
                      Nothing lives here.
                    </Text>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <div
                className={cx(
                  styles.body,
                  assets && imxAssets.eth && styles.grid
                )}
              >
                {assets && imxAssets.eth ? (
                  Object.values(imxAssets.eth).map((i) => (
                    <AssetVaultItem
                      key={i?.token_id}
                      asset={i}
                      card={cardsByUuid[i?.metadata?.uuid]}
                    />
                  ))
                ) : (
                  <div className={styles.emptyContent}>
                    <Text size="sm" type="muted" weight="medium">
                      Nothing lives here.
                    </Text>
                  </div>
                )}
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </ReactModal>
  );
};

export default AssetVaultModal;
