import Countdown from 'react-countdown';
import cx from 'classnames';

import Text from 'shared_components/Text';

import styles from './styles.module.scss';

type PackCountdownProps = {
  className?: string;
  date: string;
  inline?: boolean;
  onComplete?: () => void;
};

const PackCountdown = (props: PackCountdownProps) => {
  const { className, date, inline, onComplete } = props;
  return (
    <div className={cx(styles.timer, inline && styles.timerInline, className)}>
      <Countdown
        onComplete={onComplete}
        date={new Date(date)}
        renderer={({ days, hours, minutes, seconds }) =>
          inline ? (
            <Text type="white" weight="black" className={styles.time}>
              {days > 2 && (
                <>
                  {formatTime(days)}
                  <strong>days</strong>
                </>
              )}
              {days > 2 ? (
                <>
                  {formatTime(hours)}
                  <strong>hours</strong>
                </>
              ) : hours > 0 ? (
                <>
                  {formatTime(days * 24 + hours)}
                  <strong>hours</strong>
                </>
              ) : null}
              {minutes > 0 && (
                <>
                  {formatTime(minutes)}
                  <strong>mins</strong>
                </>
              )}
              {formatTime(seconds)}
              <strong>secs</strong>
            </Text>
          ) : (
            <Text
              block
              className={styles.time}
              type="white"
              weight="black"
              size="2xl"
            >
              {days < 2 ? (
                <div className={styles.timeBlocks}>
                  {hours > 0 && (
                    <span>
                      {formatTime(days * 24 + hours)}
                      <strong>hours</strong>
                    </span>
                  )}
                  {minutes > 0 && (
                    <span>
                      {formatTime(minutes)}
                      <strong>mins</strong>
                    </span>
                  )}
                  <span>
                    {formatTime(seconds)}
                    <strong>secs</strong>
                  </span>
                </div>
              ) : (
                <div className={styles.timeBlocks}>
                  <span>
                    {formatTime(days)}
                    <strong>days</strong>
                  </span>
                  {hours > 0 && (
                    <span>
                      {formatTime(hours)}
                      <strong>hours</strong>
                    </span>
                  )}
                  {minutes > 0 && (
                    <span>
                      {formatTime(minutes)}
                      <strong>mins</strong>
                    </span>
                  )}
                  <span>
                    {formatTime(seconds)}
                    <strong>secs</strong>
                  </span>
                </div>
              )}
            </Text>
          )
        }
      />
    </div>
  );
};

const formatTime = (num: number) => num.toString().padStart(2, '0');

export default PackCountdown;
