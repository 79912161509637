import request from './request';
import useRequest, { Config } from './swr';
import { getToken } from 'utils/auth';
import { API_URL } from 'config';

const UPLOAD_API_URL = API_URL.includes('staging1')
  ? `https://upload-api-staging1.epics.gg/api/v1`
  : `https://upload-api.epics.gg/api/v1`;

export const useCurrentUser = (skip?: boolean, config?: Config<User>) => {
  const token = getToken();
  return useRequest<User>(
    skip || !token ? null : { url: `/user/info` },
    config
  );
};

export const useCurrentUserFunds = (
  skip?: boolean,
  config?: Config<{ weth: string }>
) => useRequest<{ weth: string }>(skip ? null : { url: `/user/funds` }, config);

export const getUploadCode = () =>
  request.get<{ code: string }>(`/user/upload-code`);

export const uploadAvatar = (params: { code: string; file: File }) => {
  const formData = new FormData();
  formData.append('code', params.code);
  formData.append('avatar', params.file);
  return request.post<User>(UPLOAD_API_URL + `/user/global-avatar`, formData, {
    params: { categoryId: undefined },
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  });
};

export const withdraw = (data: { eth: string }) =>
  request.post<{ exchangeId: number; eth: string }>(`/balance/withdraw`, {
    eth: data.eth,
  });

export const deposit = (data: { eth: string }) =>
  request.post<{ exchangeId: number; eth: string }>(`/balance/deposit`, {
    eth: data.eth,
  });
