import Alert from 'shared_components/Alert';

type Props = {
  className?: string;
  message?: string;
};

const ErrorAlert = (props: Props) => {
  const { className, message } = props;
  return <Alert type="secondary" className={className} message={message} />;
};

export default ErrorAlert;
