import Avatar from 'shared_components/Avatar';
import styles from './styles.module.scss';
import Text from 'shared_components/Text';
import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import useRequestState from 'hooks/useRequestState';
import { useCallback } from 'react';
import { deleteOffer } from 'api/offers';
import useNotifications from 'hooks/useNotifications';

type OfferItemProps = {
  offer: Offer;
  reload: () => Promise<unknown>;
  template: CardTemplate;
};

const SentOfferItem = (props: OfferItemProps) => {
  const { offer, reload, template } = props;
  const { addNotification } = useNotifications();

  const withdrawnState = useRequestState();
  const handleWithdraw = useCallback(async () => {
    try {
      withdrawnState.start();
      await deleteOffer(offer.id);
      await reload();
      addNotification({
        type: 'primary',
        title: 'Offer withdrawn!',
        subtitle: 'You have successfully cancelled this offer.',
        timeout: 5000,
      });
    } catch (e) {
      withdrawnState.setApiError(e);
      withdrawnState.end();
      addNotification({
        type: 'secondary',
        title: 'Offer withdrawal failed',
        subtitle: 'The withdrawal of this offer failed.',
        timeout: 5000,
      });
    }
  }, [offer, withdrawnState, addNotification, reload]);

  return (
    <li className={styles.offer}>
      <img
        alt={template.uuid}
        src={template.images.size402}
        className={styles.cardImage}
      />

      <div className={styles.content}>
        <Text block weight="semibold" type="white" className={styles.cardName}>
          {offer.entity.itemName}
        </Text>

        <Text block weight="regular" size="xs" type="muted">
          by{' '}
          <Text weight="semibold" size="xs" type="white">
            {template.art.artists[0]?.name}
          </Text>
        </Text>

        <div className={styles.user}>
          <CurrencyValue value={offer.price} size={20} className={styles.price} />
          <Text uppercase weight="bold" size="xs" type="muted" className={styles.offered}>
            From
          </Text>
          <Avatar size={34} src={offer.user.avatar} />
          <Text weight="bold" type="white" className={styles.userName}>
            {offer.user.username}
          </Text>


        </div>

        <div className={styles.actions}>
          <Button
            size="sm"
            type="muted"
            loading={withdrawnState.loading}
            onClick={handleWithdraw}
            className={styles.action}
          >
            Withdraw Offer
          </Button>
        </div>
      </div>
    </li>
  );
};

export default SentOfferItem;
