import request from './request';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

export type AuctionResponse = {
  auction: Auction;
  totalBids: number;
  bids: AuctionBid[];
  users: UserLite[];
};

export const useAuction = (
  auctionId?: number,
  config?: InfiniteConfig<AuctionResponse>
) =>
  useRequestInfinite<AuctionResponse>((index, prevPageData) => {
    if (!auctionId || (prevPageData && !prevPageData.data.bids.length))
      return null;
    return { url: `/auctions/${auctionId}`, params: { page: index + 1 } };
  }, config);

export const getAuction = (auctionId?: number) =>
  request.get<AuctionResponse>(`/auctions/${auctionId}`);

type AuctionsResponse = {
  total: number;
  auctions: Auction[];
  users: UserLite[];
};

export const useAuctions = (
  params: {
    status: 'upcoming' | 'active' | 'completed' | 'failed';
    us?: boolean;
  },
  skip?: boolean,
  config?: InfiniteConfig<AuctionsResponse>
) =>
  useRequestInfinite<AuctionsResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.auctions.length))
      return null;
    return {
      url: `/auctions/`,
      params: {
        status: params.status,
        us: params.us ? 1 : undefined,
        page: index + 1,
      },
    };
  }, config);

export const useAuctionsParticipating = (
  params: {
    status: 'upcoming' | 'active' | 'completed' | 'failed';
    us?: boolean;
  },
  skip?: boolean,
  config?: InfiniteConfig<AuctionsResponse>
) =>
  useRequestInfinite<AuctionsResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.auctions.length))
      return null;
    return {
      url: `/auctions/participating`,
      params: {
        page: index + 1,
      },
    };
  }, config);

export const useAuctionsForUser = (
  userId: number | undefined,
  status?: 'active' | 'completed' | 'failed',
  skip?: boolean,
  config?: InfiniteConfig<AuctionsResponse>
) =>
  useRequestInfinite<AuctionsResponse>((index, prevPageData) => {
    if (skip || !userId || (prevPageData && !prevPageData.data.auctions.length))
      return null;
    return {
      url: `/auctions/users/${userId}`,
      params: { status, page: index + 1 },
    };
  }, config);

type CreateAuctionParams = {
  auctionType?: AuctionType;
  entityType?: 'card' | 'pack';
  entityId: number;
  startPrice?: string;
  reservePrice?: string;
  buyNowPrice?: string;
  start?: string;
  end?: string;
  overtime?: {
    window: number;
    extend: number;
  };
};

export const createAuction = (data: CreateAuctionParams) => {
  const { auctionType = 1, entityType = 'card' } = data;
  return request.post<{ auction: { id: number } }>(`/auctions/`, {
    ...data,
    auctionType,
    entityType,
  });
};

type UpdateAuctionParams = {
  startPrice?: string;
  reservePrice?: string;
  buyNowPrice?: string;
  start?: string;
  end?: string;
};

export const updateAuction = (auctionId: number, data: UpdateAuctionParams) =>
  request.patch<{ auction: { id: number } }>(`/auctions/${auctionId}`, data);

export const deleteAuction = (auctionId: number) =>
  request.delete(`/auctions/${auctionId}`);

export const placeBid = (params: { auctionId: number; amount: string }) =>
  request.post<{
    id: number;
    auctionId: number;
    user: UserLite;
    amount: string;
    created: string;
  }>(`/auctions/${params.auctionId}/bid`, { amount: params.amount });

export const buyNowAuction = async (params: {
  auctionId: number;
  buyNowPrice: string;
}) => {
  const formData = new FormData();
  formData.append('buyNowPrice', params.buyNowPrice);
  return await request.post(`/auctions/${params.auctionId}/buy-now`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  });
};
