import request from './request';
import useRequest, { Config } from './swr';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

export const usePacks = (
  params?: { season?: string },
  config?: InfiniteConfig<PackTemplate[]>
) =>
  useRequestInfinite<PackTemplate[]>((index, prevPageData) => {
    if (prevPageData && !prevPageData.data.length) return null;
    return {
      url: `/packs`,
      params: {
        page: index + 1,
        seasons: params?.season,
      },
    };
  }, config);

type UserPacksResponse = {
  count: number;
  total: number;
  packs: Pack[];
};

export const useUserPacks = (
  skip?: boolean,
  config?: InfiniteConfig<UserPacksResponse>
) =>
  useRequestInfinite<UserPacksResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.count)) return null;
    return { url: `/packs/user`, params: { page: index + 1 } };
  }, config);

export const useUserPacksCount = (
  skip?: boolean,
  config?: Config<{ total: number }>
) =>
  useRequest<{ total: number }>(skip ? null : { url: `/packs/count` }, config);

export const openPack = async (packId: number) => {
  const formData = new FormData();
  formData.append('packId', packId.toString());
  return await request.post<{ cards: Card[] }>(`/packs/open`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
    },
  });
};

export const buyPack = (data: { packTemplateId: number; amount: number }) =>
  request.post<[]>(`/packs/buy`, {
    packTemplateId: data.packTemplateId,
    amount: data.amount,
  });

export const orderPack = (data: { packTemplateId: number; amount: number }) =>
  request.post<{ orderId: number; cost: string; expires: string }>(
    `/packs/order`,
    {
      packTemplateId: data.packTemplateId,
      amount: data.amount,
    }
  );
