import { useEffect, useCallback } from 'react';
import socket from 'api/socket';

const PACK_ADDED_EVENT = 'pack-added';

type EventPackData = {
  amount: number;
  categoryId: number;
  packTemplateId: string;
};

type PackInventoryUpdateEventResponse = {
  event: typeof PACK_ADDED_EVENT;
  packs: EventPackData[];
};

const usePackAddedSocket = (cb?: (data: EventPackData) => void) => {
  const handler = useCallback(
    (e: PackInventoryUpdateEventResponse) => {
      cb?.(e.packs[0]);
    },
    [cb]
  );

  useEffect(() => {
    socket.on(PACK_ADDED_EVENT, handler);
    return () => {
      socket.off(PACK_ADDED_EVENT, handler);
    };
  }, [handler]);
};

export default usePackAddedSocket;
