import {
  ControlledMenu,
  FocusPosition,
  MenuHeader,
  MenuItem,
} from '@szhsin/react-menu';
import { useSearchUser } from 'api/users';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { RefObject, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from 'shared_components/Avatar';

import './styles.module.scss';

type MenuProps = {
  isMounted: boolean;
  isOpen: boolean;
  menuItemFocus: { position: FocusPosition };
  anchorRef: RefObject<HTMLInputElement>;
};

type Props = {
  input?: string;
  inputFocused?: boolean;
  controlledMenuProps: MenuProps;
  closeMenu: () => void;
  openMenu: () => void;
};

const SearchResults = ({
  input,
  inputFocused,
  controlledMenuProps,
  closeMenu,
  openMenu,
}: Props) => {
  const history = useHistory();
  const { data: matches, isValidating } = useSearchUser(input);
  const [ignore, setIgnore] = useState(false);

  useEffect(() => {
    setIgnore(!!inputFocused);
  }, [inputFocused]);

  useOnOutsideClick(
    closeMenu,
    {
      ref: controlledMenuProps.anchorRef,
      ignore: ignore,
    },
    [controlledMenuProps.isOpen, inputFocused]
  );

  const goToUser = (username: string) => {
    history.push(`/u/${username}`);
    closeMenu();
  };

  useEffect(() => {
    if (matches) openMenu();
    else closeMenu();
  }, [matches, openMenu, closeMenu]);

  if (!matches) return null;

  return (
    <ControlledMenu
      {...controlledMenuProps}
      captureFocus={false}
      position="anchor"
      overflow="auto"
      direction="bottom"
      offsetY={2}
      id="user_search"
    >
      {matches?.length && <MenuHeader>{matches.length} matches</MenuHeader>}
      {matches?.length ? (
        matches.map((user) => (
          <MenuItem
            key={user.id}
            onClick={() => goToUser(user.username)}
            onKeyDown={({ key }) => {
              if (key === 'Enter' || key === ' ') goToUser(user.username);
            }}
          >
            <Avatar size={20} src={user.avatar} />
            {user.username}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          {isValidating ? 'Loading...' : 'No matches'}
        </MenuItem>
      )}
    </ControlledMenu>
  );
};

export default SearchResults;
