import {
  createContext,
  createElement,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RouteProps, useHistory } from 'react-router';

import usePrevious from 'hooks/usePrevious';

type PrevLocation = RouteProps['location'] | undefined;

const context = createContext<PrevLocation>(undefined);

export const PreviousLocationProvider = (props: PropsWithChildren<unknown>) => {
  const previousLocation = usePreviousLocationState();
  return createElement(context.Provider, { ...props, value: previousLocation });
};

export function usePreviousLocationState<LocState = unknown>() {
  const history = useHistory<LocState>();
  const [currentLocation, set] = useState<
    typeof history.location | undefined
  >();

  useEffect(() => {
    if (history) {
      const setCurrent = (x: typeof history.location) => set(x);
      const unlisten = history.listen((l) => {
        setCurrent(l);
      });

      return () => {
        unlisten();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return usePrevious(currentLocation);
}

/**
 * Since location changes are dynamic you might need to use this in
 * conjunction with other hooks to keep a static reference
 * @example useRef(usePreviousLocation())
 */
export default function usePreviousLocation() {
  return useContext(context);
}
