import { LegacyRef, useCallback, useMemo } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory } from 'react-router-dom';
import { flatten, groupBy } from 'lodash';
import ReactModal from 'react-modal';

import useAppPacks from 'hooks/useAppPacks';
import useQueryParams from 'hooks/useQueryParams';
import usePackTemplates from 'hooks/usePackTemplates';
import { stringifyUrlOmitParam } from 'utils/routes';
import { useUserPacks } from 'api/packs';

import PacksDrawerItem from './PacksDrawerItem';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import packImage from 'assets/icons/pack.png';
import styles from './styles.module.scss';

const flattenOwnedPackPages = (
  pages: {
    count: number;
    total: number;
    packs: Pack[];
  }[]
) => flatten(pages.map(({ packs }) => packs));

const groupPacksByTemplate = (packs: Pack[]) =>
  groupBy(packs, 'packTemplateId');

const PacksDrawer = () => {
  const history = useHistory();
  const { drawer } = useQueryParams();
  const isOpen = drawer === 'packs';

  const { templates } = usePackTemplates();
  const { packTemplates } = useAppPacks();
  const { data: packs, isValidating, setSize, size } = useUserPacks(!isOpen);

  const isLastPage = useMemo(() => {
    if (packs?.[0]) return packs[0].count >= packs[0].total;
    return false;
  }, [packs]);

  const handleDismiss = useCallback(() => {
    history.push(stringifyUrlOmitParam(history.location, 'drawer'));
  }, [history]);

  const scrollRef: LegacyRef<HTMLDivElement> = useBottomScrollListener(() => {
    if (!isLastPage && !isValidating) setSize((size) => size + 1);
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={200}
      className={{
        base: styles.drawer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.packsDrawerOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <img alt="Packs Owned" src={packImage} className={styles.packImage} />
        <Text size="lg" weight="bold" type="white">
          Purchased Packs{' '}
          {/* {Boolean(packs?.[0]?.total) && (
            <Text
              size="sm"
              type="white"
              weight="semibold"
              className={styles.count}
            >
              {packs?.[0]?.total}
            </Text>
          )} */}
        </Text>

        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <div ref={scrollRef} className={styles.content}>
        {packTemplates && packs?.[0]?.packs.length ? (
          <ul className={styles.navItems}>
            {Object.values(
              groupPacksByTemplate(flattenOwnedPackPages(packs))
            ).map((packs) => {
              if (!templates[packs[0].packTemplateId]) return null;
              return (
                <PacksDrawerItem
                  key={packs[0].packTemplateId}
                  packTemplate={templates[packs[0].packTemplateId]}
                  packs={packs}
                />
              );
            })}
          </ul>
        ) : (
          packs?.[0]?.total === 0 && (
            <Text block centered size="xs" className={styles.endText}>
              No packs to display.
            </Text>
          )
        )}

        {size > 1 && isValidating && (
          <Spinner size={24} type="white" className={styles.pageSpinner} />
        )}

        {!packs && isValidating && <Spinner centered />}
      </div>
    </ReactModal>
  );
};

export default PacksDrawer;
