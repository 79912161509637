import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useQueryParams = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  return parsed as Record<string, string>;
};

export default useQueryParams;
