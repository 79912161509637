import axios, { AxiosRequestConfig } from 'axios';
import { isBefore } from 'date-fns';

import { getToken, saveToken } from 'utils/auth';
import { Category } from 'constants/category';

import { logout, refreshToken, REFRESH_TOKEN_ENDPOINT } from './refreshToken';
import { authenticateSocket } from './socket';
import config from './config';

const request = axios.create(config);

export const JWT_HEADER_KEY = 'x-user-jwt';
const CATEGORY_PARAM_KEY = 'categoryId';

let refreshing = false;
const queue: AxiosRequestConfig[] = [];

/**
 * Refresh token & authorization header request interceptor
 */
request.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    // Refresh token & queue requests with outdated JWTs
    const tokenExpires = token.expires * 1000;
    const isExpired = isBefore(new Date(tokenExpires), new Date());
    if (isExpired) {
      queue.push(config);

      if (!refreshing) {
        refreshing = true;

        const response = await refreshToken(token);
        const { jwt, expires } = response.data;

        authenticateSocket(jwt);
        saveToken({ jwt, expires, refreshToken: token.refreshToken });

        const requests = queue.map((config) => {
          config.headers[JWT_HEADER_KEY] = jwt;
          return request(config);
        });
        await Promise.all(requests);
      }
    }

    // Add JWT to request headers
    else if (!config.headers[JWT_HEADER_KEY]) {
      config.headers[JWT_HEADER_KEY] = token.jwt;
    }
  }

  // Attach categoryId if not explicitly passed
  if (!(CATEGORY_PARAM_KEY in (config.params || {}))) {
    if (!config.params) config.params = {};
    config.params[CATEGORY_PARAM_KEY] = Category.id;
  }

  return config;
});

/**
 * Refresh token response interceptor
 */
request.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle invalid JWT
    if (error.response?.data?.errorCode === 'jwt_invalid') {
      await logout();
    }

    // Refresh token
    const originalRequest = error.config;
    if (originalRequest?.url === REFRESH_TOKEN_ENDPOINT) {
      await logout();
      return;
    }

    return Promise.reject(error);
  }
);

export default request;
