export enum Routes {
  landing = '/',
  mobileLogin = '/mobilelogin',
  home = '/home',
  collection = '/collection',
  shop = '/shop',
  market = '/market',
  leaderboard = '/leaderboard',
  drops = '/drops',
  packOpening = '/opening',
  profile = '/u/:username',

  auctions = '/auctions',
  auction = '/auctions/:id',

  bicycles = '/bicycle',
  bicycle = '/bicycle/auction/:id',

  card = '/asset/:id',
  marketCard = '/market/:id',
  profileCard = '/u/:username/asset/:id',
  fullCard = '/asset/:id/full',

  artists = '/artists',
  artist = '/artist/:name',
}
