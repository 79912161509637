import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';

import { getAuction } from 'api/auctions';
import useAlertSocket from 'hooks/useAlertSocket';
import { buildPathParams } from 'utils/routes';
import { Routes } from 'constants/routes';

import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import sunBeam from 'assets/animation/sunbeamSuccess.png';
import styles from './styles.module.scss';

const AuctionWonModal = () => {
  const history = useHistory();
  const [auctionItem, setAuctionItem] = useState<Auction>();

  useAlertSocket<{ auctionId: number }>(
    'auction-won',
    async ({ auctionId }) => {
      try {
        const { data } = await getAuction(auctionId);
        setAuctionItem(data.auction);
      } catch (e) {
        return;
      }
    }
  );

  const handleDismiss = useCallback(() => {
    setAuctionItem(undefined);
  }, []);

  const handleViewCard = useCallback(() => {
    if (auctionItem) {
      history.push(
        buildPathParams(Routes.card, { id: auctionItem.entity.card.uuid })
      );
      setAuctionItem(undefined);
    }
  }, [auctionItem, history]);

  return (
    <ReactModal
      isOpen={Boolean(auctionItem)}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.auctionWonModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.auctionWonModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.dismiss} role="button" onClick={handleDismiss}>
        <Text size="sm" weight="semibold">
          CLOSE
        </Text>
        <CloseIcon size={18} className={styles.closeIcon} />
      </div>

      <div className={styles.content}>
        <div className={styles.sunBeamWrapper}>
          <img alt="Success!" src={sunBeam} className={styles.sunBeam} />
        </div>
        {auctionItem && (
          <img
            alt={auctionItem.entity.card.uuid}
            src={auctionItem.entity.card.images.size402}
            className={styles.card}
          />
        )}
        <Text as="h2" size="2xl" type="white" className={styles.successText}>
          Auction Won!
        </Text>
        <Text as="p" size="sm" type="white" className={styles.successSubtext}>
          You have won the auction for this card with{' '}
          <CurrencyValue inline value={auctionItem?.currentBid || undefined} />{' '}
          !
        </Text>

        <Button
          rounded="full"
          size="sm"
          onClick={handleViewCard}
          className={styles.linkBtn}
        >
          View Card
        </Button>
      </div>
    </ReactModal>
  );
};

export default AuctionWonModal;
