import { useEffect, useState } from 'react';
import { debounce as _debounce } from 'lodash';

type Ops = {
  debounce?: number;
};

const defaultOps: Ops = { debounce: 0 /* 0 = no debounce */ };

const useViewport = ({ debounce }: Ops = defaultOps) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const handleWindowResize = debounce
      ? _debounce(handler, debounce)
      : handler;

    window.addEventListener('resize', handleWindowResize, { passive: true });
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [debounce]);

  return { width, height };
};

export default useViewport;
