import { useEffect, useCallback } from 'react';
import socket from 'api/socket';

const EVENT_TYPE = 'card-update';

export type EventResponse = {
  event: typeof EVENT_TYPE;
  cards: { id: number; status: Card['status'] }[];
};

const useCardStatusChangeSocket = (cb: (data: EventResponse) => void) => {
  const handler = useCallback(cb, [cb]);

  useEffect(() => {
    socket.on(EVENT_TYPE, handler);
    return () => {
      socket.off(EVENT_TYPE, handler);
    };
  }, [handler]);
};

export default useCardStatusChangeSocket;
