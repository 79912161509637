import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Routes } from 'constants/routes';
import { PreviousLocationProvider } from 'hooks/usePreviousLocation';

import AppLayout from 'shared_containers/AppLayout';
import Spinner from 'shared_components/Spinner';
import RouterAnalytics from 'shared_components/RouterAnalytics';
import {
  PreAuthRoute,
  PrivateRoute,
  TitledRoute,
} from 'shared_containers/Route';
import useAppSeasons from 'hooks/useAppSeasons';

const Landing = lazy(() => import('./Landing'));
const MobileLogin = lazy(() => import('./MobileLogin'));
const Home = lazy(() => import('./Home'));
const Collection = lazy(() => import('./Collection'));
const Shop = lazy(() => import('./Shop'));
const Market = lazy(() => import('./Market'));
const Leaderboard = lazy(() => import('./Leaderboard'));
const Drops = lazy(() => import('./Drops'));
const PackOpening = lazy(() => import('./PackOpening'));
const Profile = lazy(() => import('./Profile'));
const ViewCard = lazy(() => import('./ViewCard'));
const ViewCardFull = lazy(() => import('./ViewCardFull'));
const Drop = lazy(() => import('./Drop'));
const Auctions = lazy(() => import('./Auctions'));
const ViewAuction = lazy(() => import('./ViewAuction'));
const Artists = lazy(() => import('./Artists'));
const Artist = lazy(() => import('./Artist'));
const ErrorBoundary = lazy(() => import('../shared_containers/ErrorBoundary'));

const Pages = () => {
  const { seasons } = useAppSeasons();
  return (
    <Suspense fallback={<Spinner centered />}>
      <Router>
        <ErrorBoundary>
          <RouterAnalytics />
          <PreviousLocationProvider>
            <Switch>
              <Route exact path={Routes.mobileLogin} component={MobileLogin} />
              <Route exact path={Routes.fullCard} component={ViewCardFull} />
              <Route exact path={Routes.card} component={ViewCard} />
              <AppLayout>
                <Switch>
                  <PreAuthRoute
                    exact
                    path={Routes.landing}
                    component={Landing}
                  />
                  <PrivateRoute exact path={Routes.home} component={Home} />
                  <TitledRoute
                    title="Collection"
                    exact
                    path={Routes.collection}
                    component={Collection}
                  />
                  <Route exact path={Routes.shop} component={Shop} />
                  <TitledRoute
                    title="Market"
                    exact
                    path={Routes.market}
                    component={Market}
                  />
                  <TitledRoute
                    title="Leaderboard"
                    exact
                    path={Routes.leaderboard}
                    component={Leaderboard}
                  />
                  <TitledRoute
                    title="Drops"
                    exact
                    path={Routes.drops}
                    component={Drops}
                  />
                  <Route
                    exact
                    path={Routes.packOpening}
                    component={PackOpening}
                  />
                  <TitledRoute
                    title={({ params }) => params.username}
                    exact
                    path={Routes.profile}
                    component={Profile}
                  />
                  <TitledRoute
                    title="Auctions"
                    exact
                    path={Routes.auctions}
                    component={Auctions}
                  />
                  <Route exact path={Routes.auction} component={ViewAuction} />
                  <TitledRoute
                    title="Artists"
                    exact
                    path={Routes.artists}
                    component={Artists}
                  />
                  <Route exact path={Routes.artist} component={Artist} />
                  <Route
                    exact
                    path={[Routes.marketCard, Routes.profileCard]}
                    component={ViewCard}
                  />

                  {seasons.length &&
                    seasons.map((season) => (
                      <TitledRoute
                        exact
                        title={season}
                        path={`/${season}`}
                        key={season}
                        component={Drop}
                      />
                    ))}
                  {/* We're using a Route.render instead of <Redirect> here so we can pass location-state based on the current location, and match an asynchronously generated route-path (see above)  */}
                  <Route
                    render={(routeProps) => {
                      routeProps.history.replace(Routes.home, {
                        previousLocation: routeProps.location,
                      });
                      return null;
                    }}
                  />
                </Switch>
              </AppLayout>
            </Switch>
          </PreviousLocationProvider>
        </ErrorBoundary>
      </Router>
    </Suspense>
  );
};

export default Pages;
