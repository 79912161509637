import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Routes } from 'constants/routes';
import useAuth from 'hooks/useAuth';

import appLogo from 'assets/logos/app_logo.svg';
import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const AppLogo = (props: Props) => {
  const { isAuth } = useAuth();
  const { className } = props;
  const cn = cx(styles.appLogoContainer, className);
  return (
    <Link to={isAuth ? Routes.home : Routes.landing} className={cn}>
      <img alt="Kolectiv" src={appLogo} className={styles.appLogo} />
    </Link>
  );
};

export default AppLogo;
