import { useEffect, useState } from 'react';
import { keyBy } from 'lodash';

import { useArtists } from 'api/artists';
import usePrevious from './usePrevious';

const useAppArtists = (params?: { seasons?: string }) => {
  const [page, setPage] = useState(1);
  const prevPage = usePrevious(page);

  const { data, setSize, ...rest } = useArtists(params, {
    onSuccess: (result) => {
      const last = result[result.length - 1];
      const num =
        last.data.limit * (result.length - 1) + last.data.artists.length;
      if (num < last.data.total) setPage((p) => p + 1);
    },
  });

  useEffect(() => {
    if (page > (prevPage || 1)) setSize((s) => s + 1);
  }, [page, prevPage, setSize]);

  const artists = keyBy(data?.map((d) => d.artists).flat(), 'id');
  return { artists, ...rest };
};

export default useAppArtists;
