import ReactModal from 'react-modal';
import cx from 'classnames';

import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import Button from 'shared_components/Button';

type Props = {
  cancelLabel?: string;
  children?: React.ReactNode;
  className?: string;
  confirmButtonLoading?: boolean;
  confirmLabel?: string;
  contentClassName?: string;
  headerClassName?: string;
  header?: string;
  isOpen: boolean;
  confirmDisabled?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = (props: Props) => {
  const {
    cancelLabel = 'Cancel',
    children,
    className,
    confirmButtonLoading,
    confirmLabel = 'Confirm',
    contentClassName,
    headerClassName,
    header,
    isOpen,
    onCancel,
    onConfirm,
    confirmDisabled
  } = props;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      closeTimeoutMS={300}
      className={{
        base: cx(styles.confirmationModal, className),
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.confirmationModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={cx(styles.header, headerClassName)}>
        <Text size="md" weight="bold" type="white">
          {header}
        </Text>
        <CloseIcon
          size={50}
          role="button"
          onClick={onCancel}
          className={styles.closeIcon}
        />
      </div>

      <div className={cx(styles.content, contentClassName)}>{children}</div>

      <div className={styles.actions}>
        <Button
          block
          size="sm"
          type="muted"
          onClick={onCancel}
          className={styles.cancelBtn}
        >
          {cancelLabel}
        </Button>
        <Button
          block
          size="sm"
          loading={confirmButtonLoading}
          onClick={onConfirm}
          className={styles.confirmBtn}
          disabled={confirmDisabled ? confirmDisabled : false}
        >
          {confirmLabel}
        </Button>
      </div>
    </ReactModal>
  );
};

export default ConfirmationModal;
