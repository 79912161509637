import cx from 'classnames';

import Button, { ButtonProps } from 'shared_components/Button';

import styles from './styles.module.scss';

export const AppHeaderButton = ({
  children,
  className = '',
  ...props
}: ButtonProps) => {
  return (
    <Button rounded="full" className={cx(styles.btn, className)} {...props}>
      {children}
    </Button>
  );
};

export const AppHeaderLoginButton = (props: ButtonProps) => {
  return (
    <AppHeaderButton
      rounded="full"
      to={(location) => ({
        pathname: location.pathname,
        hash: location.hash,
        search: '?modal=login',
      })}
      {...props}
    >
      {props.children}
    </AppHeaderButton>
  );
};
