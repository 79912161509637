import cx from 'classnames';
import { format } from 'date-fns';
import { formatDate, formatDateDistance } from 'utils/format';

import Text from 'shared_components/Text';

import styles from './styles.module.scss';

type AlertItemProps = {
  alert: Alert;
  children?: React.ReactNode;
  icon: React.ReactNode;
  title: string;
};

// You do not have to you this if you want something custom for an alertType!
const BaseAlertItem = (props: AlertItemProps) => {
  const { alert, children, icon, title } = props;

  return (
    <div
      className={cx(
        styles.baseAlertItem,
        alert.seen ? styles.seen : styles.unseen
      )}
    >
      <div className={styles.left}>
        <div className={styles.icon}>{icon}</div>
      </div>
      <div className={styles.right}>
        <Text block className={styles.created} size="2xs">
          {formatDateDistance(alert.created)}
        </Text>
        <Text
          block
          size="sm"
          weight="bold"
          type="white"
          className={styles.title}
        >
          {title || alert.alertType}
        </Text>

        <div className={styles.body}>
          {/* Shows JSON data in dev if alertType not handled */}
          {children ||
            (process.env.NODE_ENV === 'development' ? (
              <Text block size="sm">
                <Text size="xs" type="muted">
                  [data preview - only visible in development]
                </Text>
                <pre>{JSON.stringify(alert.data, null, 2)}</pre>
              </Text>
            ) : null)}
        </div>

        {alert.message && (
          <Text block size="sm" className={styles.message}>
            Message: {alert.message}
          </Text>
        )}
      </div>
    </div>
  );
};

export default BaseAlertItem;
