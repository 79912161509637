import { useEffect, useCallback } from 'react';
import socket from 'api/socket';

const EVENT_TYPE = 'offer-withdrawn';

type EventData = {
  itemName: string;
  amount: string;
  offerId: number;
};

export type EventResponse = {
  event: typeof EVENT_TYPE;
  id?: number;
} & EventData;

const useOffersWithdrawnSocket = (cb: (data: EventResponse) => void) => {
  const handler = useCallback(cb, [cb]);

  useEffect(() => {
    socket.on(EVENT_TYPE, handler);
    return () => {
      socket.off(EVENT_TYPE, handler);
    };
  }, [handler]);
};

export default useOffersWithdrawnSocket;
