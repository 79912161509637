import * as React from 'react';
import cx from 'classnames';
import { renderSvg } from './helpers';
import styles from './styles.module.scss';
import { forEach } from 'lodash';
// import usePrevious from 'hooks/usePrevious';

export interface ISvgCardProps {
  cardTemplateUUID: string;
  className?: string;
  url: string;
  width: number;
  autoplay?: boolean;
  muted?: boolean;
  onLoad?: () => void;
  onError?: () => void;
}

const SvgCard = React.memo(
  function SvgCard(props: React.PropsWithChildren<ISvgCardProps>) {
    const { autoplay = true, muted = true, onLoad, onError } = props;
    const svgRef = React.useRef<HTMLDivElement>(null);
    const [svg, setSvg] = React.useState('');

    React.useEffect(() => {
      let isMounted = true;
      const svgGenerator = renderSvg({
        url: props.url,
        autoplay: true,
      });
      const setSvgContent = async () => {
        if (props.url) {
          const svgContent = await svgGenerator.next();
          if (isMounted) setSvg(svgContent.value);
          if (svgContent) onLoad?.();
          else onError?.();
        }
      };
      setSvgContent();
      return () => {
        isMounted = false;
        svgGenerator.next();
        setSvg('');
      };
    }, [props.url, onError, onLoad]);

    React.useEffect(() => {
      if (svgRef !== null) {
        const videos = svgRef.current?.getElementsByTagName('video');
        forEach(videos, (video) => {
          video.muted = muted;
          autoplay ? video.play() : video.pause();
        });
      }
    }, [autoplay, muted, svg]);

    return (
      <div
        ref={svgRef}
        style={{
          width: `${props.width}px`,
          height: `${props.width * 1.4}px`,
          perspective: '1200px',
          position: 'relative',
        }}
        id={`card_${props.cardTemplateUUID}`}
        className={cx(styles.SVGCard, props.className)}
        dangerouslySetInnerHTML={{
          __html: svg,
        }}
      />
    );
  },
  (prevProps: ISvgCardProps, nextProps: ISvgCardProps) => {
    const urlChanged = prevProps.url !== nextProps.url;
    const muteChanged = prevProps.muted !== nextProps.muted;
    const autoplayChanged = prevProps.autoplay !== nextProps.autoplay;
    return !urlChanged && !muteChanged && !autoplayChanged;
  }
);

export function SvgCardPlayOnHover(props: ISvgCardProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  const hoverStateEnter = React.useCallback(() => {
    setIsHovered(true);
  }, []);
  const hoverStateLeave = React.useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <div onMouseEnter={hoverStateEnter} onMouseLeave={hoverStateLeave}>
      <SvgCard {...props} autoplay={isHovered} />
    </div>
  );
}

SvgCard.displayName = 'SvgCard';
export default SvgCard;
