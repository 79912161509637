import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import useOrderState from 'hooks/useOrderState';
import Text from 'shared_components/Text';
import useQueryParams from 'hooks/useQueryParams';
import { CloseIcon } from 'shared_components/SVGIcons';
import { Lottie } from '@jedidiah/react-lottie';
import sunBeam from 'assets/animation/sunbeamSuccess.png';
import successAnim from 'assets/animation/success.json';

import styles from './styles.module.scss';
import { getEtherscanTransactionLink } from 'utils/etherscan';

const ExchangeSuccessModal = () => {
  const { transactionHash, quantity, exchangeType } = useOrderState();
  const history = useHistory();
  const { modal, tx } = useQueryParams();

  const isOpen = modal === 'exchangesuccess';

  const handleDismiss = useCallback(() => {
    history.push({
      pathname: history.location.pathname,
      hash: history.location.hash,
    });
  }, [history]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.loginModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.loginModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.dismiss} role="button" onClick={handleDismiss}>
        <Text size="sm" weight="semibold">CLOSE</Text>
        <CloseIcon size={18} className={styles.closeIcon} />
      </div>

      <div className={styles.content}>
        <div className={styles.sunBeamWrapper}>
          <img alt="Success!" src={sunBeam} className={styles.sunBeam} />
        </div>
        <Lottie
          width="150px"
          height="150px"
          direction={1}
          speed={1}
          loop={0}
          animationData={successAnim}
        />
        <Text as="h2" size="2xl" type="white" className={styles.successText}>
          {exchangeType === 'withdraw' ? 'Withdrawal' : 'Deposit'} Success!
        </Text>
        <Text as="p" size="sm" type="white" className={styles.successSubtext}>
          It may take a minute for your balance to update.
        </Text>

        {transactionHash || tx ? (
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href={getEtherscanTransactionLink(transactionHash || tx)}
          >
            View on Etherscan
          </a>
        ) : null}
      </div>
    </ReactModal>
  );
};

export default ExchangeSuccessModal;
