import { useCallback } from 'react';
import { ImmutableXClient } from '@imtbl/imx-sdk';
import { groupBy } from 'lodash';
import Web3 from 'web3';

import create from 'zustand';
import { persist } from 'zustand/middleware';

import BigNumber from 'bignumber.js';
import { IMX_API_URL } from 'config';
import { useSettings } from 'api/settings';
import storage from 'utils/storage';
import useSettingsStore from './useSettingsStore';

export const PERSIST_KEY = 'persist:imx';

type GroupedAssetsType = { [uuid: string]: Asset[] };

type ProcessingAsset = {
  uuid: string;
  assetId?: string;
  assetStatus?: Asset['status'];
  cardStatus?: Card['status'];
  type:
    | 'transferToImx'
    | 'transferToKolectiv'
    | 'prepareWithdrawal'
    | 'deposit'
    | 'completeWithdrawal';
};

function uuidToInt(uuid: string) {
  const tokenId = new BigNumber('0x' + uuid.replace(/-/g, '')).toFixed();
  return tokenId;
}

type ImxAssetsState = {
  assets?: Asset[];
  currentAsset?: Asset;
  groupedAssets?: GroupedAssetsType;
  setCurrentAsset: (asset: Asset) => void;
  clearCurrentAsset?: () => void;
  setAssets: (assets?: Asset[]) => void;
  setGroupedAssets: (groupedAssets?: GroupedAssetsType) => void;
  getAsset: (card?: Card) => Asset | undefined;
  assetsProcessingMap: { [uuid: string]: ProcessingAsset };
  getProcessingAsset: (uuid?: string) => ProcessingAsset | undefined;
  processAsset: (params: ProcessingAsset) => void;
  removeProcessAsset: (uuid: string) => void;
};

const useImxAssetsState = create<ImxAssetsState>(
  persist(
    (set, get) => ({
      assets: undefined,
      currentAsset: undefined,
      groupedAssets: undefined,
      setAssets: (assets) => {
        set({ assets });
      },
      setGroupedAssets: (groupedAssets) => {
        set({ groupedAssets });
      },
      setCurrentAsset: (asset: Asset) => {
        set({ currentAsset: asset });
      },
      clearCurrentAsset: () => {
        set({ currentAsset: undefined });
      },
      getAsset: (card) => {
        const { groupedAssets } = get();
        if (
          !card ||
          (card.status !== 'imx_locked' && card.status !== 'eth_locked')
        )
          return undefined;
        const assetGroup = card.uuid ? groupedAssets?.[card.uuid] : undefined;
        const asset = assetGroup
          ? assetGroup.length > 1
            ? assetGroup.find((a) => a.token_id.slice(0, 2) !== '0x')
            : assetGroup[0]
          : undefined;
        if (asset?.token_id.slice(0, 2) === '0x' && asset?.status === 'eth')
          return undefined;
        return asset;
      },
      assetsProcessingMap: {},
      getProcessingAsset: (uuid) => {
        if (!uuid) return undefined;
        const { assetsProcessingMap } = get();
        return assetsProcessingMap[uuid];
      },
      processAsset: ({ uuid, assetId, assetStatus, cardStatus, type }) => {
        const { assetsProcessingMap } = get();
        set({
          assetsProcessingMap: {
            ...assetsProcessingMap,
            [uuid]: { uuid, assetId, assetStatus, cardStatus, type },
          },
        });
      },
      removeProcessAsset: (uuid) => {
        const { [uuid]: _, ...assetsProcessingMap } = get().assetsProcessingMap;
        set({ assetsProcessingMap });
      },
    }),
    { name: PERSIST_KEY, getStorage: () => storage }
  )
);

const useImxAssets = () => {
  const {
    assets,
    setAssets,
    currentAsset,
    setCurrentAsset,
    clearCurrentAsset,
    setGroupedAssets,
    getAsset,
    assetsProcessingMap,
    processAsset,
    removeProcessAsset,
    getProcessingAsset,
  } = useImxAssetsState();

  const persistedSettings = useSettingsStore();
  const { data: settings } = useSettings(false, undefined, {
    initialData: persistedSettings as Settings,
  });

  const fetchAsset = useCallback(
    async ({ uuid }) => {
      try {
        if (settings?.eth?.tokenContract && uuid) {
          const client = await ImmutableXClient.build({
            publicApiUrl: IMX_API_URL,
          });
          await client
            .getAsset({
              address: settings.eth.tokenContract,
              id: uuidToInt(uuid),
            })
            .then((asset) => {
              if (!asset) {
                return null;
              } else {
                setCurrentAsset(asset as Asset);
                return asset as Asset;
              }
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [settings, setCurrentAsset]
  );

  const fetchAssets = useCallback(async () => {
    try {
      if (settings?.eth?.tokenContract) {
        const client = await ImmutableXClient.build({
          publicApiUrl: IMX_API_URL,
        });
        if (!window.ethereum) return null;
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const user = await web3.eth.getCoinbase();
        const { result } = await client.getAssets({
          collection: settings.eth.tokenContract,
          user,
        });
        const grouped = groupBy(result, 'metadata.uuid');
        setGroupedAssets(grouped as GroupedAssetsType);
        const assets =
          grouped &&
          Object.values(grouped).map((i) =>
            i.length > 1
              ? (i.find((a) => a.token_id.slice(0, 2) !== '0x') as Asset)
              : i[0]
          );
        setAssets(assets as Asset[]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [settings, setAssets, setGroupedAssets]);

  return {
    assets,
    currentAsset,
    setCurrentAsset,
    clearCurrentAsset,
    fetchAsset,
    fetchAssets,
    getAsset,
    assetsProcessing: Object.values(assetsProcessingMap),
    getProcessingAsset,
    processAsset,
    removeProcessAsset,
  };
};

export default useImxAssets;
