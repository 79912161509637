import cx from 'classnames';

import styles from './styles.module.scss';

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: number;
  width?: number;
  type?: 'primary' | 'secondary' | 'muted' | 'white';
};

type BaseProps = Props & {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
};

const Base = (props: BaseProps) => {
  const { className, icon: Icon, size = 24, width = size, type, ...rest } = props;
  const cn = cx(
    styles.icon,
    {
      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.muted]: type === 'muted',
      [styles.white]: type === 'white',
    },
    className
  );
  return <Icon {...rest} className={cn} height={size} width={width ? width : size} />;
};

export default Base;
