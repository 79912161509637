import cx from 'classnames';

import Text from 'shared_components/Text';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  message?: string;
  title?: string;
  type?: 'primary' | 'secondary';
};

const Alert = (props: Props) => {
  const { className, message, title, type = 'primary' } = props;
  if (!title && !message) return null;
  return (
    <div
      className={cx(
        styles.alert,
        {
          [styles.primary]: type === 'primary',
          [styles.secondary]: type === 'secondary',
        },
        className
      )}
    >
      {title && (
        <Text
          uppercase
          as="h2"
          size="base"
          weight="bold"
          className={styles.title}
        >
          {title}
        </Text>
      )}
      <Text as="p" size="sm" className={styles.message}>
        {message}
      </Text>
    </div>
  );
};

export default Alert;
