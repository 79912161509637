import useAppArtists from './useAppArtists';
import useAppPacks from './useAppPacks';
import useAppSeasons from './useAppSeasons';
import useCardTemplates from './useCardTemplates';
import { usePersistedSettings } from './useSettingsStore';
import { useAlertSocketHandlers } from './useAlertSocket';
import { getCollectionCardTemplates, useAppCollections } from 'api/collections';
import useCollectionsStore from './useCollectionsStore';
import { flatten } from 'lodash';

const useAppInitialize = () => {
  useAppPacks();
  useAppArtists();
  usePersistedSettings();
  useAlertSocketHandlers();
  useInitializeCollections();
};

const useInitializeCollections = () => {
  const { seasons } = useAppSeasons();
  const {
    setTemplates,
    setTemplatesForCollection,
    setLoading,
  } = useCardTemplates();
  const { setCollection } = useCollectionsStore();

  setLoading(true);
  const { size, setSize } = useAppCollections(
    { seasons },
    {
      onSuccess: (collections) => {
        if (size < seasons.length) {
          setSize((s) => s + 1);
        } else {
          const allCollections = flatten(collections.map((c) => c.data));
          allCollections.forEach(async (collection) => {
            setCollection(collection);
            const { data: templates } = await getCollectionCardTemplates(
              collection.id
            );
            setTemplates(templates);
            setTemplatesForCollection(collection.id, templates);
          });
          setLoading(false);
        }
      },
    }
  );
};

export default useAppInitialize;
