import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import Web3 from 'web3';

import usePrevious from 'hooks/usePrevious';
import useOrderState from 'hooks/useOrderState';
import useSettingsStore from 'hooks/useSettingsStore';
import { useSettings } from 'api/settings';
import { stringifyUrlWithParam } from 'utils/routes';
import storage from 'utils/storage';
import { ContractAddress } from 'constants/contract';

import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon, CheckIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const clearOrderLocalData = () => {
  storage.removeItem('depositId');
  storage.removeItem('orderTxHash');
};

const DepositProcessingBar = () => {
  const history = useHistory();

  const { depositId, clearDepositId } = useOrderState();
  const prevDepositId = usePrevious(depositId);
  const [currentStatus, setCurrentStatus] = useState<string>();
  const [hidden, setHidden] = useState(Boolean(depositId));
  const [succeeded, setSucceeded] = useState(false);

  const skipInitialFetch = Boolean(!depositId || hidden);

  const persistedSettings = (useSettingsStore() || undefined) as
    | Settings
    | undefined;
  const { data } = useSettings(skipInitialFetch, undefined, {
    initialData: persistedSettings,
    revalidateOnFocus: !skipInitialFetch,
    revalidateOnReconnect: !skipInitialFetch,
  });
  const contractAddress = data?.eth?.depositContract
    ? data.eth.depositContract
    : ContractAddress.address;

  const poll = useRef<NodeJS.Timeout | null>();
  const clearPoll = useCallback(() => {
    if (poll.current) clearInterval(poll.current);
    poll.current = null;
  }, []);

  const clearData = useCallback(() => {
    setTimeout(() => {
      setHidden(true);
      setCurrentStatus(undefined);
      clearDepositId();
      clearOrderLocalData();
    }, 5000);
  }, [clearDepositId]);

  const handleSucceed = useCallback(() => {
    if (!succeeded) {
      setSucceeded(true);
      setCurrentStatus('success');
      history.push(
        stringifyUrlWithParam(history.location, {
          modal: 'success',
          depositId,
          tx: depositId,
        })
      );
      clearData();
      clearPoll();
    }
  }, [clearData, clearPoll, history, depositId, succeeded]);

  useEffect(() => {
    if (
      (!prevDepositId && depositId) ||
      (prevDepositId && prevDepositId !== depositId)
    )
      setSucceeded(false);
  }, [prevDepositId, depositId]);

  useEffect(() => {
    if (depositId) {
      const fn = async () => {
        // CHECK IF DEPOSIT HAS PROCESSED
        // const web3 = new Web3(window.ethereum);
        // const contract = new web3.eth.Contract(abi, contractAddress);
        // const response = await contract.methods.getOrderBalance(depositId).call();
        // if (response === '0') {
        //   setHidden(false);
        //   setCurrentStatus('processing');
        // } else handleSucceed();
      };
      fn();
      if (!poll.current) poll.current = setInterval(fn, 10000);
      return () => {
        clearPoll();
      };
    }
  }, [depositId, contractAddress, handleSucceed, clearPoll]);

  if (!depositId || hidden) return null;

  if (currentStatus === 'failure') {
    return (
      <div className={cx(styles.bar, styles.failure)}>
        <CloseIcon size={40} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR DEPOSIT FAILED, PLEASE TRY AGAIN
        </Text>
      </div>
    );
  }

  if (currentStatus === 'success') {
    return (
      <div className={cx(styles.bar, styles.success)}>
        <CheckIcon size={24} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR DEPOSIT WAS SUCCESSFUL
        </Text>
      </div>
    );
  }

  if (depositId) {
    return (
      <div className={cx(styles.bar, styles.progress)}>
        <Spinner size={24} type="white" />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR DEPOSIT IS IN PROGRESS
        </Text>
      </div>
    );
  }

  return (
    <div className={cx(styles.bar, styles.progress)}>
      <Spinner size={40} type="white" />
      <Text className={cx(styles.barText, styles.light)}>
        YOUR DEPOSIT IS IN PROGRESS
      </Text>
    </div>
  );
};

export default DepositProcessingBar;
