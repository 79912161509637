import { useEffect, useCallback } from 'react';

import socket from 'api/socket';
import useNotifications from './useNotifications';
import useAuth from './useAuth';

import notif2 from 'assets/sounds/notif2.mp3';
import { Routes } from 'constants/routes';

const ALERT_TYPE = 'alert';

type AlertEventResponse<AlertData> = {
  alertType: string;
  categoryId: number;
  created: string;
  data: AlertData;
  event: typeof ALERT_TYPE;
  id: number;
  message: string | null;
  seen: boolean;
  userId: number;
};

const useAlertSocket = <AlertData = Record<string, unknown>>(
  alertType: string,
  cb?: (data: AlertData) => void
) => {
  const handler = useCallback(
    (e: AlertEventResponse<AlertData>) => {
      if (e.alertType === alertType) cb?.(e.data);
    },
    [alertType, cb]
  );

  useEffect(() => {
    socket.on(ALERT_TYPE, handler);
    return () => {
      socket.off(ALERT_TYPE, handler);
    };
  }, [handler]);
};

export default useAlertSocket;

export const useAllAlertSockets = <AlertData = Record<string, unknown>>(
  cb: (data: AlertEventResponse<AlertData>) => void
) => {
  useEffect(() => {
    if (cb) {
      socket.on(ALERT_TYPE, cb);
      return () => {
        socket.off(ALERT_TYPE, cb);
      };
    }
  });
};

/**
 * Generic handlers for any incoming alert socket events (event.type = 'alert')
 * by their alertType (event.alertType, e.g. "market-sold")
 */
export const useAlertSocketHandlers = () => {
  const { addNotification } = useNotifications();
  const { isAuth } = useAuth();

  const goToAuction = (id: number) => {
    window.location.assign(`${Routes.auctions}/${id}`);
  };

  useEffect(() => {
    const handler = (
      alertEvent: AlertEventResponse<Record<string, unknown>>
    ) => {
      switch (alertEvent.alertType) {
        case 'market-sold':
          return (data: MarketSoldAlertData) => {
            if (isAuth && data) {
              addNotification({
                type: 'primary',
                title:
                  data.itemName +
                  ' sold on market for ' +
                  data.receivedAmount +
                  ' ETH',
                subtitle: 'You sold an item on the market',
              });
            }
            const audio = new Audio(notif2);
            audio.play();
          };
        case 'auction-outbid':
          return (data: AuctionOutbidData) => {
            if (isAuth && data) {
              addNotification({
                type: 'secondary',
                title: `Outbid with an offer of ${data.newBidAmount}`,
                subtitle: `You've been outbid`,
                onClick: () => goToAuction(data.auctionId),
              });
            }
            const audio = new Audio(notif2);
            audio.play();
          };
        default:
          return;
      }
    };

    socket.on(ALERT_TYPE, handler);
    return () => {
      socket.off(ALERT_TYPE, handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
};

type MarketSoldAlertData = {
  entityTemplateId: number;
  id: number;
  itemName: string;
  marketId: number;
  price: string;
  receivedAmount: string;
  type: ItemType;
};

type AuctionOutbidData = {
  auctionId: number;
  newBidAmount: string;
};
