import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

type TransactionsResponse = {
  total: number;
  count: number;
  transactions: Transaction[];
};

export const useTransactions = (
  skip?: boolean,
  config?: InfiniteConfig<TransactionsResponse>
) =>
  useRequestInfinite<TransactionsResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.count)) return null;
    return { url: `/user/transactions`, params: { page: index + 1 } };
  }, config);
