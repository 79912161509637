export const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch {
    return false;
  }
};

const getItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

const setItem = (key: string, value: string) => {
  try {
    return localStorage.setItem(key, value);
  } catch {
    return;
  }
};

const removeItem = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch {
    return;
  }
};

const clear = () => {
  try {
    return localStorage.clear();
  } catch {
    return;
  }
};

const storage = {
  getItem,
  setItem,
  removeItem,
  clear,
};

export default storage;
