import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

import { useAlertsUnseenCount } from 'api/alerts';
import { stringifyUrlWithParam } from 'utils/routes';

import Text from 'shared_components/Text';
import { BellIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const NotificationsButton = (props: Props) => {
  const { className } = props;
  const history = useHistory();
  const { data } = useAlertsUnseenCount();

  const total = data?.unseen;

  const handleClick = useCallback(() => {
    history.push(stringifyUrlWithParam(history.location, { drawer: 'alerts' }));
  }, [history]);

  return (
    <div
      role="button"
      onClick={handleClick}
      className={cx(styles.notificationsButton, className)}
    >
      <div className={styles.notificationsContent}>
        <BellIcon size={20} className={total ? styles.iconActive : styles.icon} />
        {Boolean(total) && (
          <div className={styles.notificationsTag}>
            <Text
              size="sm"
              weight="extrabold"
              type="white"
              className={styles.notificationsTagNumber}
            >
              {total}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsButton;
