import { useCallback } from 'react';

import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import { useEthUSDPrice } from 'hooks/useSettingsStore';
import { declineOffer } from 'api/offers';
import { trackEvent } from 'utils/analytics';

import Avatar from 'shared_components/Avatar';
import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import Text from 'shared_components/Text';

import styles from './styles.module.scss';

type OfferItemProps = {
  handleAcceptOffer: (offer: Offer) => void;
  itemName: string;
  offer: Offer;
  reload: () => Promise<unknown>;
  template: CardTemplate;
};

const OfferItem = (props: OfferItemProps) => {
  const { handleAcceptOffer, itemName, offer, reload, template } = props;
  const { addNotification } = useNotifications();
  const USD = useEthUSDPrice();

  const acceptState = useRequestState();

  const handleAccept = useCallback(async () => {
    handleAcceptOffer(offer);
    trackEvent('Market Offer', itemName, Number(offer.price) * Number(USD));
  }, [handleAcceptOffer, itemName, offer, USD]);

  const declineState = useRequestState();
  const handleDecline = useCallback(async () => {
    try {
      declineState.start();
      await declineOffer(offer.id);
      await reload();
      addNotification({
        type: 'secondary',
        title: 'Offer declined!',
        subtitle: 'You have declined this offer.',
        timeout: 5000,
      });
    } catch (e) {
      declineState.setApiError(e);
      declineState.end();
    }
  }, [offer, declineState, addNotification, reload]);

  return (
    <li className={styles.offer}>
      <img
        alt={template.uuid}
        src={template.images.size402}
        className={styles.cardImage}
      />

      <div className={styles.content}>
        <Text block weight="semibold" type="white" className={styles.cardName}>
          {itemName}
        </Text>

        <Text block weight="regular" size="xs" type="muted">
          by{' '}
          <Text weight="semibold" size="xs" type="white">
            {template.art.artists[0]?.name}
          </Text>
        </Text>

        <div className={styles.user}>
          <CurrencyValue
            value={offer.price}
            size={20}
            className={styles.price}
          />
          <Text
            uppercase
            weight="bold"
            size="xs"
            type="muted"
            className={styles.offered}
          >
            From
          </Text>
          <Avatar size={34} src={offer.user.avatar} />
          <Text weight="bold" type="white" className={styles.userName}>
            {offer.user.username}
          </Text>
        </div>

        <div className={styles.actions}>
          <Button
            size="sm"
            disabled={declineState.loading}
            loading={acceptState.loading}
            onClick={handleAccept}
            className={styles.action}
          >
            Accept Offer
          </Button>
          <Button
            size="sm"
            type="muted"
            disabled={acceptState.loading}
            loading={declineState.loading}
            onClick={handleDecline}
            className={styles.action}
          >
            Decline Offer
          </Button>
        </div>
      </div>
    </li>
  );
};

export default OfferItem;
