import { useCallback, useState } from 'react';

import { markAlertsAsSeen } from 'api/alerts';
import { useCurrentUser } from 'api/user';
import useCardTemplates from 'hooks/useCardTemplates';

import Text from 'shared_components/Text';
import { AuctionIcon, BellIcon, BidIcon, EthIconPlain, ImmutableIcon, MarketIcon, PacksIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import BaseAlertItem from './BaseAlertItem';
import usePackTemplates from 'hooks/usePackTemplates';
import CurrencyValue from 'shared_components/CurrencyValue';
import { startCase } from 'lodash';
import { getEtherscanTransactionLink } from 'utils/etherscan';

type DrawerItemProps = {
  alert: Alert;
};

const AlertsDrawerItem = (props: DrawerItemProps) => {
  const { alert } = props;
  const { data: currentUser } = useCurrentUser();

  const [markedAsSeen, update] = useState<boolean>(Boolean(alert?.seen));

  const handleSeen = useCallback(() => {
    update(true);
    markAlertsAsSeen({ ids: [alert.id], all: false });
  }, [alert.id]);

  const isSeen = alert.seen || markedAsSeen;

  return (
    <div
      onPointerEnter={isSeen ? undefined : handleSeen}
      className={styles.alertsDrawerItem}
    >
      {renderAlert(alert, currentUser)}
    </div>
  );
};

const renderAlert = (alert: Alert, currentUser?: User) => {
  const defaultTitleFormat = (alert: Alert) =>
    startCase(alert.alertType.replace(/[-_]+/g, ' '));

  switch (alert.alertType) {
    case 'achievement':
      return (
        <BaseAlertItem
          alert={alert}
          title="Achievement Reached"
          icon={<BellIcon />}
        >
          <Text block>{alert.data.title}</Text>
        </BaseAlertItem>
      );
      case 'auction-failed':
        return (
          <BaseAlertItem alert={alert} title="Auction Failed" icon={<AuctionIcon />}>
            <Text block size="xs">
              Your auction [ID:{alert.data.auctionId}] has failed to hit the reserve.
            </Text>
          </BaseAlertItem>
        );
    case 'auction-won':
      return (
        <BaseAlertItem alert={alert} title="Auction Won" icon={<AuctionIcon />}>
          <Text block size="xs">
            You have won auction [ID:{alert.data.auctionId}]
          </Text>
        </BaseAlertItem>
      );
    case 'auction-outbid':
      return (
        <BaseAlertItem alert={alert} title="You've been outbid" icon={<BidIcon />}>
          <Text block size="xs">
            You have been outbid on auction [ID:{alert.data.auctionId}]
          </Text>
        </BaseAlertItem>
      );
    case 'auction-win-failed':
      return (
        <BaseAlertItem alert={alert} title="Auction Reserve Not Met" icon={<AuctionIcon />}>
          <Text block size="xs">
            Auction [ID:{alert.data.auctionId}] ended before hitting the reserve.
          </Text>
        </BaseAlertItem>
      );

    case 'admin-card-gift':
      return (
        <BaseAlertItem alert={alert} title="Cards Gifted" icon={<BellIcon />}>
          <Text block size="xs">
            Gifted x{alert.data.amount} card(s)
          </Text>
        </BaseAlertItem>
      );

    case 'admin-pack-gift':
      return (
        <BaseAlertItem alert={alert} title="Packs Gifted" icon={<BellIcon />}>
          <Text block size="xs">
            Gifted x{alert.data.amount} pack(s)
          </Text>
        </BaseAlertItem>
      );

    case 'eth-deposit-complete':
      return (
        <BaseAlertItem alert={alert} title="Eth Deposited" icon={<EthIconPlain />}>
          <div className={styles.success}>
            <Text uppercase weight="semibold" size="2xs">
              Success
            </Text>
          </div>
          <Text block size="xs">
            Deposited {alert.data?.eth ?? 'some'} eth
          </Text>
          {alert.data?.ethTransaction && (
            <Text block size="xs" underlined type="primary">
              <a
                href={getEtherscanTransactionLink(alert.data.ethTransaction)}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Etherscan
              </a>
            </Text>
          )}
        </BaseAlertItem>
      );

    case 'eth-withdraw-complete':
      return (
        <BaseAlertItem alert={alert} title="Eth Withdrawn" icon={<BellIcon />}>
          <div className={styles.success}>
            <Text uppercase weight="semibold" size="2xs">
              Success
            </Text>
          </div>
          <Text block size="xs">
            Withdrew {alert.data?.eth ?? 'some'} eth
          </Text>
          {alert.data?.ethTransaction && (
            <Text block size="xs" underlined type="primary">
              <a
                href={getEtherscanTransactionLink(alert.data.ethTransaction)}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Etherscan
              </a>
            </Text>
          )}
        </BaseAlertItem>
      );

      case 'imx-card-sent':
        return (
          <BaseAlertItem alert={alert} title="Asset Sent To ImmutableX" icon={<ImmutableIcon />}>
            <div className={styles.success}>
              <Text uppercase weight="semibold" size="2xs">
                Success
              </Text>
            </div>
            <Text block size="xs">
              <Text size="xs" type="white" weight="regular">{alert.data?.itemName}</Text> was successfuly sent to your ImmutableX Wallet
            </Text>
          </BaseAlertItem>
        );
      case 'imx-card-received':
        return (
          <BaseAlertItem alert={alert} title="Asset Recieved From ImmutableX" icon={<ImmutableIcon />}>
            <div className={styles.success}>
              <Text uppercase weight="semibold" size="2xs">
                Success
              </Text>
            </div>
            <Text block size="xs">
              <Text size="xs" type="white" weight="regular">{alert.data?.itemName}</Text> has been returned to you Kolectiv Collection
            </Text>
          </BaseAlertItem>
        );

    case 'market-bought':
      return (
        <BaseAlertItem
          alert={alert}
          title="Market Purchase"
          icon={<MarketIcon />}
        >
          <Text block size="xs">
            Purchased {alert.data.itemName ? `${alert.data.itemName} ` : ''}
            {alert.data.type} from market for{' '}
            <CurrencyValue inline value={alert.data.price} size={12} />
          </Text>
        </BaseAlertItem>
      );

    case 'market-listed': {
      const text = (name: string | undefined) =>
        `You listed ${name || alert.data.type}`;

      return (
        <BaseAlertItem alert={alert} title="Market Listed" icon={<MarketIcon />}>
          <Text block size="xs">
            {text(alert.data.itemName)} for {' '}
            <CurrencyValue inline value={alert.data.price} size={12} />
          </Text>
        </BaseAlertItem>
      );
    }

    case 'market-unlisted': {
      const text = (name: string | undefined) =>
        `You removed your market-listed ${name || alert.data.type}`;

      return (
        <BaseAlertItem
          alert={alert}
          title="Market Unlisted"
          icon={<MarketIcon />}
        >
          <Text block size="xs">
            {text(alert.data.itemName)} (
            <CurrencyValue inline value={alert.data.price} size={12} />)
          </Text>
        </BaseAlertItem>
      );
    }

    case 'market-sold':
      return alert.data.itemName ? (
        <BaseAlertItem alert={alert} title="Market Sale" icon={<MarketIcon />}>
          <Text block size="xs">
            Sold {alert.data.itemName ?? alert.data.type} for{' '}
            <CurrencyValue inline value={alert.data.price} size={12} />
          </Text>
        </BaseAlertItem>
      ) : (
        <WithCardTemplate cardTemplateId={alert.data.entityTemplateId}>
          {(template) => (
            <BaseAlertItem
              alert={alert}
              title="Market Sale"
              icon={<BellIcon />}
            >
              <Text block size="xs">
                Sold {template?.title ?? alert.data.type} for{' '}
                <CurrencyValue inline value={alert.data.price} size={12} />
              </Text>
            </BaseAlertItem>
          )}
        </WithCardTemplate>
      );

    case 'pack-opened':
      return (
        <BaseAlertItem alert={alert} title="Pack Opened" icon={<PacksIcon />}>
          <Text block size="xs">
            Opened {alert?.data?.packTemplate?.name ?? 'pack'} containing{' '}
            {alert?.data?.items?.every(Boolean)
              ? alert?.data?.items?.join(', ')
              : 'various items'}
          </Text>
        </BaseAlertItem>
      );

    case 'pack-order-expired':
      return (
        <WithPackTemplate packTemplateId={alert?.data?.packTemplateId}>
          {(template) => (
            <BaseAlertItem
              alert={alert}
              title="Order Expired"
              icon={<PacksIcon />}
            >
              <Text block size="xs">
                Order for {template?.name ?? 'pack'} (x{alert.data.amount}) has
                expired
              </Text>
            </BaseAlertItem>
          )}
        </WithPackTemplate>
      );

    case 'pack-purchased':
      return (
        <WithPackTemplate packTemplateId={alert?.data?.packTemplateId}>
          {(template) => (
            <BaseAlertItem
              alert={alert}
              title="Pack Purchased"
              icon={<PacksIcon />}
            >
              <Text block size="xs">
                Purchased{' '}
                {alert.data?.packTemplate?.name ?? template?.name ?? 'pack'} (x
                {alert.data.amount})
              </Text>
            </BaseAlertItem>
          )}
        </WithPackTemplate>
      );

    case 'offer-received':
      return (
        <BaseAlertItem alert={alert} title="Offer Received" icon={<BidIcon />}>
          <Text block size="xs"><Text size="xs" type="white" weight="regular">{alert.data?.itemName}</Text> has an open offer of <CurrencyValue inline value={alert.data?.amount} /></Text>

        </BaseAlertItem>
      );

    case 'offer-accepted':
      return (
        <BaseAlertItem alert={alert} title="Offer Accepted" icon={<BidIcon />}>
          <Text block size="xs">Your offer for <Text size="xs" type="white" weight="regular">{alert.data?.itemName}</Text> has been accepted</Text>

        </BaseAlertItem>
      );
      case 'offer-declined':
        return (
          <BaseAlertItem alert={alert} title="Offer Declined" icon={<BidIcon />}>
            <Text block size="xs">Your offer for <Text size="xs" type="white" weight="regular">{alert.data?.itemName}</Text> has been declined</Text>

          </BaseAlertItem>
        );

    case 'trade-declined':
      return (
        <BaseAlertItem alert={alert} title="Trade Declined" icon={<BellIcon />}>
          <Text block size="xs">
            {alert.data.byUser.id === currentUser?.id
              ? `You declined trade with ${alert.data.withUser.username}`
              : `${alert.data.byUser.username} declined your trade`}
          </Text>
        </BaseAlertItem>
      );

    default:
      return (
        <BaseAlertItem
          alert={alert}
          title={defaultTitleFormat(alert)}
          icon={<BellIcon />}
        >
          {alert.data?.title}
        </BaseAlertItem>
      );
  }
};

////////////////////////////////////////////////////////////
// Some fallbacks if we need something thats not provided

const WithCardTemplate = ({
  cardTemplateId,
  children,
}: {
  cardTemplateId: number;
  children: (template: CardTemplate) => any;
}) => {
  const cardTemplates = useCardTemplates((state) => state.templates);
  const cardTemplate = cardTemplates[cardTemplateId];
  return children(cardTemplate);
};

const WithPackTemplate = ({
  packTemplateId,
  children,
}: {
  packTemplateId: number;
  children: (template: PackTemplate) => any;
}) => {
  const packTemplates = usePackTemplates((state) => state.templates);
  const packTemplate = packTemplates[packTemplateId];
  return children(packTemplate);
};

export default AlertsDrawerItem;
