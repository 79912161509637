import { keyBy } from 'lodash';
import create from 'zustand';

type CardTemplatesState = {
  templates: Record<string, CardTemplate>;
  templatesByCollection: Record<number, CardTemplate[]>;
  loading: boolean;
  setLoading: (b: boolean) => void;
  getTemplate: (templateId: number) => CardTemplate;
  setTemplates: (templates: CardTemplate[]) => void;
  setTemplatesForCollection: (
    collectionId: number,
    templates: CardTemplate[]
  ) => void;
  getTemplatesByCollection: (collectionId: number) => CardTemplate[];
};

const useCardTemplates = create<CardTemplatesState>((set, get) => ({
  templates: {},
  templatesByCollection: {},
  loading: false,
  setLoading: (bool) => {
    return { loading: bool };
  },
  getTemplate: (id) => {
    const { templates } = get();
    return templates[id];
  },
  setTemplates: (templates) =>
    set((state) => {
      const newTemplates = { ...state.templates, ...keyBy(templates, 'id') };
      return { templates: newTemplates };
    }),
  setTemplatesForCollection: (collectionId, templates) =>
    set((state) => {
      return {
        templatesByCollection: {
          ...state.templatesByCollection,
          [collectionId]: templates,
        },
      };
    }),
  getTemplatesByCollection: (collectionId) => {
    const { templatesByCollection } = get();
    return templatesByCollection[collectionId];
  },
}));

export default useCardTemplates;
