import request from './request';
import useRequest, { Config } from './swr';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

export type MarketTemplatesResponse = {
  total: number;
  type: 'card';
  templates: MarketTemplate[];
};

export const useMarketTemplates = (
  skip?: boolean,
  params?: {
    need?: boolean;
    season?: string;
    tier?: string;
    type?: ItemType;
    artistId?: number;
    cardTemplateId?: number;
  },
  config?: InfiniteConfig<MarketTemplatesResponse>
) =>
  useRequestInfinite<MarketTemplatesResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.templates.length))
      return null;
    return {
      url: `/market/templates`,
      params: {
        need: params?.need,
        page: index + 1,
        season: params?.season,
        tier: params?.tier,
        type: params?.type,
        artistId: params?.artistId,
        templateIds: params?.cardTemplateId,
      },
    };
  }, config);

export type MarketTemplateListingsResponse = {
  total: number;
  count: number;
  market: [MarketTemplateListing[]];
  recentSales: RecentSale[];
};

export const useMarketTemplateListings = (
  templateId?: number,
  type: ItemType = 'card',
  config?: InfiniteConfig<MarketTemplateListingsResponse>
) =>
  useRequestInfinite<MarketTemplateListingsResponse>((index, prevPageData) => {
    if (!templateId || (prevPageData && !prevPageData.data.count)) return null;
    return {
      url: `/market/buy`,
      params: { page: index + 1, templateId, type },
    };
  }, config);

export const listItemOnMarket = (data: {
  id: number;
  price: string;
  type: ItemType;
}) =>
  request.post<{ marketId: number }>(`/market/list`, {
    type: data.type,
    id: data.id,
    price: data.price,
  });

export const updateListing = (data: { id: number; price: string }) =>
  request.patch<{ marketId: number }>(`/market/listed/${data.id}`, {
    price: data.price,
  });

export const delistItemFromMarket = (data: { marketId: number }) =>
  request.delete(`/market/listed/${data.marketId}`);

export const buyMarketListing = (data: { marketId: number; price: string }) =>
  request.post<{ success: boolean }>(`/market/buy`, {
    marketId: data.marketId,
    price: data.price,
  });

export type UserMarketListingsResponse = {
  total: number;
  count: number;
  market: MarketTemplateListing[];
};

export const useUserMarketListings = (
  skip?: boolean,
  params?: { userId?: number; need?: boolean; type?: ItemType },
  config?: InfiniteConfig<UserMarketListingsResponse>
) =>
  useRequestInfinite<UserMarketListingsResponse>((index, prevPageData) => {
    if (skip || !params?.userId || (prevPageData && !prevPageData.data.count))
      return null;
    return {
      url: `/market/listed/users/${params.userId}`,
      params: {
        need: params?.need,
        page: index + 1,
        type: params?.type,
      },
    };
  }, config);

export type MarketTopUsersResponse = {
  top: {
    user: {
      id: User['id'];
      username: User['username'];
      avatar: User['avatar'];
      group: User['group'];
      groups: User['groups'];
    };
    total: string;
  }[];
};

export const useMarketTopUsers = (
  skip?: boolean,
  params?: {
    type: MarketReportUsersType;
    range: MarketReportUsersRange;
    season?: string | undefined;
  },
  config?: Config<MarketTopUsersResponse>
) =>
  useRequest<MarketTopUsersResponse>(
    !skip ? { url: `/market/reports/top-users`, params } : null,
    config
  );

export type MarketTopSalesResponse = {
  top: {
    entityType: 'card';
    card: Card;
    user: {
      id: User['id'];
      username: User['username'];
      avatar: User['avatar'];
      group: User['group'];
      groups: User['groups'];
    };
    price: string;
    updated: string;
  }[];
};

export const useMarketTopSales = (
  skip?: boolean,
  params?: { range: MarketReportSalesRange; season?: string },
  config?: Config<MarketTopSalesResponse>
) =>
  useRequest<MarketTopSalesResponse>(
    !skip ? { url: `/market/reports/top-sales`, params } : null,
    config
  );
