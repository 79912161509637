import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

type ArtistsResponse = {
  total: number;
  limit: number;
  artists: Artist[];
};

export const useArtists = (
  params?: { seasons?: string },
  config?: InfiniteConfig<ArtistsResponse>
) =>
  useRequestInfinite<ArtistsResponse>((index, prevPageData) => {
    if (prevPageData && !prevPageData.data.artists.length) return null;
    return {
      method: 'GET',
      url: `/art/artists`,
      params: { page: index + 1, ...(params ?? {}) },
    };
  }, config);
