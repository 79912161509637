import { Helmet } from 'react-helmet-async';
import { Route, RouteProps } from 'react-router';

// not sure where to retrieve the type of `ComputedMatch` which we get passed
// automatically to <Route> (assuming via a context)

// you'll see it logged in `routeProps`

type ComputedMatch = {
  isExact: boolean;
  params: Record<string, string>;
  path: string;
  url: string;
};

const TitledRoute = ({
  title,
  exactTitle,
  ...routeProps
}: RouteProps & {
  title: string | ((match: ComputedMatch) => string);
  exactTitle?: boolean;
  computedMatch?: ComputedMatch;
}) => {
  const match = routeProps.computedMatch as ComputedMatch;

  const outputTitle: string = typeof title === 'string' ? title : title(match);
  return (
    <>
      <Helmet title={exactTitle ? outputTitle : `Kolectiv • ${outputTitle}`} />
      <Route {...routeProps} />
    </>
  );
};

export default TitledRoute;
