import cx from 'classnames';
import { EthIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  size?: number;
  width?: number;
};

const CurrencyIcon = (props: Props) => {
  const { className, size = 16, width = 16, } = props;
  const cn = cx(styles.currencyIcon, className);
  return (
    <div className={cn}>
      <EthIcon size={size} width={width ? width : size}  />
    </div>
  );
};

export default CurrencyIcon;
