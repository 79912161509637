import cx from 'classnames';

import styles from './styles.module.scss';
import { CheckIcon, KolectivIcon } from 'shared_components/SVGIcons';
import Text from 'shared_components/Text';

type Props = {
  verifiedType?: [number];
  size?: number;
};

const VerifiedBadge = (props: Props) => {
  const { verifiedType, size = 0 } = props;
  const badgeSize = Math.floor(size / 4);

  if(!verifiedType){
    return null;
  }


  const type = verifiedType.includes(1) === true ? 'staff' : verifiedType.includes(21) === true ? 'artist' : verifiedType.includes(22) === true ? 'founder' : verifiedType.includes(23) === true ? 'verified' : 0;

  if (type !== 'artist' && type !== 'staff' && type !== 'founder' && type !== 'verified') return null;

  return (
    <div
      className={styles.iconContainer}
    >
      <div
        style={{ width: badgeSize, height: badgeSize, borderRadius: badgeSize, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        className={cx(styles.icon, styles[type])}
      >
        <Text size="2xs" weight="semibold" type="muted" className={styles.typeLabel}>{type.toUpperCase()}</Text>
        {type !== 'founder' ?
          <CheckIcon size={badgeSize / 1.6} className={styles.checkIcon} />
        :
          <KolectivIcon size={badgeSize / 1.5} className={styles.founderIcon} />
        }
      </div>
    </div>
  );
};

export default VerifiedBadge;
