import request from './request';
import useRequest, { Config } from './swr';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

type AlertsResponse = {
  count: number;
  total: number;
  unseen: number;
  alerts: Alert[];
};

type AlertsUnseen = Pick<AlertsResponse, 'unseen'>;

export const useAlerts = (
  skip?: boolean,
  config?: InfiniteConfig<AlertsResponse>
) =>
  useRequestInfinite<AlertsResponse>((index, prevPageData) => {
    if (skip || (prevPageData && !prevPageData.data.count)) return null;
    return { url: `/alerts`, params: { page: index + 1 } };
  }, config);

export const useAlertsUnseenCount = (
  skip?: boolean,
  config?: Config<AlertsUnseen>
) => useRequest<AlertsUnseen>(skip ? null : { url: `/alerts/count` }, config);

type Body = { all?: boolean; ids?: number[] };

export const markAlertsAsSeen = (body: Body = { all: false, ids: [] }) =>
  request.patch<AlertsResponse>(`/alerts/seen`, body);
