import { useCallback, useRef } from 'react';
import { Link, ERC721TokenType } from '@imtbl/imx-sdk';

import { IMX_LINK_URL } from 'config';
import { useSettings } from 'api/settings';
import useSettingsStore from './useSettingsStore';
import useImxAssets from './useImxAssets';

const useImxActions = () => {
  const persistedSettings = useSettingsStore();
  const { data: settings } = useSettings(false, undefined, {
    initialData: persistedSettings as Settings,
  });
  const { fetchAssets, processAsset, removeProcessAsset } = useImxAssets();

  const link = useRef(new Link(IMX_LINK_URL, { className: 'imxBlock' }));

  const setupImxLink = useCallback(() => {
    return link.current.setup({});
  }, []);

  const imxToKolectiv = useCallback(
    async (uuid: string, assetId?: string) => {
      try {
        if (assetId && settings?.eth?.tokenContract) {
          processAsset({
            uuid,
            assetId,
            assetStatus: undefined,
            cardStatus: 'available',
            type: 'transferToKolectiv',
          });
          await link.current.transfer([
            {
              type: ERC721TokenType.ERC721,
              tokenAddress: settings.eth.tokenContract,
              tokenId: assetId,
              toAddress: settings.eth.tokenEscrow,
            },
          ]);
          await fetchAssets();
        }
      } catch {
        removeProcessAsset(uuid);
      }
    },
    [settings, fetchAssets, processAsset, removeProcessAsset]
  );

  const imxToMainnet = useCallback(
    async (uuid: string, assetId?: string) => {
      try {
        if (assetId && settings?.eth?.tokenContract) {
          processAsset({
            uuid,
            assetId,
            assetStatus: 'preparing_withdrawal',
            cardStatus: 'imx_locked',
            type: 'prepareWithdrawal',
          });
          await link.current.prepareWithdrawal({
            type: ERC721TokenType.ERC721,
            tokenAddress: settings.eth.tokenContract,
            tokenId: assetId,
          });
          await fetchAssets();
        }
      } catch {
        removeProcessAsset(uuid);
      }
    },
    [settings, fetchAssets, processAsset, removeProcessAsset]
  );

  const deposit = useCallback(
    async (uuid: string, assetId?: string) => {
      try {
        if (assetId && settings?.eth?.tokenContract) {
          processAsset({
            uuid,
            assetId,
            assetStatus: 'imx',
            cardStatus: 'imx_locked',
            type: 'deposit',
          });
          await link.current.deposit({
            type: ERC721TokenType.ERC721,
            tokenId: assetId,
            tokenAddress: settings.eth.tokenContract,
          });
          await fetchAssets();
        }
      } catch {
        removeProcessAsset(uuid);
      }
    },
    [settings, fetchAssets, processAsset, removeProcessAsset]
  );

  const completeWithdrawal = useCallback(
    async (uuid: string, assetId?: string) => {
      try {
        if (assetId && settings?.eth?.tokenContract) {
          await link.current.completeWithdrawal({
            type: ERC721TokenType.ERC721,
            tokenAddress: settings.eth.tokenContract,
            tokenId: assetId,
          });
          processAsset({
            uuid,
            assetId,
            assetStatus: 'eth',
            cardStatus: 'eth_locked',
            type: 'completeWithdrawal',
          });
          await fetchAssets();
        }
      } catch {
        removeProcessAsset(uuid);
      }
    },
    [settings, fetchAssets, processAsset, removeProcessAsset]
  );

  return {
    setupImxLink,
    imxToKolectiv,
    imxToMainnet,
    deposit,
    completeWithdrawal,
  };
};

export default useImxActions;
