import {
  useSWRInfinite,
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from 'swr';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import axios from './request';

export type GetRequest = AxiosRequestConfig | null;

interface InfiniteReturn<Data, Error>
  extends Pick<
    SWRInfiniteResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'revalidate' | 'error' | 'mutate' | 'size' | 'setSize'
  > {
  data: Data[] | undefined;
  response: AxiosResponse<Data>[] | undefined;
}

export interface InfiniteConfig<Data = unknown, Error = unknown>
  extends Omit<
    SWRInfiniteConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    'initialData'
  > {
  initialData?: Data[];
}

export default function useRequestInfinite<Data = unknown, Error = unknown>(
  getRequest: (
    index: number,
    previousPageData: AxiosResponse<Data> | null
  ) => GetRequest,
  { initialData, ...config }: InfiniteConfig<Data, Error> = {}
): InfiniteReturn<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    revalidate,
    mutate,
    size,
    setSize,
  } = useSWRInfinite<AxiosResponse<Data>, AxiosError<Error>>(
    (index, previousPageData) => {
      const key = getRequest(index, previousPageData);
      return key ? JSON.stringify(key) : null;
    },
    (request) => axios(JSON.parse(request)),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      ...config,
      initialData:
        initialData &&
        initialData.map((i) => ({
          status: 200,
          statusText: 'InitialData',
          config: {},
          headers: {},
          data: i,
        })),
    }
  );

  return {
    data:
      response && Array.isArray(response)
        ? response.map((r) => r.data)
        : undefined,
    response,
    error,
    isValidating,
    revalidate,
    mutate,
    size,
    setSize,
  };
}
