import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Routes } from 'constants/routes';

import Text from 'shared_components/Text';
import {
  SVGIcon,
  AuctionIcon,
  CollectionIcon,
  MarketIcon,
  PacksIcon,
  LeaderboardIcon,
  HomeIcon,
  DiscordIcon,
  TwitterIcon,
  NewIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  isMobileMenuVisible?: boolean;
  groups?: User['groups']
};

export const SignedInHeaderNavItems = (props: Props) => {
  const { className, groups } = props;
  const isStaff = groups?.includes(1)
  return (
    <div className={cx(styles.headerNavItems, className)}>
      <NavItem label="Home" icon={HomeIcon} path={Routes.home} />
      <NavItem
        label="Collection"
        icon={CollectionIcon}
        path={Routes.collection}
      />
      <NavItem label="New Drops" icon={NewIcon} path={Routes.drops} />
      <NavItem label="Market" icon={MarketIcon} path={Routes.market} />
      <NavItem label="Auctions" icon={AuctionIcon} path={Routes.auctions} />
      <NavItem
        label="Leaderboard"
        icon={LeaderboardIcon}
        path={Routes.leaderboard}
      />
      <a
        href="https://discord.com/invite/JBF26ht9cY"
        target="_blank"
        rel="noreferrer"
        className={cx(styles.navItem, styles.discordNavItem)}
      >
        <Text
        size="xs"
        weight="bold"
        uppercase
        type={'muted'}
        className={styles.navItemLabel}
        >
          Join Our Discord
        </Text>
        <DiscordIcon size={24} type="muted" className={styles.discordIcon} />
      </a>
      <a
        href="https://twitter.com/KolectivGG"
        target="_blank"
        rel="noreferrer"
        className={styles.navItem}
      >
        <Text
        size="xs"
        weight="bold"
        uppercase
        type={'muted'}
        className={styles.navItemLabel}
        >
          Follow Us On Twitter
        </Text>
        <TwitterIcon size={24} type="muted" className={styles.discordIcon} />
      </a>
    </div>
  );
};

export const MobileSignedInSocialNavItems = (props: Props) => {
  const { className, groups } = props;
  const isStaff = groups?.includes(1)
  return (
    <>
      <div className={cx(styles.socialHeaderNavItems, className)}>
        <a
          href="https://discord.com/invite/JBF26ht9cY"
          target="_blank"
          rel="noreferrer"
          className={styles.navItem}
        >
          <DiscordIcon size={24} type="muted" className={styles.discordIcon} />
          <Text
          size="xs"
          weight="bold"
          uppercase
          type={'muted'}
          className={styles.navItemLabel}
          >
            Join Our Discord
          </Text>
        </a>
        <a
          href="https://twitter.com/KolectivGG"
          target="_blank"
          rel="noreferrer"
          className={styles.navItem}
        >
          <TwitterIcon size={24} type="muted" className={styles.discordIcon} />
          <Text
          size="xs"
          weight="bold"
          uppercase
          type={'muted'}
          className={styles.navItemLabel}
          >
            Follow Us On Twitter
          </Text>
        </a>
      </div>
    </>
  );
};



export const SignedOutHeaderNavItems = (props: Props) => {
  const { className } = props;
  return (
    <div className={cx(styles.headerNavItems, className)}>
      <NavItem label="Home" icon={HomeIcon} path={Routes.home} />
      <NavItem label="New Drops" icon={NewIcon} path={Routes.drops} />
      <NavItem label="Market" icon={MarketIcon} path={Routes.market} />
      <NavItem label="Auctions" icon={AuctionIcon} path={Routes.auctions} />
      <NavItem
        label="Leaderboard"
        icon={LeaderboardIcon}
        path={Routes.leaderboard}
      />
      <a
        href="https://discord.com/invite/JBF26ht9cY"
        target="_blank"
        rel="noreferrer"
        className={cx(styles.navItem, styles.discordNavItem)}
      >
        <DiscordIcon size={24} type="muted" className={styles.discordIcon} />
        <Text
        size="xs"
        weight="bold"
        uppercase
        type={'muted'}
        className={styles.navItemLabel}
        >
          Join Our Discord
        </Text>
      </a>
      <a
        href="https://twitter.com/KolectivGG"
        target="_blank"
        rel="noreferrer"
        className={styles.navItem}
      >
        <TwitterIcon size={24} type="muted" className={styles.discordIcon} />
        <Text
        size="xs"
        weight="bold"
        uppercase
        type={'muted'}
        className={styles.navItemLabel}
        >
          Follow Us On Twitter
        </Text>
      </a>
    </div>
  );
};

export const MobileSignedInHeaderNavItems = (props: Props) => {
  const { className, groups } = props;
  const isStaff = groups?.includes(1)
  return (
    <>
    <div className={cx(styles.headerNavItems, className)}>
      <NavItem label="Home" icon={HomeIcon} path={Routes.home} />
      <NavItem
        label="Collection"
        icon={CollectionIcon}
        path={Routes.collection}
      />
      <NavItem label="New Drops" icon={NewIcon} path={Routes.drops} />
      <NavItem label="Market" icon={MarketIcon} path={Routes.market} />
      <NavItem label="Auctions" icon={AuctionIcon} path={Routes.auctions} />
      <NavItem
        label="Leaderboard"
        icon={LeaderboardIcon}
        path={Routes.leaderboard}
      />
    </div>
    </>
  );
};

export const MobileSignedOutHeaderNavItems = (props: Props) => {
  const { className } = props;
  return (
    <>
      <div className={cx(styles.headerNavItems, className)}>
        <NavItem label="Home" icon={HomeIcon} path={Routes.home} />
        {/* <NavItem
          label="Collection"
          icon={CollectionIcon}
          path={Routes.collection}
        /> */}
        <NavItem label="New Drops" icon={NewIcon} path={Routes.drops} />
        <NavItem label="Market" icon={MarketIcon} path={Routes.market} />
        <NavItem label="Auctions" icon={AuctionIcon} path={Routes.auctions} />
        <NavItem
          label="Leaderboard"
          icon={LeaderboardIcon}
          path={Routes.leaderboard}
        />
      </div>

    </>
  );
};

type NavItemProps = {
  icon?: SVGIcon;
  label: string;
  path: string;
};

const NavItem = (props: NavItemProps) => {
  const { icon: Icon, label, path } = props;
  const { pathname } = useLocation();
  const active = pathname === path;
  return (
    <Link
      to={path}
      className={cx(styles.navItem, active && styles.navItemActive)}
    >
      {Icon && <Icon size={24} type={active ? 'white' : undefined} />}
      <Text
        size="xs"
        weight="bold"
        uppercase
        type={active ? 'white' : 'muted'}
        className={styles.navItemLabel}
      >
        {label}
      </Text>
    </Link>
  );
};
