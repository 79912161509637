import cx from 'classnames';

import { useCurrentUser } from 'api/user';
import { buildPathParams } from 'utils/routes';
import { Routes } from 'constants/routes';

import Avatar from 'shared_components/Avatar';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const UserAvatar = (props: Props) => {
  const { className } = props;
  const { data: user } = useCurrentUser();
  return (
    <div className={cx(styles.userAvatar, className)}>
      <Avatar
        src={user?.avatar}
        groups={user?.groups}
        to={buildPathParams(Routes.profile, { username: user?.username })}
      />
    </div>
  );
};

export default UserAvatar;
