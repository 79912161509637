import { Route, Redirect, RouteProps } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { Routes } from 'constants/routes';

const PrivateRoute = (props: RouteProps & { component: React.FC }) => {
  const { component: Component, ...routeProps } = props;
  const { isAuth } = useAuth();
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) =>
        isAuth ? (
          <Component {...routeComponentProps} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.landing,
              state: { previousLocation: routeComponentProps.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
