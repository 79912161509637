import { useCallback } from 'react';

import { CollectionsBySeason } from 'constants/collections';
import { useCollectionUserOwned } from 'api/collections';
import { useCurrentUser } from 'api/user';

import useAuth from './useAuth';

const useOwnedCards = (
  userId: number | undefined,
  season: keyof typeof CollectionsBySeason
) => {
  const { isAuth } = useAuth();
  const { data: user } = useCurrentUser(!isAuth);

  const Collection = CollectionsBySeason[season];

  const {
    data: coreData,
    mutate: r1,
    isValidating: v1,
  } = useCollectionUserOwned(false, Collection?.core, userId || user?.id);

  const {
    data: specialData,
    mutate: r2,
    isValidating: v2,
  } = useCollectionUserOwned(false, Collection?.special, userId || user?.id);

  const {
    data: foundersData,
    mutate: r3,
    isValidating: v3,
  } = useCollectionUserOwned(false, Collection?.founders, userId || user?.id);

  const core = coreData?.cards || [];
  const special = specialData?.cards || [];
  const founders = foundersData?.cards || [];
  const merged = [...core, ...special, ...founders];

  const reload = useCallback(() => {
    const reqs: Promise<unknown>[] = [r1(), r2(), r3()];
    return Promise.all(reqs);
  }, [r1, r2, r3]);

  return {
    core,
    special,
    founders,
    merged,
    reload,
    loading: v1 || v2 || v3,
  };
};

export default useOwnedCards;
