import Base, { Props } from './base';

import { ReactComponent as arrowRightIcon } from './icons/arrow_right_icon.svg';
import { ReactComponent as arrowLeftIcon } from './icons/arrow_left_icon.svg';
import { ReactComponent as bellIcon } from './icons/bell_icon.svg';
import { ReactComponent as checkIcon } from './icons/check_icon.svg';
import { ReactComponent as chevronDownIcon } from './icons/chevron_down_icon.svg';
import { ReactComponent as chevronLeftIcon } from './icons/chevron_left_icon.svg';
import { ReactComponent as chevronRightIcon } from './icons/chevron_right_icon.svg';
import { ReactComponent as closeIcon } from './icons/close_icon.svg';
import { ReactComponent as collectionIcon } from './icons/collection_icon.svg';
import { ReactComponent as packsIcon } from './icons/packs_icon.svg';
import { ReactComponent as ethWalletIcon } from './icons/eth_wallet_icon.svg';
import { ReactComponent as homeIcon } from './icons/home_icon.svg';
import { ReactComponent as leaderboardIcon } from './icons/leaderboard_icon.svg';
import { ReactComponent as logoutIcon } from './icons/logout_icon.svg';
import { ReactComponent as marketIcon } from './icons/market_icon.svg';
import { ReactComponent as menuIcon } from './icons/menu_icon.svg';
import { ReactComponent as plusIcon } from './icons/plus_icon.svg';
import { ReactComponent as shopIcon } from './icons/shop_icon.svg';
import { ReactComponent as transactionsIcon } from './icons/transactions_icon.svg';
import { ReactComponent as trophyCheckIcon } from './icons/trophy_check_icon.svg';
import { ReactComponent as ethIcon } from './icons/eth_icon.svg';
import { ReactComponent as ethInIcon } from './icons/ethIn_icon.svg';
import { ReactComponent as ethOutIcon } from './icons/ethOut_icon.svg';
import { ReactComponent as ethIconPlain } from './icons/eth_icon_plain.svg';
import { ReactComponent as ethIconPlus } from './icons/eth_icon_plus.svg';
import { ReactComponent as ethVault } from './icons/eth_vault_icon.svg';
import { ReactComponent as twitterIcon } from './icons/twitter_icon.svg';
import { ReactComponent as instagramIcon } from './icons/instagram_icon.svg';
import { ReactComponent as discordIcon } from './icons/discord_icon.svg';
import { ReactComponent as userIcon } from './icons/user_icon.svg';
import { ReactComponent as metaMask } from './icons/metamask-fox.svg';
import { ReactComponent as timerIcon } from './icons/timer_icon.svg';
import { ReactComponent as copyIcon } from './icons/copy_icon.svg';
import { ReactComponent as shareIcon } from './icons/share_icon.svg';
import { ReactComponent as soundOnIcon } from './icons/sound_on_icon.svg';
import { ReactComponent as soundOffIcon } from './icons/sound_off_icon.svg';
import { ReactComponent as immutableIcon } from './icons/immutable_icon.svg';
import { ReactComponent as immutableInIcon } from './icons/immutableIn_icon.svg';
import { ReactComponent as immutableOutIcon } from './icons/immutableOut_icon.svg';
import { ReactComponent as lockIcon } from './icons/lock_icon.svg';
import { ReactComponent as mintIcon } from './icons/mint_icon.svg';
import { ReactComponent as ghostIcon } from './icons/ghost_icon.svg';
import { ReactComponent as faceHappyIcon } from './icons/face_happy_icon.svg';
import { ReactComponent as faceToothIcon } from './icons/face_tooth_icon.svg';
import { ReactComponent as faceCoolIcon } from './icons/face_cool_icon.svg';
import { ReactComponent as facePirateIcon } from './icons/face_pirate_icon.svg';
import { ReactComponent as faceClosedEyesIcon } from './icons/face_closed_eyes_icon.svg';
import { ReactComponent as faceSwirlIcon } from './icons/face_swirl_eyes_icon.svg';
import { ReactComponent as starIcon } from './icons/star_icon.svg';
import { ReactComponent as signIcon } from './icons/sign_icon.svg';
import { ReactComponent as zkrollupIcon } from './icons/zkrollup_icon.svg';
import { ReactComponent as bidIcon } from './icons/bid_icon.svg';
import { ReactComponent as kolectivIcon } from './icons/kolectiv_icon.svg';
import { ReactComponent as newIcon } from './icons/new_icon.svg';
import { ReactComponent as auctionIcon } from './icons/auction_icon.svg';
import { ReactComponent as crownIcon } from './icons/crown_icon.svg';
import { ReactComponent as percentageIcon } from './icons/percentage_icon.svg';
import { ReactComponent as scoreIcon } from './icons/score_icon.svg';
import { ReactComponent as fireIcon } from './icons/fire_icon.svg';
import { ReactComponent as whaleIcon } from './icons/whale_icon.svg';
import { ReactComponent as artIcon } from './icons/art_icon.svg';
import { ReactComponent as spadeIcon } from './icons/spade_icon.svg';

export const SpadeIcon = (props: Props) => (<Base {...props} icon={spadeIcon} />);
export const ArtIcon = (props: Props) => (<Base {...props} icon={artIcon} />);
export const WhaleIcon = (props: Props) => (<Base {...props} icon={whaleIcon} />);
export const FireIcon = (props: Props) => (<Base {...props} icon={fireIcon} />);
export const PercentageIcon = (props: Props) => (<Base {...props} icon={percentageIcon} />);
export const ScoreIcon = (props: Props) => (<Base {...props} icon={scoreIcon} />);
export const CrownIcon = (props: Props) => (<Base {...props} icon={crownIcon} />);
export const AuctionIcon = (props: Props) => (
  <Base {...props} icon={auctionIcon} />
);
export const NewIcon = (props: Props) => (
  <Base {...props} icon={newIcon} />
);
export const BidIcon = (props: Props) => (
  <Base {...props} icon={bidIcon} />
);
export const KolectivIcon = (props: Props) => (
  <Base {...props} icon={kolectivIcon} />
);

export const ZKRollUpIcon = (props: Props) => (
  <Base {...props} icon={zkrollupIcon} />
);

export const SignIcon = (props: Props) => (
  <Base {...props} icon={signIcon} />
);
export const StarIcon = (props: Props) => (
  <Base {...props} icon={starIcon} />
);
export const FaceHappyIcon = (props: Props) => (
  <Base {...props} icon={faceHappyIcon} />
);
export const FaceToothIcon = (props: Props) => (
  <Base {...props} icon={faceToothIcon} />
);
export const FaceCoolIcon = (props: Props) => (
  <Base {...props} icon={faceCoolIcon} />
);
export const FacePirateIcon = (props: Props) => (
  <Base {...props} icon={facePirateIcon} />
);
export const FaceClosedEyesIcon = (props: Props) => (
  <Base {...props} icon={faceClosedEyesIcon} />
);
export const FaceSwirlIcon = (props: Props) => (
  <Base {...props} icon={faceSwirlIcon} />
);

export const ArrowRightIcon = (props: Props) => (
  <Base {...props} icon={arrowRightIcon} />
);
export const ArrowLeftIcon = (props: Props) => (
  <Base {...props} icon={arrowLeftIcon} />
);
export const MintIcon = (props: Props) => <Base {...props} icon={mintIcon} />;
export const GhostIcon = (props: Props) => <Base {...props} icon={ghostIcon} />;
export const LockIcon = (props: Props) => <Base {...props} icon={lockIcon} />;
export const ImmutableIcon = (props: Props) => (
  <Base {...props} icon={immutableIcon} />
);
export const ImmutableInIcon = (props: Props) => (
  <Base {...props} icon={immutableInIcon} />
);
export const ImmutableOutIcon = (props: Props) => (
  <Base {...props} icon={immutableOutIcon} />
);
export const SoundOnIcon = (props: Props) => (
  <Base {...props} icon={soundOnIcon} />
);
export const SoundOffIcon = (props: Props) => (
  <Base {...props} icon={soundOffIcon} />
);
export const BellIcon = (props: Props) => <Base {...props} icon={bellIcon} />;
export const CheckIcon = (props: Props) => <Base {...props} icon={checkIcon} />;
export const ChevronDownIcon = (props: Props) => (
  <Base {...props} icon={chevronDownIcon} />
);
export const ChevronRightIcon = (props: Props) => (
  <Base {...props} icon={chevronRightIcon} />
);
export const ChevronLeftIcon = (props: Props) => (
  <Base {...props} icon={chevronLeftIcon} />
);
export const CloseIcon = (props: Props) => <Base {...props} icon={closeIcon} />;
export const CollectionIcon = (props: Props) => (
  <Base {...props} icon={collectionIcon} />
);
export const PacksIcon = (props: Props) => <Base {...props} icon={packsIcon} />;
export const EthWalletIcon = (props: Props) => (
  <Base {...props} icon={ethWalletIcon} />
);
export const HomeIcon = (props: Props) => <Base {...props} icon={homeIcon} />;
export const ShareIcon = (props: Props) => <Base {...props} icon={shareIcon} />;
export const LeaderboardIcon = (props: Props) => (
  <Base {...props} icon={leaderboardIcon} />
);
export const LogoutIcon = (props: Props) => (
  <Base {...props} icon={logoutIcon} />
);
export const MarketIcon = (props: Props) => (
  <Base {...props} icon={marketIcon} />
);
export const CopyIcon = (props: Props) => <Base {...props} icon={copyIcon} />;
export const MenuIcon = (props: Props) => <Base {...props} icon={menuIcon} />;
export const PlusIcon = (props: Props) => <Base {...props} icon={plusIcon} />;
export const ShopIcon = (props: Props) => <Base {...props} icon={shopIcon} />;
export const TransactionsIcon = (props: Props) => (
  <Base {...props} icon={transactionsIcon} />
);
export const TrophyCheckIcon = (props: Props) => (
  <Base {...props} icon={trophyCheckIcon} />
);
export const UserIcon = (props: Props) => <Base {...props} icon={userIcon} />;
export const TimerIcon = (props: Props) => <Base {...props} icon={timerIcon} />;
export const EthIcon = (props: Props) => <Base {...props} icon={ethIcon} />;
export const EthInIcon = (props: Props) => <Base {...props} icon={ethInIcon} />;
export const EthOutIcon = (props: Props) => <Base {...props} icon={ethOutIcon} />;
export const EthIconPlain = (props: Props) => <Base {...props} icon={ethIconPlain} />;
export const EthIconPlus = (props: Props) => <Base {...props} icon={ethIconPlus} />;
export const EthVaultIcon = (props: Props) => <Base {...props} icon={ethVault} />;
export const TwitterIcon = (props: Props) => (
  <Base {...props} icon={twitterIcon} />
);
export const InstagramIcon = (props: Props) => (
  <Base {...props} icon={instagramIcon} />
);
export const MetaMask = (props: Props) => <Base {...props} icon={metaMask} />;
export const DiscordIcon = (props: Props) => (
  <Base {...props} icon={discordIcon} />
);

export type SVGIcon = React.FunctionComponent<Props>;
