import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import cx from 'classnames';

import useQueryParams from 'hooks/useQueryParams';
import useRequestState from 'hooks/useRequestState';
import useUploadAvatar from 'hooks/useUploadAvatar';
import usePrevious from 'hooks/usePrevious';
import { useCurrentUser } from 'api/user';

import Avatar from 'shared_components/Avatar';
import Button from 'shared_components/Button';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const EditProfileModal = () => {
  const history = useHistory();
  const { modal } = useQueryParams();

  const isOpen = modal === 'profile';
  const isOpenPrev = usePrevious(isOpen);
  const { data: user } = useCurrentUser(!isOpenPrev && isOpen);

  const state = useRequestState();

  const {
    avatar,
    uploading,
    handleSelect,
    handleUpload,
    handleReset,
  } = useUploadAvatar();

  useEffect(() => {
    if (isOpen && !isOpenPrev) {
      state.reset();
      handleReset();
    }
  }, [isOpen, isOpenPrev, state, handleReset]);

  const handleDismiss = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      if (avatar) await handleUpload();
      handleDismiss();
    },
    [avatar, handleUpload, handleDismiss]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.editProfileModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.editProfileModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          Edit Avatar
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <form onSubmit={handleSave} className={styles.content}>
        <label className={styles.uploadAvatar}>
          <input
            type="file"
            multiple={false}
            accept="image/png,image/jpg,image/jpeg"
            onChange={handleSelect}
          />
          <div
            className={cx(
              styles.avatarContainer,
              uploading && styles.avatarUploading
            )}
          >
            {avatar ? (
              <img alt="New avatar" src={avatar} />
            ) : (
              <Avatar size={160} src={user?.avatar} />
            )}
            {uploading && (
              <Spinner centered size={32} className={styles.spinner} />
            )}
          </div>
        </label>
        <Text centered size="xs" type="primary">
          Maximum upload file size: 1 MB
        </Text>

        <Button
          block
          rounded="full"
          htmlType="submit"
          loading={state.loading || uploading}
          className={styles.button}
        >
          SAVE
        </Button>
      </form>
    </ReactModal>
  );
};

export default EditProfileModal;
