const {
  REACT_APP_API_URL,
  REACT_APP_API_SOCKET_URL,
  REACT_APP_CDN_URL,
} = process.env;

/* --- Staging --- */
// const defaultApiURL = 'https://api-staging1.kolectiv.gg/api/v1';
// const defaultApiSocketURL = 'https://sockets-staging1.kolectiv.gg';
// const defaultCdnUrl = 'https://cdn.kolectiv.gg';
// export const IMX_API_URL = 'https://api.uat.x.immutable.com/v1';
// export const IMX_LINK_URL = 'https://link.uat.x.immutable.com';

/* --- Prod --- */
const defaultApiURL = 'https://api.kolectiv.gg/api/v1';
const defaultApiSocketURL = 'https://sockets.kolectiv.gg';
const defaultCdnUrl = 'https://cdn.kolectiv.gg';
export const IMX_API_URL = 'https://api.x.immutable.com/v1';
export const IMX_LINK_URL = 'https://link.x.immutable.com';

export const API_URL = REACT_APP_API_URL || defaultApiURL;
export const API_SOCKET_URL = REACT_APP_API_SOCKET_URL || defaultApiSocketURL;
export const CDN_URL = REACT_APP_CDN_URL || defaultCdnUrl;
export const EPICS_CDN_URL = 'https://cdn.epics.gg';
