import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';

type RequestState = {
  loading: boolean;
  error?: string;
  setError: (message: string) => void;
  setApiError: (e: AxiosError<ApiErrorResponse>) => void;
  reset: () => void;
  start: () => void;
  end: () => void;
};

const useRequestState = (): RequestState => {
  const [loading, setLoading] = useState(false);
  const [error, setErrorState] = useState<string>();

  const setError = useCallback((message: string) => {
    setErrorState(message);
  }, []);

  const setApiError = useCallback((e: AxiosError<ApiErrorResponse>) => {
    setErrorState(e.response?.data?.error);
  }, []);

  const reset = useCallback(() => {
    setLoading(false);
    setErrorState(undefined);
  }, []);

  const start = useCallback(() => {
    setLoading(true);
    setErrorState(undefined);
  }, []);

  const end = useCallback(() => {
    setLoading(false);
  }, []);

  return {
    loading,
    error,
    setError,
    setApiError,
    reset,
    start,
    end,
  };
};

export default useRequestState;
