import { FormEvent, useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import usePrevious from 'hooks/usePrevious';
import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import { listItemOnMarket } from 'api/market';

import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import ErrorAlert from 'shared_components/ErrorAlert';
import InputText from 'shared_components/InputText';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import { useSettings } from 'api/settings';
import useSettingsStore from 'hooks/useSettingsStore';
import { checkValidPrice } from 'utils/format';
import SvgCard from 'shared_components/SvgCard';
import { getImageCdnUrl } from 'utils/images';

type Props = {
  card?: Card;
  cardTemplate?: CardTemplate;
  isOpen: boolean;
  onDismiss: () => void;
  packTemplate?: PackTemplate;
  packs?: Pack[];
  reload: () => Promise<unknown>;
};

const ListOnMarketModal = (props: Props) => {
  const {
    card,
    cardTemplate,
    isOpen,
    onDismiss,
    packTemplate,
    packs,
    reload,
  } = props;

  const { addNotification } = useNotifications();
  const { market: marketSettings } = useSettingsStore();
  const { commission, maxListPrice, minListPrice, noCommissionBelow } =
    marketSettings ?? {};

  const state = useRequestState();
  const [price, setPrice] = useState('');
  const [amount, setAmount] = useState('1');

  const isOpenPrev = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen && !isOpenPrev) {
      state.reset();
      setPrice('');
      setAmount('1');
    }
  }, [isOpen, isOpenPrev, state, setPrice]);

  const handleListOnMarket = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        state.start();
        let subtitle = '';

        if (card) {
          await listItemOnMarket({
            id: card.id,
            price,
            type: card.type,
          });
          subtitle = 'You have successfully listed your card on the market.';
        }

        if (packs) {
          if (Number(amount) > packs.length) {
            state.setError(
              `Insufficient packs. You only have ${packs.length} ${
                packs.length > 1 ? 'packs' : 'pack'
              } available to list.`
            );
            state.end();
            return;
          }
          const requests = packs.slice(0, Number(amount)).map((pack) =>
            listItemOnMarket({
              id: pack.id,
              price,
              type: 'pack',
            })
          );
          await Promise.all(requests);
          subtitle = `You have successfully listed your ${
            Number(amount) > 1 ? 'packs' : 'pack'
          } on the market.`;
        }

        await reload();
        addNotification({
          type: 'primary',
          title: 'List on market success!',
          subtitle,
          timeout: 5000,
        });
        onDismiss();
      } catch (e) {
        state.setApiError(e);
        state.end();
      }
    },
    [state, card, packs, amount, price, reload, addNotification, onDismiss]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.listOnMarketModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.listOnMarketModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          List on Market
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={onDismiss}
          className={styles.closeIcon}
        />
      </div>

      <form onSubmit={handleListOnMarket} className={styles.content}>
        {/* Card or pack image here */}
        {card?.images.svg.size400 && cardTemplate?.uuid && (
          <div className={styles.cardImage}>
            <SvgCard
              url={card.images.svg.size400}
              width={240}
              className={styles.cardImage}
              cardTemplateUUID={cardTemplate.uuid}
            />
          </div>
        )}
        {packTemplate && (
          <div className={styles.packImage}>
            <img
              src={getImageCdnUrl(
                packTemplate.images.sort((a, b) =>
                  a.name === 'image' ? -1 : 1
                )[0].url,
                true
              )}
              alt={packTemplate.name}
              key={packTemplate.id}
            />
          </div>
        )}
        <ErrorAlert message={state.error} className={styles.errorAlert} />
        <label className={styles.formItem}>
          <Text
            block
            uppercase
            size="sm"
            type="muted"
            weight="semibold"
            className={styles.formLabel}
          >
            Listing Price {packs && '(Per Pack)'}
          </Text>
          <InputText
            required
            type="number"
            placeholder="Enter ETH amount"
            min={minListPrice ? +minListPrice : 0.0005}
            max={maxListPrice ? +maxListPrice : 2000}
            step={minListPrice ? +minListPrice : 0.001}
            value={price}
            onChange={(value) => {
              if (checkValidPrice(value)) {
                setPrice(value);
              }
            }}
            className={styles.input}
            autoFocus
          />
        </label>

        <div className={styles.bounds}>
          <Text size="sm" type="muted">
            Minimum price: {minListPrice ?? '–'}
          </Text>
          <Text size="sm" type="muted">
            Maximum price: {maxListPrice ?? '–'}
          </Text>
        </div>

        {packs && (
          <label className={styles.formItem}>
            <Text
              block
              uppercase
              size="sm"
              type="muted"
              weight="semibold"
              className={styles.formLabel}
            >
              Number of Packs
            </Text>
            <InputText
              required
              type="number"
              placeholder="Enter number of packs to list"
              min={1}
              step={1}
              value={amount}
              onChange={setAmount}
              className={styles.input}
            />
          </label>
        )}

        <div className={styles.infoRow}>
          <div className={styles.infoColumn}>
            <Text
              block
              uppercase
              size="sm"
              type="muted"
              weight="semibold"
              className={styles.infoLabel}
            >
              How much you receive
            </Text>
            <CurrencyValue
              showUSD
              size={20}
              value={
                commission
                  ? (
                      Number(price) *
                      Number(amount) *
                      (1 - percent(commission))
                    ).toFixed(8)
                  : '...'
              }
              className={styles.infoValue}
            />
          </div>
          <div className={styles.infoColumn}>
            <Text
              block
              uppercase
              size="sm"
              type="muted"
              weight="semibold"
              className={styles.infoLabel}
            >
              Kolectiv fee ({commission}%*)
            </Text>
            <CurrencyValue
              size={20}
              value={
                commission
                  ? (
                      Number(price) *
                      Number(amount) *
                      percent(commission)
                    ).toFixed(8)
                  : '...'
              }
              className={styles.infoValue}
            />
          </div>
        </div>

        {/* <div className={styles.bounds}>
          <Text size="xs" type="muted" style={{ fontStyle: 'italic' }}>
            * No commission is collected below {noCommissionBelow ?? '–'}
          </Text>
        </div> */}

        <Button
          block
          rounded="full"
          htmlType="submit"
          loading={state.loading}
          className={styles.button}
        >
          SUBMIT
        </Button>
      </form>
    </ReactModal>
  );
};

const percent = (n: number) => 0.01 * n;

export default ListOnMarketModal;
