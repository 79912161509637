/* eslint-disable @typescript-eslint/ban-ts-comment */

import { analytics } from './firebase';

declare global {
  interface Window {
    gtag: any;
  }
}

export const trackEvent = (action: string, value?: string, eth?: number) => {
  if (window && window.gtag) {
    if (eth) {
      window.gtag(
        'event',
        action,
        value
          ? {
              event_label: value,
              value: eth,
            }
          : null
      );
    } else {
      window.gtag(
        'event',
        action,
        value
          ? {
              event_label: value,
            }
          : null
      );
    }
  }
};

// @ts-ignore
export function gtagLogEvent(...args) {
  // @ts-ignore
  analytics.logEvent(...args);
}
