import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@szhsin/react-menu';

import useBalanceChangeSocket from 'hooks/useBalanceChangeSocket';
import { useCurrentUserFunds } from 'api/user';
import { stringifyUrlWithParam } from 'utils/routes';

import CurrencyValue from 'shared_components/CurrencyValue';
import {
  TransactionsIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const UserBalance = (props: Props) => {
  const { className } = props;
  const { data: funds, mutate } = useCurrentUserFunds();
  const history = useHistory();

  useBalanceChangeSocket((balanceChangeData) => {
    mutate((response) => {
      if (response) response.data.weth = balanceChangeData.weth;
      return response;
    });
  });

  return (
    <Menu
      menuButton={
        <div role="button" className={cx(styles.userBalance, className)}>
          <CurrencyValue
            value={funds?.weth}
            //showUSD
            size={18}
            className={styles.balance}
          />
          <ChevronDownIcon size={10} />
        </div>
      }
      className={styles.menu}
      position="auto"
      offsetY={2}
    >
      <MenuItem
        onClick={() =>
          history.push(
            stringifyUrlWithParam(history.location, { modal: 'deposit' })
          )
        }
      >
        <div className={styles.positiveDirection}>
          <ArrowRightIcon />
        </div>{' '}
        Deposit
      </MenuItem>
      <MenuItem
        onClick={() =>
          history.push(
            stringifyUrlWithParam(history.location, { modal: 'withdraw' })
          )
        }
      >
        <div className={styles.negativeDirection}>
          <ArrowRightIcon />
        </div>{' '}
        Withdraw
      </MenuItem>
      <MenuItem
        onClick={() =>
          history.push(
            stringifyUrlWithParam(history.location, { drawer: 'history' })
          )
        }
      >
        <TransactionsIcon /> History
      </MenuItem>
    </Menu>
  );
};

export default UserBalance;
