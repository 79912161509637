import axios from 'axios';
import { nanoid } from 'nanoid';

import useAuth from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import { deleteToken } from 'utils/auth';
import config from './config';

export const logout = async () => {
  deleteToken();
  useAuth.setState({ isAuth: false });
  useNotifications.setState({
    notifications: [
      {
        id: nanoid(),
        type: 'primary',
        subtitle: 'You have been logged out',
        timeout: 5000,
      },
    ],
  });
};

const request = axios.create(config);

/**
 * Logout response interceptor
 */
request.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (originalRequest.url === REFRESH_TOKEN_ENDPOINT) {
      await logout();
      return;
    }

    return Promise.reject(error);
  }
);

export const REFRESH_TOKEN_ENDPOINT = `/auth/refresh-jwt`;

export const refreshToken = (token: Token) => {
  return request.post<{
    jwt: string;
    expires: number;
  }>(REFRESH_TOKEN_ENDPOINT, {
    refreshToken: token.refreshToken,
    device: 'web',
  });
};
