import { format, formatDistance } from 'date-fns';
import { BigNumber } from 'bignumber.js';

export const formatNumber = (num: number) => {
  const intlNumber = Intl.NumberFormat();
  return intlNumber.format(num);
};

const ONE_MILLION = 1000000;
const ONE_THOUSAND = 1000;

export const formatLargeNumber = (num: number) => {
  if (num > ONE_MILLION) {
    const numMillions = num / ONE_MILLION;
    const numThousands = num % ONE_MILLION;
    const decimal = numThousands
      ? `.${Math.floor(Math.round(numThousands / ONE_MILLION))}`
      : '';
    return `${Math.floor(numMillions)}${decimal}M`;
  }
  if (num > ONE_THOUSAND) {
    const numMillions = num / ONE_THOUSAND;
    const numThousands = num % ONE_THOUSAND;
    const decimal = numThousands
      ? `.${Math.floor(Math.round(numThousands / ONE_THOUSAND))}`
      : '';
    return `${Math.floor(numMillions)}${decimal}K`;
  }
  return num.toString();
};

export const formatAmount = (value: string) => new BigNumber(value).toString();

export const formatDate = (date: string) =>
  format(new Date(date), 'dd/MM/yyyy, HH:mm:ss');

export const formatDateDistance = (date: string) =>
  formatDistance(new Date(date), new Date(), {
    includeSeconds: true,
    addSuffix: true,
  });

export const formatStringPossessive = (str: string) => {
  if (str.toLowerCase().charAt(str.length - 1) === 's') return `${str}’`;
  return `${str}’s`;
};

export const checkValidPrice = (value: string) => {
  if (value.length >= 2 && Number(value) <= 20000) {
    if (value[0] === '0') {
      if (value.length <= 6) {
        return true;
      }
    } else if (
      value.indexOf('.') &&
      value.substring(value.lastIndexOf('.')).length <= 5
    ) {
      return true;
    }
  } else if (value.length < 2) {
    return true;
  }

  return false;
};

export const countDecimals = (value: any) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};
