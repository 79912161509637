import { omit } from 'lodash';
import { parse, stringify, stringifyUrl } from 'query-string';

export const buildPathQuery = (
  path: string,
  params?: { [key: string]: string | string[] | number | null | undefined }
) => {
  if (!params) return path;
  const str = stringify(params);
  return path + '?' + str;
};

export const buildPathParams = (
  path: string,
  params: { [key: string]: string | number | undefined }
) => {
  let str = path;
  Object.entries(params).forEach(([param, value]) => {
    if (value) str = str.replace(`:${param}`, String(value));
  });
  return str;
};

export const omitQueryParam = (queryString: string, param: string | string[]) =>
  stringify(omit(parse(queryString), param));

export const stringifyUrlOmitParam = (
  location: Location | History['state'],
  params: string | string[]
) =>
  stringifyUrl({
    url: location.pathname,
    query: parse(omitQueryParam(location.search, params)),
  });

export const addQueryParam = (
  queryString: string,
  query: Record<string, string | number | undefined>
) =>
  stringify(
    {
      ...parse(queryString),
      ...query,
    },
    { skipNull: true, skipEmptyString: true }
  );

export const stringifyUrlWithParam = (
  location: Location | History['state'],
  query: Record<string, string | number | undefined>
) =>
  stringifyUrl({
    url: location.pathname,
    query: parse(addQueryParam(location.search, query)),
  });
