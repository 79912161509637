import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cx from 'classnames';

import Spinner from 'shared_components/Spinner';

import styles from './styles.module.scss';

export type ButtonProps = {
  block?: boolean;
  immutable?: boolean;
  eth?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  htmlType?: 'submit' | 'button';
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  rounded?: 'full';
  size?: 'lg' | 'sm';
  to?: LinkProps['to'];
  type?: 'primary' | 'secondary' | 'muted' | 'eth' | 'edit' | 'negative';
  uppercase?: boolean;
};

const Button = (props: ButtonProps) => {
  const {
    block,
    immutable,
    eth,
    children,
    className,
    disabled,
    htmlType,
    loading,
    onClick,
    rounded,
    size,
    to,
    type = 'primary',
    uppercase,
  } = props;
  const isDisabled = disabled || loading;
  const cn = cx(
    styles.button,
    {
      [styles.immutableButton]: immutable,
      [styles.ethButton]: eth,
      [styles.block]: block,
      [styles.uppercase]: uppercase ?? size === 'sm',

      [styles.sm]: size === 'sm',
      [styles.lg]: size === 'lg',

      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.muted]: type === 'muted',
      [styles.eth]: type === 'eth',
      [styles.edit]: type === 'edit',
      [styles.negative]: type === 'negative',

      [styles.disabled]: isDisabled,
      [styles.loading]: loading,

      [styles.roundedFull]: rounded === 'full' || size === 'sm',
    },
    className
  );
  const content = loading ? (
    <Spinner size={size === 'sm' ? 20 : 24} type="white" />
  ) : (
    children
  );
  return to && !disabled ? (
    <Link to={to} className={cn}>
      <span className={styles.buttonContent}>{content}</span>
    </Link>
  ) : (
    <button
      disabled={isDisabled}
      onClick={onClick}
      type={htmlType}
      className={cn}
    >
      <span className={styles.buttonContent}>{content}</span>
    </button>
  );
};

export default Button;
