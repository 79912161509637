import { useEffect, useCallback } from 'react';
import socket from 'api/socket';

const BALANCE_CHANGE_EVENT = 'balance-change';

type BalanceChangeEventData = {
  change: string;
  weth: string;
};

type BalanceChangeEventResponse = {
  event: typeof BALANCE_CHANGE_EVENT;
  change: string;
  weth: string;
};

const useBalanceChangeSocket = (
  cb?: (data: BalanceChangeEventData) => void
) => {
  const handler = useCallback(
    (e: BalanceChangeEventResponse) => {
      cb?.({ change: e.change, weth: e.weth });
    },
    [cb]
  );

  useEffect(() => {
    socket.on(BALANCE_CHANGE_EVENT, handler);
    return () => {
      socket.off(BALANCE_CHANGE_EVENT, handler);
    };
  }, [handler]);
};

export default useBalanceChangeSocket;
