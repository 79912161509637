import { useEffect, useState, useCallback } from 'react';
import socket from 'api/socket';

const PACK_INVENTORY_UPDATE_EVENT = 'pack-inventory-update';

type EventPackTemplateData = {
  id: number;
  inventoryCount: number;
  cost: string;
};

type PackInventoryUpdateEventResponse = {
  type: 'publicFeed';
  event: typeof PACK_INVENTORY_UPDATE_EVENT;
  packTemplate: EventPackTemplateData;
  id?: number;
};

const usePackInventorySocket = (cb?: (data: EventPackTemplateData) => void) => {
  const [packTemplate, setPackTemplate] = useState<EventPackTemplateData>();

  const handler = useCallback(
    (e: PackInventoryUpdateEventResponse) => {
      setPackTemplate(e.packTemplate);
      cb?.(e.packTemplate);
    },
    [cb]
  );

  useEffect(() => {
    socket.on(PACK_INVENTORY_UPDATE_EVENT, handler);
    return () => {
      socket.off(PACK_INVENTORY_UPDATE_EVENT, handler);
    };
  }, [handler]);

  return { packTemplate };
};

export default usePackInventorySocket;
