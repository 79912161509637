import { useEffect } from 'react';
import { flatten } from 'lodash';

import usePrevious from 'hooks/usePrevious';
import usePackTemplates from 'hooks/usePackTemplates';
import { PackTemplate } from 'constants/packTemplate';
import { usePacks } from 'api/packs';

const useAppPacks = () => {
  const { setTemplates, getTemplate } = usePackTemplates();

  const { data: packTemplates, mutate } = usePacks(undefined, {
    onSuccess: ([{ data }]) => {
      setTemplates(data);
    },
  });

  const prevPackTemplates = usePrevious(packTemplates);
  useEffect(() => {
    if (!prevPackTemplates && packTemplates) {
      setTemplates(flatten(packTemplates));
    }
  }, [prevPackTemplates, packTemplates, setTemplates]);

  const pack =
    packTemplates &&
    flatten(packTemplates).find((p) => p.id === PackTemplate.crypto2021);

  return { pack, packTemplates, getTemplate, mutate };
};

export default useAppPacks;
