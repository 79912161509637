import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import Web3 from 'web3';

import useOrderState from 'hooks/useOrderState';
import useAlertSocket from 'hooks/useAlertSocket';
import usePrevious from 'hooks/usePrevious';
import { stringifyUrlWithParam } from 'utils/routes';
import storage from 'utils/storage';

import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon, CheckIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

import abi from 'constants/exchangeAbi';
import useSettingsStore from 'hooks/useSettingsStore';
import { useSettings } from 'api/settings';
import { ContractAddress } from 'constants/contract';
import { getEtherscanTransactionLink } from 'utils/etherscan';
import { differenceInMinutes } from 'date-fns';
import { mutate } from 'swr';

type ExchangeRequestData = {
  eth: string;
  exchangeId: string;
  transactionId: number;
  ethTransaction: string;
  exchangeTimestamp: string;
};

const clearOrderLocalData = () => {
  storage.removeItem('exchangeType');
  storage.removeItem('exchangeId');
  storage.removeItem('exchangeTxHash');
};

const ExchangeProcessingBar = () => {
  const history = useHistory();

  const {
    exchangeId,
    exchangeTimestamp,
    exchangeType,
    transactionHash,
    clearExchangeId,
    clearExchangeType,
    clearExchangeTimestamp,
    clearTransactionHash,
  } = useOrderState();

  const prevOrderId = usePrevious(exchangeId);
  const [currentStatus, setCurrentStatus] = useState<string>();
  const [hidden, setHidden] = useState(Boolean(!exchangeId));
  const [succeeded, setSucceeded] = useState(false);

  const skipInitialFetch = Boolean(!exchangeId || hidden);

  const persistedSettings = (useSettingsStore() || undefined) as
    | Settings
    | undefined;
  const { data } = useSettings(skipInitialFetch, undefined, {
    initialData: persistedSettings,
    revalidateOnFocus: !skipInitialFetch,
    revalidateOnReconnect: !skipInitialFetch,
  });
  const contractAddress = data?.eth?.exchangeContract
    ? data.eth.exchangeContract
    : ContractAddress.address;

  const poll = useRef<NodeJS.Timeout | null>();

  if (
    exchangeTimestamp &&
    differenceInMinutes(new Date(), Date.parse(exchangeTimestamp)) >= 5
  ) {
    clearExchangeId();
    clearExchangeType();
    clearExchangeTimestamp();
    clearTransactionHash();
  }

  const clearPoll = useCallback(() => {
    if (poll.current) clearInterval(poll.current);
    poll.current = null;
  }, []);

  const clearData = useCallback(() => {
    setTimeout(() => {
      setHidden(true);
      setCurrentStatus(undefined);
      clearExchangeId();
      clearTransactionHash();
      clearOrderLocalData();
    }, 10000);
  }, [clearExchangeId, clearTransactionHash]);

  const handleSucceed = useCallback(() => {
    if (!succeeded) {
      setSucceeded(true);
      setCurrentStatus('success');

      // This is the success modal, if you want to trigger a custom modal/pass custom params.
      history.push(
        stringifyUrlWithParam(history.location, {
          modal: 'exchangesuccess',
          exchangeId,
          tx: transactionHash,
          exchangeType: exchangeType,
        })
      );

      mutate(`/user/funds`);
      mutate(`/alerts/count`);
      clearData();
      clearPoll();
    }
  }, [
    clearData,
    clearPoll,
    succeeded,
    history,
    exchangeId,
    transactionHash,
    exchangeType,
  ]);

  useEffect(() => {
    if (
      (!prevOrderId && exchangeId) ||
      (prevOrderId && prevOrderId !== exchangeId)
    )
      setSucceeded(false);
  }, [prevOrderId, exchangeId]);

  useEffect(() => {
    if (exchangeId && contractAddress) {
      setHidden(false);
      const fn = async () => {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);

        if (exchangeType === 'withdraw') {
          const response = await contract.methods
            .getWithdrawBalance(Number(exchangeId))
            .call();
          if (response === '0') {
            setHidden(false);
            setCurrentStatus('processing');
          } else handleSucceed();
        } else if (exchangeType === 'deposit') {
          const response = await contract.methods
            .getDepositBalance(Number(exchangeId))
            .call();
          if (response === '0') {
            setHidden(false);
            setCurrentStatus('processing');
          } else handleSucceed();
        }
      };
      fn();
      if (!poll.current) poll.current = setInterval(fn, 10000);
      return () => {
        clearPoll();
      };
    }
  }, [exchangeId, exchangeType, contractAddress, handleSucceed, clearPoll]);

  useAlertSocket<ExchangeRequestData>('eth-deposit-complete', (data) => {
    if (
      exchangeType === 'deposit' &&
      Number(exchangeId) === Number(data?.exchangeId)
    )
      handleSucceed();
  });

  useAlertSocket<ExchangeRequestData>('eth-withdraw-complete', (data) => {
    if (
      exchangeType === 'withdraw' &&
      Number(exchangeId) === Number(data?.exchangeId)
    )
      handleSucceed();
  });

  if (!exchangeId || hidden) return null;

  if (currentStatus === 'failure') {
    return (
      <div className={cx(styles.bar, styles.failure)}>
        <CloseIcon size={40} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR {exchangeType === 'withdraw' ? 'WITHDRAWAL' : 'DEPOSIT'} FAILED,
          PLEASE TRY AGAIN
        </Text>
      </div>
    );
  }

  if (currentStatus === 'success') {
    return (
      <div className={cx(styles.bar, styles.success)}>
        <CheckIcon size={24} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR {exchangeType === 'withdraw' ? 'WITHDRAWAL' : 'DEPOSIT'} WAS
          SUCCESSFUL
        </Text>
      </div>
    );
  }

  if (transactionHash != null) {
    return (
      <div className={cx(styles.bar, styles.progress)}>
        <Spinner size={34} type="white" />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR {exchangeType === 'withdraw' ? 'WITHDRAWAL' : 'DEPOSIT'} IS IN
          PROGRESS
        </Text>
        <a
          href={getEtherscanTransactionLink(transactionHash)}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <Text type="white" size="sm" as="p">
            View on Etherscan
          </Text>
        </a>
      </div>
    );
  }

  return (
    <div className={cx(styles.bar, styles.progress)}>
      <Spinner size={40} type="white" />
      <Text className={cx(styles.barText, styles.light)}>
        YOUR {exchangeType === 'withdraw' ? 'WITHDRAWAL' : 'DEPOSIT'} IS IN
        PROGRESS
      </Text>
    </div>
  );
};

export default ExchangeProcessingBar;
