import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import cx from 'classnames';

import useAuth from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import { stringifyUrlWithParam } from 'utils/routes';
import { Routes } from 'constants/routes';

import {
  MenuIcon,
  LogoutIcon,
  TransactionsIcon,
  UserIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

const MenuButton = (props: Props) => {
  const { className } = props;
  const { profile, transactions, logout } = useNavMenuItems();

  return (
    <Menu
      menuButton={
        <div role="button" className={cx(styles.menuButton, className)}>
          <MenuIcon size={20} />
        </div>
      }
      className={styles.menu}
      position="auto"
      offsetY={0}
    >
      <MenuItem {...profile.props}>{profile.render}</MenuItem>
      <MenuItem {...transactions.props}>{transactions.render}</MenuItem>
      <MenuDivider />
      <MenuItem {...logout.props}>{logout.render}</MenuItem>
    </Menu>
  );
};

export const useNavMenuItems = () => {
  const { logout } = useAuth();
  const { addNotification } = useNotifications();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    logout();
    history.replace(Routes.landing);
    addNotification({
      type: 'primary',
      title: 'Logout success',
      subtitle: 'You have been logged out successfully.',
      timeout: 5000,
    });
  }, [logout, history, addNotification]);

  const items = {
    profile: {
      props: {
        onClick: () =>
          history.push(
            stringifyUrlWithParam(history.location, { modal: 'profile' })
          ),
      },
      render: (
        <>
          <UserIcon /> Update Avatar
        </>
      ),
    },
    transactions: {
      props: {
        onClick: () =>
          history.push(
            stringifyUrlWithParam(history.location, { drawer: 'transactions' })
          ),
      },
      render: (
        <>
          <TransactionsIcon /> My Transactions
        </>
      ),
    },
    logout: {
      props: {
        onClick: handleLogout,
      },
      render: (
        <>
          <LogoutIcon /> Logout
        </>
      ),
    },
  };

  return items;
};

export default MenuButton;
