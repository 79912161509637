export enum Collection {
  core = 1,
  special = 35,
  founders = 36,
}

type CollectionBySeason = {
  core: number;
  special: number;
  founders?: number;
};

export const FOUNDERS_COLLECTION_ID = 36;

export const CollectionsBySeason: Record<string, CollectionBySeason> = {
  'Crypto 2021': {
    core: 1,
    special: 35,
    founders: 36,
  },
  'Nightmares': {
    core: 67,
    special: 68,
  },
  'Visions of Tomorrow': {
    core: 100,
    special: 101,
    founders: 102,
  },
  'Urban Artists': {
    core: 103,
    special: 104,
    founders: 105,
  },
  'Events': {
    core: 133,
    special: 134,
  },
};
