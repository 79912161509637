import { Suspense } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cx from 'classnames';

import useAuth from 'hooks/useAuth';
import { Routes } from 'constants/routes';

import AlertsDrawer from '../shared_drawers/AlertsDrawer';
import AppHeader from '../AppHeader';
import BuyPackModal from '../shared_modals/BuyPackModal';
import DepositModal from '../shared_modals/DepositModal';
import WithdrawModal from '../shared_modals/WithdrawModal';
import EditProfileModal from '../shared_modals/EditProfileModal';
import LoginModal from '../shared_modals/LoginModal';
import PackSuccessModal from '../shared_modals/PackSuccessModal';
import ExchangeSuccessModal from '../shared_modals/ExchangeSuccessModal';
import AuctionWonModal from '../shared_modals/AuctionWonModal';
import MenuDrawer from '../shared_drawers/MenuDrawer';
import OffersDrawer from '../shared_drawers/OffersDrawer';
import PacksDrawer from '../shared_drawers/PacksDrawer';
import TransactionsDrawer from '../shared_drawers/TransactionsDrawer';
import HistoryDrawer from '../shared_drawers/HistoryDrawer';
import PackProcessingBar from '../PackProcessingBar';
import ExchangeProcessingBar from '../ExchangeProcessingBar';
import DepositProcessingBar from '../L1DepositProcessingBar';
import AssetVaultModal from '../shared_modals/AssetVaultModal';
import Text from 'shared_components/Text';

import Spinner from 'shared_components/Spinner';

import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
};

const AppLayout = (props: Props) => {
  const { children } = props;
  const { isAuth } = useAuth();
  const match = useRouteMatch();
  const isLanding = match.isExact && match.path === Routes.landing;
  return (
    <>
      <div className={styles.layout}>
        <AppHeader className={styles.header} />
        <main className={cx(styles.main, isLanding && styles.landingMain)}>
          <Suspense fallback={<Spinner centered />}>{children}</Suspense>
        </main>
        <footer className={styles.footer}>
          <div className={styles.inner}>
            <Text size="xs" type="muted">
              Copyright 2022 Epics Digital Collectibles Inc.
              <a
                href="https://kolectiv.zendesk.com/hc/en-gb"
                target="_blank"
                rel="noreferrer"
              >
                Help Centre
              </a>
              |
              <a
                href="https://docs.google.com/document/d/e/2PACX-1vTs4KnD_arrpgc54S61gSX6ElLUTzByQMSDwpR6gVJ-k4P-UbFYqMGOfv9EPP86kg/pub"
                target="_blank"
                rel="noreferrer"
              >
                Terms&nbsp;&amp;&nbsp;Conditions
              </a>
              |
              <a
                href="https://docs.google.com/document/d/e/2PACX-1vQ-dCmBH6J1uBlRQhlOQEvgjjU3lGRd6tb_K-hPZAi3BvtVTEAosnI-MSm9vHL4kQ/pub"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Text>

            <Text size="xs" type="muted">
              It&apos;s Cool To Connect!
            </Text>
          </div>
        </footer>

        <LoginModal />
        <DepositProcessingBar />
        <AssetVaultModal />
        <PackProcessingBar />
        <PackSuccessModal />
        <ExchangeProcessingBar />
        <ExchangeSuccessModal />
        <AuctionWonModal />

        {isAuth ? (
          <>
            <MenuDrawer />
            <AlertsDrawer />
            <PacksDrawer />
            <OffersDrawer />
            <BuyPackModal />
            <DepositModal />
            <WithdrawModal />
            <EditProfileModal />
            <TransactionsDrawer />
            <HistoryDrawer />
          </>
        ) : null}
      </div>
    </>
  );
};

export default AppLayout;
