import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BigNumber } from 'bignumber.js';
import ReactModal from 'react-modal';
import cx from 'classnames';
import Web3 from 'web3';
import { trackEvent } from 'utils/analytics';

import useQueryParams from 'hooks/useQueryParams';
import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import useSettingsStore from 'hooks/useSettingsStore';
import useOrderState from 'hooks/useOrderState';
import { useCurrentUserFunds, withdraw } from 'api/user';
import { useSettings } from 'api/settings';
import { addressStartAndEnd } from 'utils/etherscan';
import storage from 'utils/storage';

import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import InputText from 'shared_components/InputText';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import appLogo from 'assets/logos/full_logo.svg';
import styles from './styles.module.scss';

const WithdrawModal = () => {
  const history = useHistory();
  const { addNotification } = useNotifications();

  const { modal } = useQueryParams();
  const isOpen = modal === 'withdraw';

  const persistedSettings = (useSettingsStore() || undefined) as
    | Settings
    | undefined;
  const { data } = useSettings(!isOpen, undefined, {
    initialData: persistedSettings,
    revalidateOnFocus: isOpen,
    revalidateOnReconnect: isOpen,
  });

  const currentFunds = useCurrentUserFunds().data;
  const withdrawalFee = data?.eth?.exchangeWithdrawFee
    ? parseFloat(data?.eth?.exchangeWithdrawFee)
    : 0;

  const state = useRequestState();
  const { setExchangeId, setExchangeType } = useOrderState();
  const [currentAmount, setCurrentAmount] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');
  const [currentWalletAddress, setCurrentWalletAddress] = useState('');
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const coinbase = await web3.eth.getCoinbase();
          setCurrentWalletAddress(coinbase);

          await web3.eth.getBalance(coinbase).then(async (balance) => {
            const currentBalance = new BigNumber(balance);
            const costWei = web3.utils.fromWei(
              currentBalance.toString(),
              'ether'
            );
            setCurrentWalletBalance(Number(costWei));

            await web3.eth.net.getNetworkType().then(async (data) => {
              setCurrentNetwork(data);
            });
          });
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [isOpen]);

  const handleDismiss = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const handleAddMax = useCallback(() => {
    setCurrentAmount(String(currentFunds?.weth));
  }, [currentFunds]);

  const handleWithdraw = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setErrors([]);

      try {
        state.start();
        const depositAmount = parseFloat(currentAmount);

        if (depositAmount !== 0) {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const coinbase = await web3.eth.getCoinbase();

          await web3.eth.getBalance(coinbase).then(async (balance) => {
            const { data } = await withdraw({
              eth: currentAmount,
            });

            trackEvent('Withdrawal', currentAmount);

            setExchangeType('withdraw');
            setExchangeId(String(data?.exchangeId));
            storage.setItem('exchangeId', data?.exchangeId.toString());
            storage.setItem('exchangeType', 'withdraw');
            handleDismiss();
          });

          state.end();
        }
      } catch (e) {
        setErrors(e);
        state.end();
        addNotification({
          type: 'secondary',
          title: `Failed to detect wallet`,
          subtitle: e.message,
        });
      }
    },
    [
      handleDismiss,
      addNotification,
      setExchangeId,
      setExchangeType,
      currentAmount,
      state,
    ]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.editProfileModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.editProfileModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          Withdraw ETH from Kolectiv
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <form onSubmit={handleWithdraw} className={styles.content}>
        <div className={styles.balanceContainer}>
          <div className={styles.balance}>
            <div className={styles.kolectivBalance}>
              <div className={styles.networkInfo}>
                <img alt="Kolectiv" src={appLogo} className={styles.appLogo} />
              </div>
            </div>
            <CurrencyValue value={currentFunds?.weth} size={30} />
          </div>
          <div className={styles.wallet}>
            <div className={styles.walletBalance}>
              <div className={styles.networkInfo}>
                <Text type="muted" size="sm" className={styles.walletAddress}>
                  {addressStartAndEnd(currentWalletAddress)}
                </Text>
                <Text type="white" size="sm" weight="bold">
                  {currentNetwork.toUpperCase()} ETHEREUM NETWORK
                </Text>
              </div>
            </div>
            <CurrencyValue
              value={String(currentWalletBalance.toFixed(6))}
              size={30}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <InputText
            required
            type="number"
            placeholder="Enter ETH Amount"
            min={0}
            step={0.0001}
            value={currentAmount}
            onChange={setCurrentAmount}
            className={styles.formInput}
          />
          <Button
            className={styles.maxButton}
            onClick={handleAddMax}
            htmlType="button"
            type="muted"
          >
            <Text block centered size="xs" type="white">
              SELECT MAX
            </Text>
          </Button>
        </div>

        {Boolean(errors.length) && (
          <div className={styles.errors}>
            {errors.map((error) => (
              <Text key={error} block centered size="xs" type="secondary">
                {error}
              </Text>
            ))}
          </div>
        )}
        <div className={styles.blocks}>
          <div className={styles.totalBlock}>
            <Text block size="xs" type="muted" className={styles.totalLabel}>
              After gas fees, you will receive
            </Text>
            <CurrencyValue
              size={24}
              value={
                currentAmount !== '' &&
                Number(currentAmount) > Number(withdrawalFee) * 2
                  ? Number(parseFloat(currentAmount) - withdrawalFee).toFixed(
                      10
                    )
                  : '0'
              }
              className={cx(styles.packCost, styles.packCost__total)}
            />
          </div>

          <div className={styles.buttonBlock}>
            <Button
              htmlType="submit"
              loading={state.loading}
              className={styles.button}
              rounded="full"
              disabled={
                Number(withdrawalFee) * 2 > Number(currentAmount) ||
                Number(currentAmount) > Number(currentFunds?.weth)
              }
            >
              Withdraw
            </Button>
          </div>
        </div>
      </form>
    </ReactModal>
  );
};

export default WithdrawModal;
