import create from 'zustand';
import { nanoid } from 'nanoid';

type Notification = {
  id?: string;
  type?: 'primary' | 'secondary' | 'processing';
  title?: string;
  subtitle?: string;
  timeout?: number;
  onClick?: () => void;
};

type NotificationsState = {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  removeNotification: (id: string) => void;
};

const useNotifications = create<NotificationsState>((set) => ({
  notifications: [],
  addNotification: (notification) => {
    notification.id = notification.id || nanoid();
    set((state) => ({ notifications: [...state.notifications, notification] }));
  },
  removeNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter(
        (notification) => notification.id !== id
      ),
    }));
  },
}));

export default useNotifications;
