import request, { JWT_HEADER_KEY } from './request';

export const getNonce = (data: { ethAddress: string }) =>
  request.post<{ nonce: string }>(`/auth/nonce`, {
    ethAddress: data.ethAddress,
  });

export const register = (data: { ethAddress: string; signature: string }) =>
  request.post<AuthData>(`/auth/register`, {
    ethAddress: data.ethAddress,
    signature: data.signature,
  });

export const registerFinish = (
  data: { username: string; email: string },
  jwt: string
) =>
  request.post<AuthData>(
    `/auth/register-finish`,
    {
      username: data.username,
      email: data.email,
    },
    { headers: { [JWT_HEADER_KEY]: jwt } }
  );

export const login = (data: { email: string; password: string }, jwt: string) =>
  request.post<AuthData>(
    `/auth/login`,
    {
      email: data.email,
      password: data.password,
    },
    { headers: { [JWT_HEADER_KEY]: jwt } }
  );

export const updateUser = (data: { username: string; email: string }) =>
  request.patch<AuthData>(`/user/info`, {
    username: data.username,
    email: data.email,
  });

export const refreshJWT = (data: { refreshToken: string; device: string }) =>
  request.post<AuthData>(`/auth/refresh-jwt`, {
    refreshToken: data.refreshToken,
    device: data.device,
  });

export const checkEmail = (data: { email: string }) =>
  request.get<{
    success: boolean;
  }>(`/auth/check-email/${data.email}`);

export const checkUsername = (data: { username: string }) =>
  request.get<{
    success: boolean;
  }>(`/auth/check-username/${data.username}`);

export const updatePassword = (data: {
  oldPassword: string;
  newPassword: string;
}) => request.patch<{ success: boolean }>(`/user/password`, data);
