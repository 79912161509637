import cx from 'classnames';

import { formatAmount, formatNumber } from 'utils/format';
import { useEthUSDPrice } from 'hooks/useSettingsStore';

import CurrencyIcon from 'shared_components/CurrencyIcon';
import Text from 'shared_components/Text';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  usdClassName?: string;
  size?: number;
  value?: string | number;
  showUSD?: boolean;
  inline?: boolean;
  column?: boolean;
  alignLeft?: boolean;
};

const CurrencyValue = (props: Props) => {
  const {
    className,
    size = 16,
    value = '0',
    showUSD = false,
    inline = false,
    column = false,
    alignLeft = false,
    usdClassName,
  } = props;
  const cn = cx(styles.currencyValue, className, inline && styles.isInline);
  const USD = useEthUSDPrice();

  return (
    <div className={cn}>


      <div className={cx(styles.currencyText, column && styles.isColumn, alignLeft && styles.isLeft)}>
        <Text
          type="white"
          weight="extrabold"
          className={styles.value}
          style={{ fontSize: size }}
        >
          {/* WETH */}
          <CurrencyIcon size={size} width={size / 1.5} className={styles.icon} />
          {formatAmount(typeof value === 'string' ? Number(value).toFixed(4) : value.toFixed(4).toString())}
        </Text>

        {/* USD conversion */}
        {value && showUSD && (
          <Text
            type="muted"
            weight="medium"
            style={{ fontSize: size / 1.5 }}
            className={cx(usdClassName, styles.usdClass)}
          >
            $
            {USD &&
              formatNumber(Number(value) * USD).replace(/\.\d+/g, (match) =>
                match.slice(0, 3)
              )}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CurrencyValue;
