import create from 'zustand';
import storage from 'utils/storage';

type OrderState = {
  orderId?: string;
  depositId?: string;
  exchangeId?: string;
  exchangeType?: string;
  exchangeTimestamp?: string;
  quantity?: number;

  setExchangeType: (exchangeType: string) => void;
  clearExchangeType: () => void;
  setExchangeId: (exchangeId: string) => void;
  clearExchangeId: () => void;
  setExchangeTimestamp: (time: Date) => void;
  clearExchangeTimestamp: () => void;

  setDepositId: (depositId: string) => void;
  clearDepositId: () => void;

  setOrderId: (orderId: string) => void;
  clearOrderId: () => void;

  transactionHash?: string;
  setTransactionHash: (hash: string) => void;
  clearTransactionHash: () => void;

  setOrderQuantity: (quantity: number) => void;
};

const useOrderState = create<OrderState>((set) => ({
  orderId: storage.getItem('orderId') || undefined,
  exchangeId: storage.getItem('exchangeId') || undefined,
  exchangeType: storage.getItem('exchangeType') || undefined,
  setOrderId: (id) => {
    set({ orderId: id });
  },
  clearOrderId: () => {
    set({ orderId: undefined });
  },
  setExchangeType: (type) => {
    set({ exchangeType: type });
  },
  clearExchangeType: () => {
    set({ exchangeType: undefined });
  },
  setExchangeTimestamp: (time: Date) => {
    set({ exchangeTimestamp: time.toString() });
  },
  clearExchangeTimestamp: () => {
    set({ exchangeTimestamp: undefined });
  },
  setExchangeId: (id) => {
    set({ exchangeId: id });
  },
  clearExchangeId: () => {
    set({ exchangeId: undefined });
  },
  setDepositId: (id) => {
    set({ depositId: id });
  },
  clearDepositId: () => {
    set({ depositId: undefined });
  },
  transactionHash:
    storage.getItem('orderTxHash') ||
    storage.getItem('exchangeTxHash') ||
    undefined,
  setOrderQuantity: (quantity) => {
    set({ quantity: quantity });
  },
  setTransactionHash: (id) => {
    set({ transactionHash: id });
  },
  clearTransactionHash: () => {
    set({ transactionHash: undefined });
  },
}));

export default useOrderState;
