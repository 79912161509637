import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BigNumber } from 'bignumber.js';
import ReactModal from 'react-modal';
import Web3 from 'web3';
import { trackEvent } from 'utils/analytics';

import useQueryParams from 'hooks/useQueryParams';
import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import useOrderState from 'hooks/useOrderState';
import useSettingsStore from 'hooks/useSettingsStore';
import { useCurrentUserFunds, deposit } from 'api/user';
import { useSettings } from 'api/settings';
import storage from 'utils/storage';
import { addressStartAndEnd } from 'utils/etherscan';
import { ContractAddress } from 'constants/contract';
import abi from 'constants/exchangeAbi';

import Button from 'shared_components/Button';
import CurrencyValue from 'shared_components/CurrencyValue';
import InputText from 'shared_components/InputText';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import appLogo from 'assets/logos/full_logo.svg';
import styles from './styles.module.scss';

const DepositModal = () => {
  const history = useHistory();
  const { addNotification } = useNotifications();
  const { modal } = useQueryParams();
  const isOpen = modal === 'deposit';

  const persistedSettings = (useSettingsStore() || undefined) as
    | Settings
    | undefined;
  const { data } = useSettings(!isOpen, undefined, {
    initialData: persistedSettings,
    revalidateOnFocus: isOpen,
    revalidateOnReconnect: isOpen,
  });
  const contractAddress = data?.eth?.exchangeContract
    ? data.eth.exchangeContract
    : ContractAddress.address;

  const state = useRequestState();
  const {
    clearExchangeId,
    clearExchangeType,
    setExchangeId,
    setExchangeType,
    setTransactionHash,
    setExchangeTimestamp,
  } = useOrderState();

  const { data: funds } = useCurrentUserFunds();
  const [currentAmount, setCurrentAmount] = useState('');
  const [currentNetwork, setCurrentNetwork] = useState('');
  const [currentWalletAddress, setCurrentWalletAddress] = useState('');
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const coinbase = await web3.eth.getCoinbase();
          setCurrentWalletAddress(coinbase);

          await web3.eth.getBalance(coinbase).then(async (balance) => {
            const currentBalance = new BigNumber(balance);
            const costWei = web3.utils.fromWei(
              currentBalance.toString(),
              'ether'
            );
            setCurrentWalletBalance(Number(costWei));

            await web3.eth.net.getNetworkType().then(async (data) => {
              setCurrentNetwork(data);
            });
          });
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [isOpen]);

  const handleDismiss = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const handleDeposit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        state.start();
        const depositAmount = parseFloat(currentAmount);

        if (depositAmount !== 0) {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const coinbase = await web3.eth.getCoinbase();

          const cost = new BigNumber(depositAmount);
          const costWei = web3.utils.toWei(cost.toString(), 'ether');

          await web3.eth.getBalance(coinbase).then(async (balance) => {
            const { data } = await deposit({
              eth: currentAmount,
            });

            const currentBalance = new BigNumber(balance);
            const bnCost = new BigNumber(costWei);

            if (bnCost.isLessThan(currentBalance)) {
              const exchangeId = data.exchangeId;
              const contract = new web3.eth.Contract(abi, contractAddress);

              await contract.methods
                .deposit(exchangeId)
                .send({ from: coinbase, value: costWei })
                .on('error', function (error: { message: string }) {
                  addNotification({
                    type: 'secondary',
                    title: `Failed to deposit`,
                    subtitle: error.message,
                  });
                  clearExchangeType();
                  clearExchangeId();
                  state.end();
                })
                .on('transactionHash', function (transactionHash: string) {
                  setExchangeType('deposit');
                  setExchangeId(String(data?.exchangeId));
                  setExchangeTimestamp(new Date());
                  storage.setItem('exchangeId', data?.exchangeId.toString());
                  storage.setItem('exchangeType', 'deposit');

                  trackEvent('Deposit', currentAmount);

                  setTransactionHash(transactionHash);
                  storage.setItem('exchangeTxHash', transactionHash);
                  handleDismiss();
                  state.end();
                })
                .on('receipt', function () {
                  state.end();
                });
            } else {
              addNotification({
                type: 'secondary',
                title: `Balance too low`,
                subtitle:
                  'You do not have a high enough balance to make that deposit.',
              });
              clearExchangeType();
              clearExchangeId();
              state.end();
            }
          });
        }
      } catch (e) {
        addNotification({
          type: 'secondary',
          title: `Failed to detect wallet`,
          subtitle: e.message,
        });
        state.end();
      }
    },
    [
      handleDismiss,
      currentAmount,
      state,
      addNotification,
      contractAddress,
      setTransactionHash,
      setExchangeId,
      setExchangeType,
      setExchangeTimestamp,
      clearExchangeId,
      clearExchangeType,
    ]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.editProfileModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.editProfileModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          Deposit ETH into Kolectiv
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <form onSubmit={handleDeposit} className={styles.content}>
        <div className={styles.balanceContainer}>
          <div className={styles.wallet}>
            <div className={styles.walletBalance}>
              <div className={styles.networkInfo}>
                <Text type="muted" size="sm">
                  {addressStartAndEnd(currentWalletAddress)}
                </Text>
                <Text type="white" size="sm" weight="bold">
                  {currentNetwork.toUpperCase()} ETHEREUM NETWORK
                </Text>
              </div>
            </div>
            <CurrencyValue
              value={String(currentWalletBalance.toFixed(6))}
              size={30}
            />
          </div>
          <div className={styles.balance}>
            <div className={styles.kolectivBalance}>
              <div className={styles.networkInfo}>
                <img alt="Kolectiv" src={appLogo} className={styles.appLogo} />
              </div>
            </div>
            <CurrencyValue value={funds?.weth} size={30} />
          </div>
        </div>
        <InputText
          required
          type="number"
          placeholder="Enter ETH Amount"
          min={0}
          step={0.0001}
          value={currentAmount}
          onChange={setCurrentAmount}
          className={styles.formInput}
        />

        <Button
          htmlType="submit"
          loading={state.loading}
          className={styles.button}
          rounded="full"
          block
        >
          DEPOSIT
        </Button>
      </form>
    </ReactModal>
  );
};

export default DepositModal;
