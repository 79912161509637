import io from 'socket.io-client';

import { API_SOCKET_URL } from 'config';

const socket = io(API_SOCKET_URL, {
  transports: ['websocket'],
  autoConnect: true,
});

socket.emit('join-public-feed', '');

export const authenticateSocket = (jwt: string) => {
  socket.emit('join-private-user', jwt);
};

export default socket;
