import { get, groupBy, keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { EthVaultIcon } from 'shared_components/SVGIcons';
import Text from 'shared_components/Text';
import cx from 'classnames';
import { stringifyUrlWithParam } from 'utils/routes';
import styles from './styles.module.scss';
import useAppSeasons from 'hooks/useAppSeasons';
import { useHistory } from 'react-router-dom';
import useImxAssets from 'hooks/useImxAssets';
import useOwnedCards from 'hooks/useOwnedCards';

type Props = {
  className?: string;
};

const VaultButton = (props: Props) => {
  const { className } = props;
  const history = useHistory();
  const { season } = useAppSeasons();
  const { assets } = useImxAssets();
  const { merged } = useOwnedCards(undefined, season);
  const cardsByUuid = useMemo(
    () =>
      keyBy(
        merged.filter(
          (i) =>
            i.status === 'available' ||
            i.status === 'imx_locked' ||
            i.status === 'eth_locked'
        ),
        'uuid'
      ),
    [merged]
  );
  const imxAssets = groupBy(
    assets?.filter((a) => {
      const card = cardsByUuid[get(a, 'metadata.uuid')];
      return card && card.status !== 'available';
    }),
    'status'
  );
  const count = imxAssets.withdrawable?.length;

  const handleClick = useCallback(() => {
    history.push(stringifyUrlWithParam(history.location, { modal: 'vault' }));
  }, [history]);

  return (
    <div
      role="button"
      onClick={handleClick}
      className={cx(styles.vaultButton, className)}
    >
      <div className={styles.vaultContent}>
        <EthVaultIcon
          size={20}
          className={count > 0 ? styles.lockImageActive : styles.lockImage}
        />
        {Boolean(count) && (
          <div className={styles.vaultTag}>
            <Text
              size="sm"
              weight="extrabold"
              type="white"
              className={styles.vaultTagNumber}
            >
              {count}
            </Text>
          </div>
        )}
        <Text
          size="xs"
          weight="bold"
          uppercase
          type={'muted'}
          className={styles.vaultText}
        >
          Asset Vault
        </Text>
      </div>
    </div>
  );
};

export default VaultButton;
