import '@szhsin/react-menu/dist/index.css';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useAuth from 'hooks/useAuth';
import useAppInitialize from 'hooks/useAppInitialize';
import { InitialFeedEventsProvider } from 'hooks/useInitialFeedEvents';
import { authenticateSocket } from 'api/socket';
import { getToken } from 'utils/auth';

import Pages from 'pages';
import Notifications from 'shared_components/Notifications';
import Spinner from 'shared_components/Spinner';

ReactModal.setAppElement('#root');

const App = () => {
  const { login } = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      login(token);
      authenticateSocket(token.jwt);
    }
    setInitialized(true);
  }, [login]);

  useAppInitialize();

  if (!initialized) return <Spinner centered />;

  return (
    <HelmetProvider>
      <Helmet title="Kolectiv Home" />
      <InitialFeedEventsProvider>
        <Pages />
      </InitialFeedEventsProvider>
      <Notifications />
    </HelmetProvider>
  );
};

export default App;
