import { useEffect } from 'react';
import { persist } from 'zustand/middleware';
import create from 'zustand';

import usePrevious from './usePrevious';
import useSettingsStore from './useSettingsStore';
import storage from 'utils/storage';
import { Season } from 'constants/season';

type SeasonState = {
  season: string;
  setSeason: (season: string) => void;
};

const useSeasonState = create<SeasonState>(
  persist(
    (set) => ({
      season: Season.id,
      setSeason: (season) => {
        set({ season });
      },
    }),
    {
      name: 'persist:seasons',
      getStorage: () => storage,
    }
  )
);

const useAppSeasons = () => {
  const { season, setSeason } = useSeasonState();
  const seasons = useSettingsStore((state) => state.seasons) || [];
  const formattedSeasons = seasons.map((season) =>
    typeof season === 'string' ? season : season.name
  );
  const prevData = usePrevious(seasons);
  useEffect(() => {
    if (!season && !prevData && formattedSeasons.length)
      setSeason(formattedSeasons[0]);
  }, [season, prevData, formattedSeasons, setSeason]);
  return { season, seasons: formattedSeasons, setSeason };
};

export default useAppSeasons;
