import { CDN_URL, EPICS_CDN_URL } from 'config';

export const getImageCdnUrl = (
  path: string,
  thumbnailSize?: boolean,
  shouldUseEpicsCdn?: boolean
) => {
  const url = path.includes('https://')
    ? path
    : `${shouldUseEpicsCdn ? EPICS_CDN_URL : CDN_URL}${path}`;
  return thumbnailSize ? url.replace(/(\.\w+)$/, '_thumb$1') : url;
};

export const getFlagCdnUrl = (country: string | 'global') => {
  return CDN_URL + '/assets/flags/' + country + '.png';
};

type SVGImageSize = keyof CardTemplate['images']['svg'];

export const getCardSVGImage = (
  template: CardTemplate | Card,
  preferredSize?: SVGImageSize
) => {
  if (!template?.images?.svg) return null;

  if (preferredSize) {
    if (template?.images?.svg?.[preferredSize]) {
      return template?.images?.svg?.[preferredSize];
    }
    if (!/[0-9]/.test(preferredSize)) {
      const nonNumericSize = Object.keys(template.images.svg).find((key) =>
        /[^0-9]/.test(key)
      ) as SVGImageSize;
      if (nonNumericSize) return template.images.svg[nonNumericSize];
    }
  }

  const keys = Object.keys(template.images.svg) as Array<SVGImageSize>;
  const sortedSizes = keys
    .map((key) => key.replace(/[^0-9]/g, ''))
    .map(Number)
    .filter(Boolean)
    .sort((a, b) => a - b);

  if (preferredSize) {
    const preferredNumericSize = Number(preferredSize.replace(/[^0-9]/g, ''));
    const nearestSize = sortedSizes.find(
      (size) => size >= preferredNumericSize
    );
    const nearestKey = keys.find((key) =>
      key.includes(String(nearestSize))
    ) as SVGImageSize;
    if (nearestKey) return template.images.svg[nearestKey] ?? null;
  }

  return template.images.svg[keys[keys.length - 1]] ?? null;
};

type NonSVGImageSize = keyof Omit<CardTemplate['images'], 'svg'>;

export const getCardNonSVGImage = (
  template: CardTemplate | Card,
  preferredSize?: NonSVGImageSize
) => {
  if (!template?.images) return null;
  if ('cardTemplateId' in template) return template.images.size402;

  const keys = Object.keys(template.images).filter(
    (key) => key !== 'svg'
  ) as Array<NonSVGImageSize>;

  if (preferredSize) {
    if (preferredSize in template.images) {
      return template?.images?.[preferredSize];
    }
    if (!/[0-9]/.test(preferredSize)) {
      const nonNumericSize = keys.find((key) =>
        /[^0-9]/.test(key)
      ) as NonSVGImageSize;
      if (nonNumericSize) return template.images[nonNumericSize];
    }
  }

  const sortedSizes = keys
    .map((key) => key.replace(/[^0-9]/g, ''))
    .map(Number)
    .filter(Boolean)
    .sort((a, b) => a - b);

  if (preferredSize) {
    const preferredNumericSize = Number(preferredSize.replace(/[^0-9]/g, ''));
    const nearestSize = sortedSizes.find(
      (size) => size >= preferredNumericSize
    );
    const nearestKey = keys.find((key) =>
      key.includes(String(nearestSize))
    ) as NonSVGImageSize;
    if (nearestKey) return template.images[nearestKey] ?? null;
  }

  return template.images[keys[keys.length - 1]] ?? null;
};
