import create from 'zustand';

import { saveToken, deleteToken } from 'utils/auth';
import clearStorage from 'utils/clearStorage';

type AuthState = {
  isAuth: boolean;
  jwt?: string;
  login: (token: Token) => void;
  setJWT: (token: Token) => void;
  logout: () => void;
};

const useAuth = create<AuthState>((set) => ({
  isAuth: false,
  jwt: undefined,
  setJWT: (token) => {
    saveToken(token);
    set({ jwt: token.jwt });
  },
  login: (token) => {
    saveToken(token);
    set({ isAuth: true, jwt: token.jwt });
  },
  logout: () => {
    deleteToken();
    set({ isAuth: false, jwt: undefined });
    clearStorage();
  },
}));

export default useAuth;
