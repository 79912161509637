import { useCallback } from 'react';

import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import { delistItemFromMarket, useUserMarketListings } from 'api/market';

import ConfirmationModal from 'shared_components/ConfirmationModal';
import Text from 'shared_components/Text';

import styles from './styles.module.scss';
import { useCurrentUser } from 'api/user';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  packTemplate: PackTemplate;
  reload: () => Promise<unknown>;
};

const ConfirmRemovePackListings = (props: Props) => {
  const { isOpen, onDismiss, packTemplate, reload } = props;

  const { data: currentUser } = useCurrentUser();
  const { data: marketListings } = useUserMarketListings(!isOpen, {
    userId: currentUser?.id,
    type: 'pack',
  });
  const listings = marketListings
    ?.map((l) => l.market)
    .flat()
    .filter((l) => l.pack.packTemplateId === packTemplate.id);

  const state = useRequestState();
  const { addNotification } = useNotifications();

  const handleRemovePackListings = useCallback(async () => {
    if (listings) {
      try {
        state.start();
        await Promise.all(
          listings.map((listing) =>
            delistItemFromMarket({ marketId: listing.marketId })
          )
        );
        await reload();
        onDismiss();
        addNotification({
          type: 'primary',
          title: `Pack ${
            listings.length > 1 ? 'listings' : 'listing'
          } removed!`,
          subtitle: `You have successfully removed your pack ${
            listings.length > 1 ? 'listings' : 'listing'
          } from the market.`,
          timeout: 5000,
        });
      } catch (e) {
        addNotification({
          type: 'secondary',
          title: `Failed to remove pack ${
            listings.length > 1 ? 'listings' : 'listing'
          }`,
          subtitle: e.response?.data?.error,
        });
      } finally {
        state.end();
      }
    }
  }, [listings, state, reload, onDismiss, addNotification]);

  return (
    <ConfirmationModal
      header="Remove Pack Listings"
      isOpen={isOpen}
      onCancel={onDismiss}
      onConfirm={handleRemovePackListings}
      confirmButtonLoading={state.loading}
      contentClassName={styles.confirmRemovePackListings}
    >
      <Text centered className={styles.msg}>
        Are you sure you want to remove your pack listings?
      </Text>
    </ConfirmationModal>
  );
};

export default ConfirmRemovePackListings;
