import { LegacyRef, useCallback, useMemo } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';

import usePrevious from 'hooks/usePrevious';
import useQueryParams from 'hooks/useQueryParams';
import { usePurchaseHistory } from 'api/purchasehistory';
import { formatDate, formatDateDistance } from 'utils/format';

import CurrencyIcon from 'shared_components/CurrencyIcon';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { ArrowRightIcon, CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const HistoryDrawer = () => {
  const history = useHistory();
  const { drawer } = useQueryParams();
  const isOpen = drawer === 'history';

  const { data, setSize, isValidating, size } = usePurchaseHistory(!isOpen);
  const prevData = usePrevious(data);
  const transactions = isOpen ? data : prevData;

  const isLastPage = useMemo(() => {
    if (data) {
      const total = data[0].total;
      const count = data.reduce((count, page) => (count += page.count), 0);
      return count === total;
    }
    return false;
  }, [data]);

  const handleDismiss = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const scrollRef: LegacyRef<HTMLDivElement> = useBottomScrollListener(() => {
    if (!isLastPage && !isValidating) setSize((size) => size + 1);
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={200}
      className={{
        base: styles.drawer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.transactionsDrawerOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          Deposit History
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <div ref={scrollRef} className={styles.content}>
        {transactions ? (
          <>
            {transactions?.[0]?.transactions?.length ? (
              transactions.map((page) =>
                page.transactions
                  .filter(
                    (obj) => obj.type === 'deposit' || obj.type === 'withdraw'
                  )
                  .map((transaction) => {
                    const positive = transaction.amount > 0;
                    return (
                      <div key={transaction.id} className={styles.transaction}>
                        <div className={styles.direction}>
                          {positive ? (
                            <div className={styles.positiveDirection}>
                              <ArrowRightIcon type="white" size={28} />
                            </div>
                          ) : (
                            <div className={styles.negativeDirection}>
                              <ArrowRightIcon type="white" size={28} />
                            </div>
                          )}
                        </div>
                        <div className={styles.left}>
                          <Text block size="xs" type="muted">
                            {formatDateDistance(transaction.created)}
                          </Text>
                          <Text
                            block
                            type="white"
                            weight="medium"
                            size="xs"
                            className={styles.description}
                          >
                            {transaction.description}
                          </Text>
                        </div>

                        {/* {transaction.ethTransaction.transaction} */}
                        <Text
                          type="white"
                          weight="bold"
                          className={styles.right}
                        >
                          {positive && '+'}
                          {transaction.amount}
                        </Text>
                        <CurrencyIcon size={16} />
                      </div>
                    );
                  })
              )
            ) : (
              <Text block centered className={styles.emptyText}>
                No transactions found
              </Text>
            )}
            {size > 1 && isValidating && (
              <Spinner size={24} type="white" className={styles.pageSpinner} />
            )}
          </>
        ) : (
          <Spinner centered type="primary" className={styles.spinner} />
        )}
      </div>
    </ReactModal>
  );
};

export default HistoryDrawer;
