import { map } from 'lodash';
import before from 'lodash/before';

/**
 * Returns a function that is only called once
 * @param function
 */
export function once(func: () => unknown) {
  return before(2, func);
}

/**
 * Fetch the SVG body from a SVG CDN url
 * @param url The url of the SVG to fetch, will be rejected if not from our CDN
 */
export async function fetchSvg(url = '') {
  try {
    const svgResponse = await fetch(url, {
      method: 'GET',
      cache: 'default',
    });

    if (svgResponse.ok) {
      const svg = await svgResponse.text();
      return svg;
    }
    throw new Error('SVG data could not be loaded');
  } catch (error) {
    console.error(error);
    console.error(`SVG data could not be loaded for '${url}'`);
    return '';
  }
}

/**
 * The API gives us badly formated font strings, this function cleans them up.
 * @param svgString
 */
export function fixFonts(svgString = '') {
  return svgString
    .replace(/"Russo One,/g, `'Russo One',`)
    .replace(/&amp;apos;/g, `'`);
}

/**
 *  Render an SVG into dom nodes and apply the needed changes to video foreign object and fonts
 */
export async function* renderSvg({
  url,
  autoplay,
  muted,
}: {
  url: string;
  autoplay: boolean;
  muted?: boolean;
}): AsyncGenerator<string> {
  const body = document.createElement('div');
  body.setAttribute('style', 'background-color: transparent;');
  const svgMarkup = (await fetchSvg(url)) || '';
  body.innerHTML = fixFonts(svgMarkup);

  const videoPlaceholders = body.querySelectorAll('image[data-video]');

  const videoNodes = map(videoPlaceholders, (videoElement) => {
    const videoURL = videoElement.getAttribute('data-video')?.valueOf();

    if (Number(videoURL?.length) > 0) {
      const videoPosterURL = videoElement.getAttribute('xlink:href')?.valueOf();

      const attributeNames = videoElement.getAttributeNames();
      const foreignObject = document.createElement('foreignObject');
      attributeNames.forEach((attr) => {
        foreignObject.setAttributeNode(
          // @ts-ignore I think the types must not be complete as you can select an attribute by the string
          videoElement.attributes[attr].cloneNode()
        );
      });
      foreignObject.setAttribute(
        'style',
        'position: relative; z-index: -10; max-width:100%;'
      );

      const videoNode = document.createElement('video');

      const videoSourceNode = document.createElement('source');

      videoNode.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');

      videoNode.setAttribute(
        'width',
        String(videoElement.getAttribute('width')?.valueOf())
      );

      videoNode.setAttribute(
        'height',
        String(videoElement.getAttribute('height')?.valueOf())
      );

      videoNode.setAttribute(
        'style',
        'position: relative; top: 0; bottom: 0; left: 0; right: 0; z-index: -10'
      );

      videoNode.setAttribute('playsinline', 'true');
      // videoNode.setAttribute('muted', String(muted));
      videoNode.setAttribute('loop', 'true');
      videoNode.setAttribute('poster', String(videoPosterURL));

      // only works if autoplay is avoided altogether if not wanting to play
      if (autoplay) {
        videoNode.setAttribute('autoplay', 'true');
      }

      videoSourceNode.setAttribute('src', String(videoURL));
      videoSourceNode.setAttribute('type', 'video/mp4');

      videoNode.appendChild(videoSourceNode);

      foreignObject.appendChild(videoNode);
      videoElement.replaceWith(foreignObject);

      videoNode.pause();

      return videoNode;
    }
  });

  const cleanupVideoNodes = () => {
    videoNodes.forEach((videoNode) => {
      videoNode?.remove();
      videoNode = undefined;
    });
    return '';
  };

  yield body.innerHTML;
  yield cleanupVideoNodes();
}
