import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { analytics } from 'utils/firebase';

export default function RouterAnalytics() {
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const path = `${location.pathname}?${location.search}`;
      analytics.setCurrentScreen(path);
      analytics.logEvent('page_view', {
        page_title: document?.title,
        page_path: path,
      });
    }
  }, [location]);
  return null;
}
