import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import useAuth from 'hooks/useAuth';
import usePackAddedSocket from 'hooks/usePackAddedSocket';
import { useUserPacksCount } from 'api/packs';
import { stringifyUrlWithParam } from 'utils/routes';

import Text from 'shared_components/Text';
import { PacksIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import useSettingsStore from 'hooks/useSettingsStore';

type Props = {
  className?: string;
};

const PacksButton = (props: Props) => {
  const { className } = props;
  const history = useHistory();
  const settingsStatePacksCount = useSettingsStore((state) => state.packs);
  const { isAuth } = useAuth();
  const { data, mutate } = useUserPacksCount(!isAuth);

  usePackAddedSocket(() => mutate());

  // settings are fetched early on so this settings-count may appear faster on
  // initial renders
  const total = data?.total ?? settingsStatePacksCount ?? 0;

  const handleClick = useCallback(() => {
    history.push(stringifyUrlWithParam(history.location, { drawer: 'packs' }));
  }, [history]);

  return (
    <>
      {isAuth && data?.total !== undefined && (
        <div
          role="button"
          onClick={handleClick}
          className={cx(styles.packsButton, className)}
        >
          <div className={styles.packsContent}>
            <PacksIcon size={20} className={total > 0 ? styles.packImageActive : styles.packImage} />

            {total > 0 &&
              <div className={styles.packsTag}>
                <Text
                  size="sm"
                  weight="extrabold"
                  type="white"
                  className={styles.packsTagNumber}
                >
                  {total}
                </Text>
              </div>
            }
            <Text
              size="xs"
              weight="bold"
              uppercase
              type={'muted'}
              className={styles.packsText}
            >
              Your Packs
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default PacksButton;
