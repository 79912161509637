import { omit } from 'lodash';
import request from './request';
import useRequest, { Config } from './swr';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

export const useCollections = (
  skip?: boolean,
  params?: { season?: string },
  config?: Config<Collection[]>
) =>
  useRequest<Collection[]>(
    !skip
      ? {
          url: `/collections`,
          params: params?.season ? { seasons: params.season } : undefined,
        }
      : null,
    config
  );

export const useAppCollections = (
  params: { seasons: string[] },
  config?: InfiniteConfig<Collection[]>
) => {
  return useRequestInfinite<Collection[]>((index, prevPageData) => {
    if (!params.seasons || !params.seasons[index]) return null;
    return {
      method: 'GET',
      url: '/collections',
      params: { seasons: params.seasons[index] },
    };
  }, config);
};

export const getCollections = (season: string) =>
  request.get<Collection[]>(`/collections`, { params: { seasons: season } });

export const useCollectionsUserSummary = (
  skip: boolean,
  params: { userId: User['id']; season?: string },
  config?: Config<CollectionSummary[]>
) =>
  useRequest<CollectionSummary[]>(
    !skip
      ? {
          url: `/collections/users/${params.userId}/user-summary`,
          params: omit(params, 'userId'),
        }
      : null,
    config
  );

export const useCollectionCardTemplates = (
  skip?: boolean,
  collectionId?: number,
  params?: { artistId?: number },
  config?: Config<CardTemplate[]>
) =>
  useRequest<CardTemplate[]>(
    !skip && collectionId
      ? { url: `/collections/${collectionId}/card-templates`, params }
      : null,
    config
  );

export const getCollectionCardTemplates = (collectionId: number) =>
  request.get<CardTemplate[]>(`/collections/${collectionId}/card-templates`);

export const useCollectionUserOwned = (
  skip?: boolean,
  collectionId?: number,
  userId?: number,
  config?: Config<{ cards: Card[] }>
) =>
  useRequest<{ cards: Card[] }>(
    !skip && collectionId && userId
      ? { url: `/collections/${collectionId}/users/${userId}/owned` }
      : null,
    config
  );

export const getCollectionUserOwned = (collectionId: number, userId: number) =>
  request.get<{ cards: Card[]; stickers: [] }>(
    `/collections/${collectionId}/users/${userId}/owned`
  );

export const useCollectionUserOwnedForTemplate = (
  skip?: boolean,
  userId?: number,
  cardTemplateId?: number,
  config?: Config<Card[]>
) =>
  useRequest<Card[]>(
    !skip && userId && cardTemplateId
      ? {
          url: `/collections/users/${userId}/card-templates/${cardTemplateId}/cards`,
        }
      : null,
    config
  );

type UserCardIds = { cardTemplateId: number; cardIds: number[] };

export const useCollectionUserCardIds = (
  skip: boolean,
  params: { userId: User['id']; collectionId: number },
  config?: Config<UserCardIds[]>
) =>
  useRequest<UserCardIds[]>(
    !skip
      ? {
          url: `/collections/users/${params.userId}/cardids`,
          params: omit(params, 'userId'),
        }
      : null,
    config
  );

type MissingCard = { type: 'card'; isGhost: true; cardTemplate: CardTemplate };

export const useCollectionMissingCards = (
  skip: boolean,
  params: { userId: User['id']; collectionId: number },
  config?: Config<MissingCard[]>
) =>
  useRequest<MissingCard[]>(
    !skip
      ? {
          url: `/collections/${params.collectionId}/users/${params.userId}/missing-cards`,
        }
      : null,
    config
  );

export type UseCardsForTemplateResponse = {
  total: number;
  limit: number;
  cards: CardLite[];
  users: Pick<
    User,
    'id' | 'username' | 'avatar' | 'group' | 'groups' | 'country' | 'created'
  >[];
};

export const useCardsForTemplate = (
  cardTemplateId?: number,
  config?: InfiniteConfig<UseCardsForTemplateResponse>
) =>
  useRequestInfinite<UseCardsForTemplateResponse>((index, prevPageData) => {
    if (!cardTemplateId || (prevPageData && !prevPageData.data)) return null;
    return {
      url: `/cards/card-template-id/${cardTemplateId}`,
      params: { page: index + 1 },
    };
  }, config);

export const getCardsForTemplate = (cardTemplateId: number, size?: number) =>
  request.get<UseCardsForTemplateResponse>(
    `/cards/card-template-id/${cardTemplateId}`,
    {
      params: { page: size || 1 },
    }
  );

export const useCardByUuid = (uuid?: string, config?: Config<Card>) =>
  useRequest<Card>(uuid ? { url: `/uuid/card/${uuid}` } : null, config);
