import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  as?: 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  block?: boolean;
  centered?: boolean;
  children?: ReactNode;
  className?: string;
  ellipsize?: boolean;
  onClick?: () => void;
  role?: string;
  size?:
    | '3xs'
    | '2xs'
    | 'xs'
    | 'sm'
    | 'base'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl';
  style?: React.CSSProperties;
  to?: string;
  type?: 'primary' | 'secondary' | 'muted' | 'white';
  underlined?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  weight?:
    | 'black'
    | 'extrabold'
    | 'bold'
    | 'semibold'
    | 'medium'
    | 'regular'
    | 'light'
    | 'extralight'
    | 'thin';
};

const Text = (props: Props) => {
  const {
    as: el = 'span',
    block,
    capitalize,
    centered,
    children,
    className,
    ellipsize,
    onClick,
    role,
    size,
    style,
    to,
    type,
    underlined,
    uppercase,
    weight,
  } = props;

  const cn = cx(
    styles.text,
    {
      [styles.block]: (el === 'span' || to) && block,
      [styles.centered]: centered,
      [styles.ellipsize]: ellipsize,
      [styles.underlined]: underlined,
      [styles.uppercase]: uppercase,
      [styles.capitalize]: capitalize,

      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.muted]: type === 'muted',
      [styles.white]: type === 'white',

      [styles.xxxl]: size === '3xl',
      [styles.xxl]: size === '2xl',
      [styles.xl]: size === 'xl',
      [styles.lg]: size === 'lg',
      [styles.md]: size === 'md',
      [styles.base]: size === 'base',
      [styles.sm]: size === 'sm',
      [styles.xs]: size === 'xs',
      [styles.xxs]: size === '2xs',
      [styles.xxxs]: size === '3xs',

      [styles.black]: weight === 'black',
      [styles.extrabold]: weight === 'extrabold',
      [styles.bold]: weight === 'bold',
      [styles.semibold]: weight === 'semibold',
      [styles.medium]: weight === 'medium',
      [styles.regular]: weight === 'regular',
      [styles.light]: weight === 'light',
      [styles.extralight]: weight === 'extralight',
      [styles.thin]: weight === 'thin',
    },
    className
  );

  if (to)
    return (
      <Link to={to} className={cn} onClick={onClick} style={style}>
        {children}
      </Link>
    );

  const Element = el === 'span' && block ? 'div' : el;
  return (
    <Element className={cn} role={role} onClick={onClick} style={style}>
      {children}
    </Element>
  );
};

export default Text;
