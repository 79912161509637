import { useEffect, useMemo, useState } from 'react';
import { isBefore, formatDistanceToNowStrict } from 'date-fns';
import cx from 'classnames';

import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import { useUserPacks } from 'api/packs';
import { addQueryParam } from 'utils/routes';
import { getImageCdnUrl } from 'utils/images';

import ListOnMarketModal from 'shared_containers/shared_modals/ListOnMarketModal';
import ConfirmRemovePackListings from '../ConfirmRemovePackListings';

import Text from 'shared_components/Text';
import Button from 'shared_components/Button';
import { CloseIcon, MarketIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type PacksDrawerItemProps = {
  packs: Pack[];
  packTemplate: PackTemplate;
};

const PacksDrawerItem = (props: PacksDrawerItemProps) => {
  const { packs, packTemplate } = props;

  const [isListModalOpen, setListModalOpen] = useState(false);
  const handleDismissListModal = () => setListModalOpen(false);

  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const handleDismissRemoveModal = () => setRemoveModalOpen(false);

  const { start, end, loading, reset } = useRequestState();
  const { addNotification } = useNotifications();
  const { mutate } = useUserPacks(!loading);

  useEffect(() => {
    reset();
  }, [isListModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadOwnedPacks = async () => {
    start();
    try {
      await mutate();
    } catch (e) {
      addNotification({
        title: 'Error refreshing packs',
        type: 'secondary',
      });
    } finally {
      end();
    }
  };

  const { available, market } = useMemo(() => {
    const available: Pack[] = [];
    const market: Pack[] = [];
    packs.forEach((pack) => {
      if (pack.status === 'market') market.push(pack);
      else available.push(pack);
    });
    return { available, market };
  }, [packs]);

  const [firstPack, ...otherPacksForTemplate] = available;
  const isUnreleased = isBefore(new Date(), new Date(packTemplate.releaseTime));

  return (
    <>
      <div className={styles.packsDrawerItem}>
        <div className={styles.packMainInfo}>
          <div className={styles.packImgContainer}>
            <img
              src={getImageCdnUrl(
                packTemplate.images.sort((a, b) =>
                  a.name === 'image' ? -1 : 1
                )[0].url,
                true
              )}
              alt={packTemplate.name}
              key={packTemplate.id}
            />
          </div>

          <div className={styles.text}>
            {packs.length > 1 && (
              <div className={styles.badges}>
                <Text
                  weight="bold"
                  size="2xs"
                  type="white"
                  uppercase
                  className={styles.count}
                >
                  <span>{available.length} To Open</span>
                </Text>
                {market.length > 0 && (
                  <Text
                    weight="bold"
                    size="2xs"
                    type="white"
                    uppercase
                    className={cx(styles.count, styles.countListed)}
                  >
                    <span>{market.length} On Market</span>
                    <Button
                      block
                      size="sm"
                      type="muted"
                      onClick={() => setRemoveModalOpen(true)}
                      className={styles.removeButton}
                    >
                      <CloseIcon size={12} className={styles.icon} />{' '}
                      <Text size="2xs" weight="semibold">
                        REMOVE
                      </Text>
                    </Button>
                  </Text>
                )}
              </div>
            )}

            <Text
              as="h2"
              ellipsize
              size="lg"
              weight="extrabold"
              type="white"
              className={styles.name}
            >
              {packTemplate.name}
            </Text>

            <Text as="p" size="sm" weight="medium">
              {packTemplate.description}
            </Text>

            {firstPack && (
              <div className={styles.buttonsRow}>
                <Button
                  size="sm"
                  disabled={isUnreleased}
                  to={(location) => ({
                    pathname: 'opening',
                    search: addQueryParam('', {
                      pid: firstPack.id,
                      tid: firstPack.packTemplateId,
                    }),
                    state: {
                      previousLocation: location,
                      pids: otherPacksForTemplate.map((p) => p.id),
                    },
                  })}
                >
                  {isUnreleased
                    ? `OPEN IN ${formatDistanceToNowStrict(
                        new Date(packTemplate.releaseTime)
                      )}`
                    : `OPEN PACK (${available.length})`}
                </Button>
                <Button
                  size="sm"
                  type="muted"
                  onClick={() => setListModalOpen(true)}
                >
                  <MarketIcon size={14} className={styles.icon} /> ADD TO MARKET
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ListOnMarketModal
        packs={available}
        packTemplate={packTemplate}
        isOpen={isListModalOpen}
        onDismiss={handleDismissListModal}
        reload={reloadOwnedPacks}
      />

      <ConfirmRemovePackListings
        packTemplate={packTemplate}
        isOpen={isRemoveModalOpen}
        onDismiss={handleDismissRemoveModal}
        reload={reloadOwnedPacks}
      />
    </>
  );
};

export default PacksDrawerItem;
