import { useCallback, useState } from 'react';
import { getUploadCode, uploadAvatar, useCurrentUser } from 'api/user';
import useNotifications from './useNotifications';
import useRequestState from './useRequestState';

const useUploadAvatar = () => {
  const { addNotification } = useNotifications();
  const { mutate } = useCurrentUser();
  const state = useRequestState();
  const [avatar, setAvatar] = useState<string>();
  const [file, setFile] = useState<File>();

  const handleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];

      if (file) {
        if (file?.size > 1_000_000) {
          state.setError('File must be below 1MB');
          addNotification({
            type: 'secondary',
            title: 'File too large',
            subtitle: 'Selected file must not exceed 1 MB.',
          });
        } else {
          const read = new FileReader();
          read.onloadend = () => {
            setAvatar(read.result as string);
            setFile(file);
          };
          read.readAsDataURL(file);
        }
      }
    },
    [addNotification, state]
  );

  const handleUpload = useCallback(async () => {
    if (!file) return;
    try {
      state.start();
      const { data } = await getUploadCode();
      await uploadAvatar({ code: data.code, file });
      await mutate();
      addNotification({
        title: 'Avatar changed',
        subtitle: 'You have successfully updated your avatar.',
      });
    } catch (e) {
      state.setApiError(e);
      addNotification({
        type: 'secondary',
        title: 'Avatar upload failed',
        subtitle: 'Failed to upload avatar.',
      });
      throw e;
    } finally {
      state.end();
    }
  }, [addNotification, file, mutate, state]);

  const handleReset = useCallback(() => {
    setAvatar(undefined);
    setFile(undefined);
    state.reset();
  }, [state]);

  return {
    avatar,
    handleSelect,
    handleUpload,
    handleReset,
    uploading: state.loading,
    error: state.error,
  };
};

export default useUploadAvatar;
