import useRequest, { Config } from './swr';

export const useUser = (userId?: number, config?: Config<{ user: User }>) =>
  useRequest<{ user: User }>(
    userId ? { url: `/users/user/${userId}` } : null,
    config
  );

export const useUserByUsername = (
  username?: string,
  config?: Config<{ user: User }>
) =>
  useRequest<{ user: User }>(
    username ? { url: `/users/username/${username}` } : null,
    config
  );

export const useSearchUser = (username?: string, config?: Config<User[]>) =>
  useRequest<User[]>(
    username ? { url: `/users/search`, params: { username } } : null,
    config
  );
