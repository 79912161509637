import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';

import useAuth from 'hooks/useAuth';
import useQueryParams from 'hooks/useQueryParams';
import useNotifications from 'hooks/useNotifications';
import { buildPathQuery, stringifyUrlOmitParam } from 'utils/routes';
import { Routes } from 'constants/routes';

import Text from 'shared_components/Text';
import MenuNavItem, { Props as MenuNavItemProps } from './MenuNavItem';
// import { Props as MenuSwitchItemProps } from './MenuSwitchItem';
import {
  CloseIcon,
  LogoutIcon,
  TransactionsIcon,
  UserIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const MenuDrawer = () => {
  const history = useHistory();
  const { logout } = useAuth();
  const { drawer } = useQueryParams();
  const { addNotification } = useNotifications();
  // const [switchMentions, setSwitchMentions] = useState(false);
  // const [switchToast, setSwitchToast] = useState(false);

  const handleDismiss = useCallback(() => {
    history.push(stringifyUrlOmitParam(history.location, 'drawer'));
  }, [history]);

  const handleLogout = useCallback(async () => {
    logout();
    history.replace(Routes.landing);
    addNotification({
      type: 'primary',
      title: 'Logout success',
      subtitle: 'You have been logged out successfully.',
      timeout: 5000,
    });
  }, [logout, history, addNotification]);

  const menuNavItems: MenuNavItemProps[] = useMemo(
    () => [
      {
        icon: UserIcon,
        label: 'Edit Avatar',
        link: buildPathQuery(history.location.pathname, { modal: 'profile' }),
      },
      {
        icon: TransactionsIcon,
        label: 'My Transactions',
        link: buildPathQuery(history.location.pathname, {
          modal: 'transactions',
        }),
      },
    ],
    [history]
  );

  // const menuSwitchItems: MenuSwitchItemProps[] = useMemo(
  //   () => [
  //     {
  //       label: 'Allow mention notifications',
  //       value: switchMentions,
  //       onChange: (value) => setSwitchMentions(value),
  //     },
  //     {
  //       label: 'Allow toast notifications',
  //       value: switchToast,
  //       onChange: (value) => setSwitchToast(value),
  //     },
  //   ],
  //   [switchMentions, setSwitchMentions, switchToast, setSwitchToast]
  // );

  const isOpen = drawer === 'menu';

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={200}
      className={{
        base: styles.menuDrawer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.menuDrawerOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          User Settings
        </Text>
        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <div className={styles.content}>
        <ul className={styles.navItems}>
          {menuNavItems.map((item) => (
            <MenuNavItem key={item.label} {...item} />
          ))}
          <MenuNavItem
            label="Logout"
            icon={LogoutIcon}
            onClick={handleLogout}
            className={styles.logoutItem}
            iconClassName={styles.logoutIcon}
          />
        </ul>

        {/* <ul className={styles.switchItems}>
          {menuSwitchItems.map((item) => (
            <MenuSwitchItem key={item.label} {...item} />
          ))}
        </ul> */}
      </div>

      <div className={styles.footer}></div>
    </ReactModal>
  );
};

export default MenuDrawer;
