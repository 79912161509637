import { useCallback, useEffect, useState, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { isBefore } from 'date-fns';
import cx from 'classnames';
import Web3 from 'web3';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import useAppPacks from 'hooks/useAppPacks';
import usePackTemplates from 'hooks/usePackTemplates';
import useOrderState from 'hooks/useOrderState';
import useQueryParams from 'hooks/useQueryParams';
import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import usePackInventorySocket from 'hooks/usePackInventorySocket';
import { useEthUSDPrice } from 'hooks/useSettingsStore';

import { buyPack, useUserPacksCount } from 'api/packs';
import { useCurrentUser, useCurrentUserFunds } from 'api/user';
import { getImageCdnUrl } from 'utils/images';
import { trackEvent } from 'utils/analytics';

import ConfirmationModal from 'shared_components/ConfirmationModal';
import CurrencyValue from 'shared_components/CurrencyValue';
import PackCountdown from 'shared_components/PackCountdown';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { ArrowRightIcon, TimerIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const DEFAULT_MAX_AMOUNT = 5;

const getPackData = (pack?: PackTemplate) => {
  if (!pack || !pack.costTiers) return {};
  const total = pack.costTiers[pack.costTiers.length - 1].max;
  const remaining = total - pack.inventoryCount;
  const tierIndex = pack.costTiers.findIndex(
    (t) => remaining >= t.min && remaining <= t.max
  );
  const tier = pack.costTiers[tierIndex];
  const remainingTilNext = tier ? tier.max - remaining : undefined;

  const isLastTier = tierIndex === pack.costTiers.length - 1;
  const nextTier = pack.costTiers[tierIndex + 1];

  return { remainingTilNext, tier, isLastTier, nextTier };
};

const BuyPackModal = () => {
  const { modal, id } = useQueryParams();
  const isOpen = modal === 'pack';

  const usd = useEthUSDPrice();
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const { setOrderQuantity } = useOrderState();

  const state = useRequestState();
  const [amount, setAmount] = useState(1);

  const { addNotification } = useNotifications();

  const { mutate: reloadPacks } = useAppPacks();
  const { mutate: reloadPackCount } = useUserPacksCount();
  const { getTemplate } = usePackTemplates();
  const packTemplateId = Number(id);
  const packTemplate = getTemplate(packTemplateId);
  const { packTemplate: updatedPackTemplate } = usePackInventorySocket();
  const { remainingTilNext, isLastTier, nextTier } = getPackData(packTemplate);

  const cost = updatedPackTemplate?.cost ?? packTemplate?.cost;
  const inventoryCount =
    updatedPackTemplate?.inventoryCount ?? packTemplate?.inventoryCount;
  const overlapsNextTier = amount > (remainingTilNext ?? Infinity);
  const costIncludingNextTier = overlapsNextTier
    ? (Number(cost) ?? 0) * (remainingTilNext ?? 0) +
      (Number(nextTier?.ethPrice) ?? 0) * (amount - (remainingTilNext ?? 0))
    : (Number(cost) || 0) * amount;

  const [costOnMount, setCostOnMount] = useState(cost);
  useEffect(() => {
    if (!costOnMount && cost) setCostOnMount(cost);
  }, [cost, costOnMount]); // eslint-disable-line react-hooks/exhaustive-deps
  const costHasChanged = costOnMount && cost && costOnMount < cost;

  const handleDismiss = useCallback(() => {
    history.push({ pathname: history.location.pathname });
  }, [history]);

  const { mutate: reloadFunds } = useCurrentUserFunds();
  const handleConfirmBuy = useCallback(async () => {
    if (!inventoryCount) {
      return addNotification({
        type: 'secondary',
        title: 'Sold out',
        subtitle: 'Inventory is empty!',
      });
    }
    try {
      state.start();
      if (packTemplate) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const coinbase = await web3.eth.getCoinbase();
        setOrderQuantity(amount);
        await web3.eth.getBalance(coinbase).then(async (balance) => {
          const { data } = await buyPack({
            packTemplateId: packTemplate.id,
            amount,
          });
          await reloadFunds();
          await reloadPackCount();
          if (data.length > 0) {
            trackEvent(
              'Pack Purchase',
              String(amount * Number(cost)),
              amount * Number(cost) * Number(usd)
            );
            addNotification({
              type: 'primary',
              title: 'Purchase successful',
              subtitle: `You've successfully purchased ${data.length} pack${
                data.length > 1 ? 's' : ''
              }`,
              timeout: 5000,
            });
          }
          handleDismiss();
          state.end();
        });
      }
    } catch (e) {
      state.setApiError(e);
      addNotification({
        type: 'secondary',
        title: 'Pack purchase unsuccessful',
        subtitle: e.response?.data?.error,
      });
      state.end();
    }
  }, [
    addNotification,
    amount,
    reloadPackCount,
    cost,
    handleDismiss,
    inventoryCount,
    packTemplate,
    reloadFunds,
    setOrderQuantity,
    state,
    usd,
  ]);

  const now = new Date();
  const hasPresale = Boolean(
    packTemplate?.preSalePurchaseStart && packTemplate?.preSaleGroups
  );
  const hasPresaleStarted = Boolean(
    packTemplate?.preSalePurchaseStart &&
      isBefore(new Date(packTemplate.preSalePurchaseStart), now)
  );
  const isUserPresale = packTemplate?.preSaleGroups?.some((g) =>
    user?.groups.includes(g.group)
  );
  const hasStarted = packTemplate
    ? isBefore(new Date(packTemplate.purchaseStart), now)
    : false;

  // Fallback: pack.purchaseStart has not yet started
  let content: ReactNode = (
    <>
      <div className={styles.dropBegins}>
        <Text
          className={cx(styles.totalCount, styles.gradientText)}
          weight="semibold"
          size="sm"
        >
          Drop Begins
        </Text>
      </div>
      <PackCountdown
        date={packTemplate?.purchaseStart}
        onComplete={reloadPacks}
      />
    </>
  );

  // Case 1: if public sale already
  if (hasStarted) {
    content = (
      <>
        <div className={styles.botttomInfo}>
          <Text
            className={styles.totalRemainingText}
            weight="semibold"
            size="sm"
          >
            Only{' '}
            <Text
              as="h4"
              size="base"
              weight="extrabold"
              className={cx(styles.totalCount, styles.gradientText)}
            >
              {inventoryCount}
            </Text>
            packs remain
          </Text>
        </div>
        <Text
          block
          size="sm"
          type="muted"
          weight="semibold"
          className={styles.amountLabel}
        >
          Choose Amount {overlapsNextTier && `(Includes pricing of next tier)`}
        </Text>
        <div
          className={cx(styles.amountSliderRow, {
            [styles.disabled]: !inventoryCount,
          })}
        >
          <Slider
            min={1}
            max={Math.min(
              DEFAULT_MAX_AMOUNT,
              isLastTier
                ? remainingTilNext ?? Infinity
                : inventoryCount ?? Infinity
            )}
            value={amount}
            onChange={setAmount}
            className={styles.amountSlider}
          />
          <Text
            size="md"
            type="white"
            weight="bold"
            className={styles.amountValue}
          >
            x{amount}
          </Text>
        </div>
        <Text
          block
          size="sm"
          type="muted"
          weight="semibold"
          className={styles.totalLabel}
        >
          Total
        </Text>
        <CurrencyValue
          size={24}
          value={Number(costIncludingNextTier).toFixed(3)}
          className={cx(styles.packCost, styles.packCost__total)}
          showUSD
        />
        {costHasChanged && (
          <Text size="sm" type="muted">
            *price has updated since viewing
          </Text>
        )}
      </>
    );

    // Case 2: if pack has presale data & user whitelisted
  } else if (hasPresale && isUserPresale) {
    // Case 2.1: presale hasn't started yet
    if (!hasPresaleStarted) {
      content = (
        <>
          <div className={styles.dropBegins}>
            <Text
              className={cx(styles.totalCount, styles.gradientText)}
              weight="semibold"
              size="sm"
            >
              Founder Presale Begins
            </Text>
          </div>
          <PackCountdown
            date={packTemplate?.preSalePurchaseStart as string}
            onComplete={reloadPacks}
          />
        </>
      );
    }

    // Case 2.2: presale ongoing
    else {
      content = (
        <>
          <div className={styles.presaleEnds}>
            Presale ends in
            <PackCountdown
              inline
              date={
                packTemplate.preSalePurchaseEnd || packTemplate.purchaseStart
              }
              onComplete={reloadPacks}
              className={styles.presaleEndCountdown}
            />
          </div>
          <div className={styles.botttomInfo}>
            <Text
              className={styles.totalRemainingText}
              weight="semibold"
              size="sm"
            >
              Only{' '}
              <Text
                as="h4"
                size="base"
                weight="extrabold"
                className={cx(styles.totalCount, styles.gradientText)}
              >
                {inventoryCount}
              </Text>
              packs remain
            </Text>
          </div>

          <Text
            block
            size="sm"
            type="muted"
            weight="semibold"
            className={styles.amountLabel}
          >
            Choose Amount{' '}
            {overlapsNextTier && `(Includes pricing of next tier)`}
          </Text>
          <div
            className={cx(styles.amountSliderRow, {
              [styles.disabled]: !inventoryCount,
            })}
          >
            <Slider
              min={1}
              max={Math.min(
                DEFAULT_MAX_AMOUNT,
                isLastTier
                  ? remainingTilNext ?? Infinity
                  : inventoryCount ?? Infinity
              )}
              value={amount}
              onChange={setAmount}
              className={styles.amountSlider}
            />
            <Text
              size="md"
              type="white"
              weight="bold"
              className={styles.amountValue}
            >
              x{amount}
            </Text>
          </div>

          <Text
            block
            size="sm"
            type="muted"
            weight="semibold"
            className={styles.totalLabel}
          >
            Total
          </Text>
          <CurrencyValue
            size={24}
            value={Number(costIncludingNextTier).toFixed(3)}
            className={cx(styles.packCost, styles.packCost__total)}
            showUSD
          />

          {costHasChanged && (
            <Text size="sm" type="muted">
              *price has updated since viewing
            </Text>
          )}
        </>
      );
    }
  }

  const disabled =
    !hasStarted ||
    (hasPresale && (!hasPresaleStarted || !isUserPresale)) ||
    !inventoryCount ||
    state.loading;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      confirmDisabled={false}
      confirmLabel="CONFIRM PURCHASE"
      onCancel={handleDismiss}
      onConfirm={handleConfirmBuy}
      confirmButtonLoading={state.loading}
      className={styles.buyPackModal}
      contentClassName={styles.buyPackModalContent}
      headerClassName={styles.buyPackModalHeader}
    >
      {packTemplate ? (
        <>
          <img
            alt={packTemplate.name}
            src={getImageCdnUrl(
              packTemplate.images.find((i) => i.name === 'image')?.url as string
            )}
            className={styles.packImage}
          />
          <div className={styles.right}>
            <Text as="h2" size="2xl" type="white" weight="bold">
              {packTemplate.name}
            </Text>
            <Text as="p" size="sm">
              {packTemplate.description}
            </Text>

            <div className={styles.currentPrice}>
              <Text as="h3" size="base" type="white" weight="bold">
                <span>CURRENT PRICE</span>{' '}
                {!inventoryCount ? (
                  <Text size="sm" type="muted" weight="regular">
                    **OUT OF STOCK**
                  </Text>
                ) : (
                  remainingTilNext != null && (
                    <Text size="sm" type="muted" weight="regular">
                      <TimerIcon />{' '}
                      <Text
                        size="sm"
                        type="muted"
                        weight="extrabold"
                        className={styles.packcount}
                      >
                        {remainingTilNext}
                      </Text>{' '}
                      packs left at current price
                    </Text>
                  )
                )}
              </Text>
              <CurrencyValue
                size={24}
                value={cost}
                className={cx(styles.packCost, styles.packCost__current, {
                  [styles.update]: costHasChanged,
                })}
                key={packTemplate?.cost}
              />
              <ArrowRightIcon className={styles.arrow} />
            </div>

            {content}
          </div>
        </>
      ) : (
        <Spinner centered />
      )}
    </ConfirmationModal>
  );
};

export default BuyPackModal;
