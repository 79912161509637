import firebaseApp from 'firebase/app';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: 'AIzaSyC7zr2ut-hSGQIQU6BR_YtjOvcpkFjI4es',
  authDomain: 'kolectiv-6b50c.firebaseapp.com',
  projectId: 'kolectiv-6b50c',
  storageBucket: 'kolectiv-6b50c.appspot.com',
  messagingSenderId: '576309761656',
  appId: '1:576309761656:web:ce379cd419b99bb0dcff8d',
  measurementId: 'G-457CMRT5VX',
};

export const firebase = firebaseApp.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
