import { LegacyRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import { mutate } from 'swr';

import usePrevious from 'hooks/usePrevious';
import useQueryParams from 'hooks/useQueryParams';
import { useAllAlertSockets } from 'hooks/useAlertSocket';
import { markAlertsAsSeen, useAlerts } from 'api/alerts';
import { stringifyUrlOmitParam } from 'utils/routes';

import AlertsDrawerItem from './AlertsDrawerItem';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

const AlertsDrawer = () => {
  const history = useHistory();
  const { drawer } = useQueryParams();
  const isOpen = drawer === 'alerts';
  const hasOpened = useRef(false);

  const { data, isValidating, setSize, mutate: _mutate, size } = useAlerts(
    !isOpen
  );
  const prevData = usePrevious(data);
  const alerts = isOpen ? data : prevData;

  useAllAlertSockets(async () => {
    await mutate(JSON.stringify({ url: '/alerts/count' })); // update nav alert count
    await _mutate(); // refetch alerts
  });

  useEffect(() => {
    if (hasOpened.current) {
      (async () => {
        if (isOpen) await _mutate();
        else await mutate(JSON.stringify({ url: '/alerts/count' }));
      })();
    } else {
      if (isOpen) hasOpened.current = true;
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const allPagesUnseenCount =
    data?.reduce((n, page) => n + page.unseen, 0) || 0;

  const isLastPage = useMemo(() => {
    if (data) {
      const total = data[0].total;
      const count = data.reduce((count, page) => (count += page.count), 0);
      return count === total;
    }
    return false;
  }, [data]);

  const handleDismiss = useCallback(() => {
    history.push(stringifyUrlOmitParam(history.location, 'drawer'));
  }, [history]);

  const scrollRef: LegacyRef<HTMLDivElement> = useBottomScrollListener(() => {
    if (!isLastPage && !isValidating) setSize((size) => size + 1);
  });

  const handleMarkSeen = async () => {
    await markAlertsAsSeen({ all: true, ids: [] });
    await _mutate();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={200}
      className={{
        base: styles.drawer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.alertsDrawerOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <div className={styles.header}>
        <Text size="lg" weight="bold" type="white">
          Alerts{' '}
          {Boolean(allPagesUnseenCount) && (
            <Text size="xs" weight="bold" className={styles.unseen}>
              {allPagesUnseenCount}
            </Text>
          )}
        </Text>

        {Boolean(allPagesUnseenCount) && (
          <button className={styles.markSeenBtn} onClick={handleMarkSeen}>
            <Text size="sm" type="muted" weight="semibold">
              Mark all as seen
            </Text>
          </button>
        )}

        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <div ref={scrollRef} className={styles.content}>
        {alerts ? (
          alerts[0].total ? (
            <>
              <ul className={styles.navItems}>
                {alerts.map(({ alerts: pageOfAlerts }) =>
                  pageOfAlerts.map((alert) => (
                    <AlertsDrawerItem key={alert.id} alert={alert} />
                  ))
                )}
              </ul>
              {size > 1 && isValidating && (
                <Spinner
                  size={24}
                  type="white"
                  className={styles.pageSpinner}
                />
              )}
            </>
          ) : (
            <Text block centered size="xs" className={styles.endText}>
              You’re all caught up on your last 7 days of activity.
            </Text>
          )
        ) : (
          <Spinner centered type="primary" />
        )}
      </div>
    </ReactModal>
  );
};

export default AlertsDrawer;
