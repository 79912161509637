/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';

import Web3 from 'web3';
import Web3Modal from 'jayprado-web3modal';
import Authereum from 'authereum';
import Torus from '@toruslabs/torus-embed';
import Fortmatic from 'fortmatic';
import WalletConnectProvider from '@walletconnect/web3-provider';

import useAuth from 'hooks/useAuth';
import useQueryParams from 'hooks/useQueryParams';
import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import { authenticateSocket } from 'api/socket';
import {
  login as loginRequest,
  register,
  registerFinish,
  getNonce,
  checkEmail,
  checkUsername,
} from 'api/auth';
import clearStorage from 'utils/clearStorage';
import { Routes } from 'constants/routes';
import { API_URL } from 'config';

import Button from 'shared_components/Button';
import ErrorAlert from 'shared_components/ErrorAlert';
import InputText from 'shared_components/InputText';
import Text from 'shared_components/Text';
import { ArrowRightIcon, CloseIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const LoginModal = () => {
  const history = useHistory();
  const { modal } = useQueryParams();
  const { login } = useAuth();
  const { addNotification } = useNotifications();

  const isOpen = modal === 'login';

  const state = useRequestState();
  const [account, setAccount] = useState('');
  const [token, setToken] = useState<Token | null>(null);
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const resetPage = useCallback(() => {
    setToken(null);
    setIsLogin(false);
    setAccount('');
    state.reset();
  }, [state]);

  const handleDismiss = useCallback(() => {
    resetPage();
    history.replace({
      pathname: history.location.pathname,
      hash: history.location.hash,
    });
  }, [history, resetPage]);

  const onLogin = useCallback(
    (data: AuthData) => {
      clearStorage();
      login(data);
      authenticateSocket(data.jwt);
      resetPage();
      addNotification({
        type: 'primary',
        title: `You are now logged in`,
        subtitle: 'You have successfully logged in',
        timeout: 5000,
      });
      history.replace(Routes.home);
    },
    [addNotification, history, login, resetPage]
  );

  const handleSign = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        state.start();
        const web3 = new Web3(window.ethereum);
        web3?.eth?.net?.getNetworkType().then(async (network) => {
          if (!API_URL.includes('api-staging1') && network !== 'main') {
            window.alert(
              'Swap your wallet to the Ethereum main network to continue.'
            );
            return;
          } else if (
            API_URL.includes('api-staging1') &&
            network !== 'ropsten'
          ) {
            window.alert(
              'Swap your wallet to the Ropsten network to continue.'
            );
            return;
          } else {
            const coinbase = await web3.eth.getAccounts();
            const message = await getNonce({ ethAddress: coinbase[0] });
            web3.eth.personal.sign(
              message.data.nonce,
              coinbase[0],
              '',
              async (error: Error, signature: string) => {
                if (error) {
                  console.error('Error signing nonce...', error);
                  return;
                }
                const { data } = await register({
                  ethAddress: coinbase[0],
                  signature,
                });
                if (data.user && data.user.id) {
                  onLogin(data);
                } else {
                  const token: Token = {
                    jwt: data.jwt,
                    expires: data.expires,
                    refreshToken: data.refreshToken,
                  };
                  setToken(token);
                }
              }
            );
          }
        });
      } catch (e) {
        state.setApiError(e);
      } finally {
        state.end();
      }
    },
    [onLogin, state]
  );

  const handleConnectWallet = useCallback(
    async (formEvent: FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault();
      if (window.web3) {
        try {
          state.start();
          const web3Modal = new Web3Modal({
            network: API_URL.includes('api-staging1') ? 'ropsten' : 'mainnet',
            cacheProvider: true,
            providerOptions,
          });
          const provider = await web3Modal.connect();
          const web3 = new Web3(provider);
          window.ethereum = web3;
          web3.eth.getAccounts().then((data: any) => {
            setAccount(data[0]);
            handleSign(formEvent);
          });
        } catch (e) {
          window.location.reload();
        } finally {
          state.end();
        }
      } else if (window.ethereum) {
        try {
          state.start();
          const newWeb3 = new Web3(window.ethereum);
          newWeb3.eth.getAccounts().then((data: any) => {
            setAccount(data[0]);
            handleSign(formEvent);
            state.end();
          });
        } catch (e) {
          window.location.reload();
        } finally {
          state.end();
        }
      }
    },
    [state, handleSign]
  );

  const handleFinishRegistration = useCallback(
    async (formEvent: FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault();
      if (email && username && token) {
        try {
          state.start();
          const { data: validEmail } = await checkEmail({ email });
          const { data: validUsername } = await checkUsername({ username });
          if (validEmail.success && validUsername.success) {
            const { data } = await registerFinish(
              { username, email },
              token.jwt
            );
            onLogin(data);
          }
        } catch (e) {
          state.setApiError(e);
        } finally {
          state.end();
        }
      }
    },
    [email, onLogin, state, token, username]
  );

  const handleEpicsLogin = useCallback(
    async (formEvent: FormEvent<HTMLFormElement>) => {
      formEvent.preventDefault();
      if (email && password && token) {
        try {
          state.start();
          const { data } = await loginRequest({ email, password }, token.jwt);
          onLogin(data);
        } catch (e) {
          state.setApiError(e);
        } finally {
          state.end();
        }
      }
    },
    [email, onLogin, password, state, token]
  );

  let content: React.ReactNode;
  // Case: User wants to use existing Epics email address
  if (isLogin) {
    content = (
      <form onSubmit={handleEpicsLogin} className={styles.form}>
        <label className={styles.formItem}>
          <Text
            block
            centered
            uppercase
            size="sm"
            weight="semibold"
            className={styles.formLabel}
          >
            Epics Email Address
          </Text>
          <InputText
            autoFocus
            required
            type="email"
            value={email}
            onChange={setEmail}
            className={styles.formInput}
          />
        </label>
        <label className={styles.formItem}>
          <Text
            block
            centered
            uppercase
            size="sm"
            weight="semibold"
            className={styles.formLabel}
          >
            Epics Password
          </Text>
          <InputText
            autoFocus
            required
            type="password"
            value={password}
            onChange={setPassword}
            className={styles.formInput}
          />
        </label>
        <Button
          block
          htmlType="submit"
          loading={state.loading}
          className={styles.button}
        >
          Link your Epics account
        </Button>
        <Text
          className={styles.bottomLink}
          type="white"
          size="sm"
          onClick={() => {
            setIsLogin(false);
            state.reset();
          }}
        >
          Create a new account
        </Text>
      </form>
    );
  }
  // Case: User has to finish registration by adding username and/or email
  else if (token) {
    content = (
      <form onSubmit={handleFinishRegistration} className={styles.form}>
        <label className={styles.formItem}>
          <Text
            block
            centered
            uppercase
            size="sm"
            weight="semibold"
            className={styles.formLabel}
          >
            Username
          </Text>
          <InputText
            autoFocus
            required
            type="text"
            value={username}
            onChange={setUsername}
            className={styles.formInput}
          />
        </label>
        <label className={styles.formItem}>
          <Text
            block
            centered
            uppercase
            size="sm"
            weight="semibold"
            className={styles.formLabel}
          >
            Email Address
          </Text>
          <InputText
            autoFocus
            required
            type="email"
            value={email}
            onChange={setEmail}
            className={styles.formInput}
          />
        </label>
        <Button
          block
          htmlType="submit"
          loading={state.loading}
          className={styles.button}
        >
          Create your account
        </Button>
        <Text
          className={styles.bottomLink}
          type="white"
          size="sm"
          onClick={() => {
            setIsLogin(true);
            state.reset();
          }}
        >
          Link your Epics account
        </Text>
      </form>
    );
  }
  // Case: Signing wallet
  else if (account) {
    content = (
      <>
        <Text size="xl" type="white" weight="bold" className={styles.heading}>
          Sign with your wallet
          <ArrowRightIcon size={30} type="white" />
        </Text>
        <Text
          size="xs"
          block
          weight="regular"
          centered
          className={styles.introInfo}
        >
          Your wallet of choice should have opened and asked you to sign, if
          this did not happen or you closed it by accident, you can try again.
        </Text>
        <form onSubmit={handleSign} className={styles.mmForm}>
          <Button
            htmlType="submit"
            rounded="full"
            loading={state.loading}
            className={styles.metamaskButton}
          >
            <Text size="sm" uppercase weight="semibold">
              Try Again
            </Text>
          </Button>
        </form>
      </>
    );
  }
  // Default "Connect Wallet" state
  else {
    content = (
      <>
        <Text size="xl" type="white" weight="bold" className={styles.heading}>
          Connect your Wallet
        </Text>
        <Text size="sm" type="muted" className={styles.termsText}>
          By connecting with Cryptees you agree to our{' '}
          <a
            href="https://docs.google.com/document/d/e/2PACX-1vTs4KnD_arrpgc54S61gSX6ElLUTzByQMSDwpR6gVJ-k4P-UbFYqMGOfv9EPP86kg/pub"
            target="_blank"
            rel="noreferrer"
          >
            <Text type="white">Terms&nbsp;&amp;&nbsp;Conditions</Text>
          </a>
          &nbsp;&&nbsp;
          <a
            href="https://docs.google.com/document/d/e/2PACX-1vQ-dCmBH6J1uBlRQhlOQEvgjjU3lGRd6tb_K-hPZAi3BvtVTEAosnI-MSm9vHL4kQ/pub"
            target="_blank"
            rel="noreferrer"
          >
            <Text type="white">Privacy Policy</Text>
          </a>
          .
        </Text>
        <form onSubmit={handleConnectWallet} className={styles.mmForm}>
          <Button
            htmlType="submit"
            rounded="full"
            loading={state.loading}
            className={styles.metamaskButton}
          >
            CONNECT WITH WALLET
          </Button>
        </form>
      </>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.loginModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.loginModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <>
        <CloseIcon
          size={50}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />

        <div className={styles.content}>
          {token && (
            <Text
              centered
              uppercase
              size="xl"
              type="white"
              weight="bold"
              className={styles.heading}
            >
              {isLogin ? 'Link Epics account' : 'Create your account'}
            </Text>
          )}

          <ErrorAlert message={state.error} className={styles.errorAlert} />

          {content}
        </div>
      </>
    </ReactModal>
  );
};

const providerOptions = {
  formatic: {
    package: Fortmatic,
    options: {
      key: API_URL.includes('api-staging1')
        ? 'pk_test_A633EDB26EDF7849'
        : 'pk_live_6B9AFFD10188DC2D',
    },
  },
  torus: {
    package: Torus,
    options: {
      config: {
        buildEnv: API_URL.includes('api-staging1')
          ? 'development'
          : 'production',
      },
    },
  },
  authereum: {
    package: Authereum,
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '6dfb3bfee9da4d40bd0c696a1cc06ecb',
      qrcodeModalOptions: {
        mobileLinks: [
          'rainbow',
          'metamask',
          'argent',
          'trust',
          'imtoken',
          'pillar',
        ],
      },
    },
  },
};

export default LoginModal;
