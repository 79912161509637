import cx from 'classnames';

import colors from 'styles/colors.module.scss';
import styles from './styles.module.scss';

type Props = {
  centered?: boolean;
  className?: string;
  size?: number;
  type?: 'primary' | 'white' | 'textMuted';
  zIndex?: number;
};

const Spinner = (props: Props) => {
  const {
    centered,
    className,
    size = 36,
    type = 'textMuted',
    zIndex = 1,
  } = props;
  const spinner = (
    <div
      className={cx(styles.spinner, !centered && className)}
      style={getOuterStyle(size, zIndex)}
    >
      <div style={getInnerStyle(size, type)} />
      <div style={getInnerStyle(size, type)} />
      <div style={getInnerStyle(size, type)} />
      <div style={getInnerStyle(size, type)} />
    </div>
  );
  return centered ? (
    <div className={cx(styles.centered, className)}>{spinner}</div>
  ) : (
    spinner
  );
};

const getOuterStyle = (size: number, zIndex: number) => ({
  width: size,
  height: size,
  zIndex,
});

const getInnerStyle = (size: number, type: NonNullable<Props['type']>) => {
  size /= 1.25;
  const borderWidth = Math.ceil(size / 8);
  const borderColor = `${colors[type]} transparent transparent transparent`;
  return {
    width: size,
    height: size,
    borderWidth,
    borderColor,
    margin: borderWidth,
  };
};

export default Spinner;
