import { keyBy } from 'lodash';
import create from 'zustand';

type PackTemplatesState = {
  templates: Record<string, PackTemplate>;
  getTemplate: (id: number) => PackTemplate;
  setTemplates: (templates: PackTemplate[]) => void;
};

const usePackTemplates = create<PackTemplatesState>((set, get) => ({
  templates: {},
  getTemplate: (id) => {
    const { templates } = get();
    return templates[id];
  },
  setTemplates: (templates) =>
    set((state) => {
      const newTemplates = { ...state.templates, ...keyBy(templates, 'id') };
      return { templates: newTemplates };
    }),
}));

export default usePackTemplates;
