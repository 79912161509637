import {
  createContext,
  createElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import socket from 'api/socket';
import {
  LiveFeedEvent,
  LiveFeedEventType,
} from 'pages/Home/hooks/useLiveFeedSockets';
import { ProviderProps, useContext } from 'react';

// I've needed to map these to an Object (rather than Array) to be able to
// de-dupe events that fire both initial-sockets and our other socket
// listeners

const InitialFeedEventsContext = createContext<Record<number, LiveFeedEvent>>(
  {}
);

export const InitialFeedEventsProvider = (
  props: Omit<ProviderProps<never>, 'value'>
) => {
  const initialFeedEvents = useInitialFeedEventsState();
  return createElement(InitialFeedEventsContext.Provider, {
    ...props,
    value: initialFeedEvents,
  });
};

/**
 * We need to store these since the events will fire on *any* page
 * (initially loaded page)
 */
const useInitialFeedEventsState = () => {
  const TYPES = useRef<readonly LiveFeedEventType[]>([
    'pack-purchased',
    'pack-opened',
    'market-list',
    'market-sold',
    'auction-new-bid',
  ] as const);

  const types = TYPES.current;

  const [events, set] = useState<Record<number, LiveFeedEvent>>({});
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const keys = Object.keys(events);
  const shouldUnlisten = timer || keys.length >= 20;

  const handler = (e: LiveFeedEvent) =>
    set((prev) => ({ ...prev, [e.id || e.event]: e }));

  const listen = useCallback(
    () =>
      types.forEach((eventType) => {
        socket.on(eventType, handler);
      }),
    [types]
  );
  const unlisten = useCallback(
    () =>
      types.forEach((eventType) => {
        socket.off(eventType, handler);
      }),
    [types]
  );

  useEffect(() => {
    if (shouldUnlisten) {
      unlisten();
    } else {
      listen();
      return () => {
        unlisten();
      };
    }
  }, [listen, unlisten, shouldUnlisten]);

  useEffect(() => {
    if (keys.length && !timer) {
      const t = setTimeout(() => {
        setTimer(t);
        unlisten();
      }, 4000); // about enough time for them all to pre-populate
      return () => {
        clearTimeout(t);
      };
    }
  }, [timer, keys.length, unlisten]);

  return events;
};

export default function useInitialFeedEvents() {
  return useContext<Record<number, LiveFeedEvent>>(InitialFeedEventsContext);
}
