import { BidIcon, CloseIcon } from 'shared_components/SVGIcons';
import { LegacyRef, useCallback, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useOffersReceived, useOffersSent } from 'api/offers';

import ConfirmAcceptOfferModal from './ConfirmAcceptOfferModal';
import OfferItem from './OfferItem';
import ReactModal from 'react-modal';
import SentOfferItem from './SentOfferItem';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { stringifyUrlOmitParam } from 'utils/routes';
import styles from './styles.module.scss';
import useAlertSocket from 'hooks/useAlertSocket';
import useBalanceChangeSocket from 'hooks/useBalanceChangeSocket';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import useCardTemplates from 'hooks/useCardTemplates';
import { useHistory } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import useOffersWithdrawnSocket from 'hooks/useOffersWithdrawnSocket';
import usePrevious from 'hooks/usePrevious';
import useQueryParams from 'hooks/useQueryParams';

const OffersDrawer = () => {
  const history = useHistory();
  const { drawer } = useQueryParams();
  const { addNotification } = useNotifications();
  const isOpen = drawer === 'offers';

  const {
    data: receivedData,
    isValidating: receivedValidating,
    mutate: receivedMutate,
    setSize: receivedSetSize,
    size: receivedSize,
    revalidate: revalidateReceived,
  } = useOffersReceived();
  const receivedLastPage = useMemo(() => {
    if (receivedData) {
      const total = receivedData[0].total;
      const count = receivedData.reduce(
        (count, page) => (count += page.offers.length),
        0
      );
      return count === total;
    }
    return false;
  }, [receivedData]);
  const receivedScrollRef: LegacyRef<HTMLDivElement> = useBottomScrollListener(
    () => {
      if (!receivedLastPage && !receivedValidating)
        receivedSetSize((size) => size + 1);
    }
  );
  const prevReceivedData = usePrevious(receivedData);
  const receivedoffers = isOpen ? receivedData : prevReceivedData;

  const {
    data: sentData,
    isValidating: sentValidating,
    mutate: sentMutate,
    setSize: sentSetSize,
    size: sentSize,
    revalidate: revalidateSent,
  } = useOffersSent();
  const sentLastPage = useMemo(() => {
    if (sentData) {
      const total = sentData[0].total;
      const count = sentData.reduce(
        (count, page) => (count += page.offers.length),
        0
      );
      return count === total;
    }
    return false;
  }, [sentData]);
  const sentScrollRef: LegacyRef<HTMLDivElement> = useBottomScrollListener(
    () => {
      if (!sentLastPage && !sentValidating) sentSetSize((size) => size + 1);
    }
  );
  const sentPrevData = usePrevious(sentData);
  const sentOffers = isOpen ? sentData : sentPrevData;

  const reload = useCallback(async () => {
    await Promise.all([revalidateReceived(), revalidateSent()]);
  }, [revalidateReceived, revalidateSent]);

  const [acceptOfferItem, setAcceptOfferItem] = useState<Offer>();

  const handleAcceptOffer = useCallback((data) => {
    setAcceptOfferItem(data);
  }, []);

  const handleCancelOffer = useCallback(() => {
    setAcceptOfferItem(undefined);
  }, []);

  useBalanceChangeSocket(reload);
  useOffersWithdrawnSocket(reload);

  useAlertSocket<ReceivedOffer>('offer-received', (data) => {
    reload();
    addNotification({
      type: 'processing',
      title: `Offer of ${data.amount} ETH received for ${data.itemName}`,
      subtitle: 'You received an offer',
      timeout: 5000,
    });
  });

  useAlertSocket<AcceptedOffer>('offer-accepted', (data) => {
    reload();
    addNotification({
      type: 'primary',
      title: `Offer accepted for ${data.itemName}`,
      subtitle: 'Your offer has been accepted',
      timeout: 5000,
    });
  });

  useAlertSocket<DeclinedOffer>('offer-declined', (data) => {
    reload();
    addNotification({
      type: 'secondary',
      title: `Offer declined for ${data.itemName}`,
      subtitle: 'Your offer has been declined',
      timeout: 5000,
    });
  });

  const handleDismiss = useCallback(() => {
    history.push(stringifyUrlOmitParam(history.location, 'drawer'));
  }, [history]);
  const { getTemplate } = useCardTemplates();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={200}
      className={{
        base: styles.drawer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.offersDrawerOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      onAfterOpen={reload}
    >
      <div className={styles.header}>
        <BidIcon type="white" className={styles.icon} />

        <Text size="lg" weight="bold" type="white">
          Offers
        </Text>

        <CloseIcon
          size={60}
          role="button"
          onClick={handleDismiss}
          className={styles.closeIcon}
        />
      </div>

      <Tabs
        className={styles.reactTabs}
        selectedTabClassName={styles.tabSelected}
      >
        <TabList className={styles.tabList}>
          <Tab className={styles.tab}>
            <Text size="sm" weight="semibold">
              Incoming
            </Text>
          </Tab>
          <Tab className={styles.tab}>
            <Text size="sm" weight="semibold">
              Outgoing
            </Text>
          </Tab>
        </TabList>

        <div className={styles.panels}>
          <TabPanel className={styles.tabPanel}>
            <div ref={receivedScrollRef} className={styles.content}>
              {receivedoffers ? (
                receivedoffers[0].total ? (
                  <>
                    <ul className={styles.items}>
                      {receivedoffers.map(({ offers: items }) =>
                        items.map((offer) => {
                          const template = getTemplate(offer.entity.templateId);
                          if (!template) return null;
                          return (
                            <OfferItem
                              key={offer.id}
                              offer={offer}
                              itemName={offer.entity.itemName}
                              template={template}
                              handleAcceptOffer={handleAcceptOffer}
                              reload={receivedMutate}
                            />
                          );
                        })
                      )}
                    </ul>
                    {receivedSize > 1 && receivedValidating && (
                      <Spinner
                        size={24}
                        type="white"
                        className={styles.pageSpinner}
                      />
                    )}
                  </>
                ) : (
                  <Text
                    block
                    centered
                    size="xs"
                    weight="regular"
                    className={styles.endText}
                  >
                    You don‘t have any offers!
                  </Text>
                )
              ) : (
                <Spinner centered type="primary" />
              )}
            </div>
          </TabPanel>
          <TabPanel className={styles.tabPanel}>
            <div ref={sentScrollRef} className={styles.content}>
              {sentOffers ? (
                sentOffers[0].total ? (
                  <>
                    <ul className={styles.items}>
                      {sentOffers.map(({ offers: items }) =>
                        items.map((offer) => {
                          const template = getTemplate(offer.entity.templateId);
                          if (!template) return null;
                          return (
                            <SentOfferItem
                              key={offer.id}
                              offer={offer}
                              template={template}
                              reload={sentMutate}
                            />
                          );
                        })
                      )}
                    </ul>
                    {sentSize > 1 && sentValidating && (
                      <Spinner
                        size={24}
                        type="white"
                        className={styles.pageSpinner}
                      />
                    )}
                  </>
                ) : (
                  <Text
                    block
                    centered
                    weight="regular"
                    size="xs"
                    className={styles.endText}
                  >
                    You don‘t have any offers!
                  </Text>
                )
              ) : (
                <Spinner centered type="primary" />
              )}
            </div>
          </TabPanel>
        </div>
      </Tabs>

      <ConfirmAcceptOfferModal
        reload={reload}
        isOpen={Boolean(acceptOfferItem)}
        onDismiss={handleCancelOffer}
        offer={acceptOfferItem}
      />
    </ReactModal>
  );
};

export default OffersDrawer;
