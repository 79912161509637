import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import useImxAssets from 'hooks/useImxAssets';
import useImxActions from 'hooks/useImxActions';
import { buildPathParams } from 'utils/routes';
import { Routes } from 'constants/routes';

import Button from 'shared_components/Button';
import Placeholder from 'shared_components/PlaceholderImage';
import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import {
  ArrowRightIcon,
  KolectivIcon,
  EthIconPlain,
  SignIcon,
  ZKRollUpIcon,
  ImmutableIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  asset?: Asset;
  card?: Card;
};

const AssetVaultItem = (props: Props) => {
  const { asset, card } = props;
  const history = useHistory();
  const {
    imxToKolectiv,
    imxToMainnet,
    deposit,
    completeWithdrawal,
  } = useImxActions();
  const { getProcessingAsset } = useImxAssets();
  const processing = Boolean(getProcessingAsset(card?.uuid));

  const goToCard = useCallback(
    () =>
      history.push(
        buildPathParams(Routes.card, {
          id: card?.uuid,
        })
      ),
    [card, history]
  );

  if (!asset || !card) return null;

  return (
    <div key={card.id} className={styles.container}>
      <LazyLoad
        height={253}
        offset={0}
        once={true}
        resize={false}
        placeholder={<Placeholder height={279} />}
      >
        <img
          className={styles.cardImage}
          onClick={goToCard}
          alt={asset.name || asset.token_id}
          src={asset.metadata.image_url}
        />
      </LazyLoad>

      <div className={styles.cardInfo}>
        <div className={styles.statusInfo}>
          <Text type="muted" size="2xs" weight="bold" className={styles.status}>
            STATUS
          </Text>
          {processing ? (
            <div className={styles.inProcessing}>
              <Spinner size={18} className={styles.icon} />
              <Text type="white" weight="semibold" size="2xs">
                PROCESSING
              </Text>
            </div>
          ) : card.status === 'imx_locked' &&
            asset.status === 'withdrawable' ? (
            <>
              <div className={styles.inWallet}>
                <SignIcon size={12} className={styles.icon} />
                <Text type="white" weight="semibold" size="2xs">
                  SIGNATURE REQUIRED
                </Text>
              </div>
              <div className={styles.buttons}>
                <Text
                  block
                  type="muted"
                  size="2xs"
                  weight="bold"
                  className={styles.status}
                >
                  ACTIONS
                </Text>
                <Button
                  eth
                  block
                  rounded="full"
                  onClick={() => completeWithdrawal(card.uuid, asset.token_id)}
                  className={styles.action}
                >
                  <Text
                    uppercase
                    size="2xs"
                    type="muted"
                    weight="bold"
                    className={styles.hoverText}
                  >
                    Confirm Ethereum Transfer - Gas Fee Applies
                  </Text>
                  <SignIcon size={14} className={styles.icon} />
                  <ArrowRightIcon size={18} className={styles.icon} />
                  <EthIconPlain size={18} className={styles.icon} />
                </Button>
              </div>
            </>
          ) : card.status === 'imx_locked' && asset.status === 'imx' ? (
            <>
              <div className={styles.inImmutable}>
                <ImmutableIcon size={12} className={styles.icon} />
                <Text type="white" weight="semibold" size="2xs">
                  IN IMMUTABLE WALLET
                </Text>
              </div>
              <div className={styles.buttons}>
                <Text
                  block
                  type="muted"
                  size="2xs"
                  weight="bold"
                  className={styles.status}
                >
                  ACTIONS
                </Text>
                <Button
                  size="sm"
                  immutable
                  block
                  rounded="full"
                  onClick={() => imxToKolectiv(card.uuid, asset.token_id)}
                  className={styles.action}
                >
                  <Text
                    uppercase
                    size="2xs"
                    type="muted"
                    weight="bold"
                    className={styles.hoverText}
                  >
                    Transfer from Immutable to Kolectiv
                  </Text>
                  <ImmutableIcon size={18} className={styles.icon} />
                  <ArrowRightIcon size={18} className={styles.icon} />
                  <KolectivIcon size={18} className={styles.icon} />
                </Button>
                <Button
                  eth
                  block
                  size="sm"
                  rounded="full"
                  onClick={() => imxToMainnet(card.uuid, asset.token_id)}
                  className={styles.action}
                >
                  <Text
                    uppercase
                    size="2xs"
                    type="muted"
                    weight="bold"
                    className={styles.hoverText}
                  >
                    Transfer from Immutable to Ethereum
                  </Text>
                  <ImmutableIcon size={18} className={styles.icon} />
                  <ArrowRightIcon size={18} className={styles.icon} />
                  <EthIconPlain size={20} className={styles.icon} />
                </Button>
              </div>
            </>
          ) : card.status === 'imx_locked' &&
            asset.status === 'preparing_withdrawal' ? (
            <div className={styles.inProcessing}>
              <ZKRollUpIcon size={12} className={styles.icon} />
              <Text type="white" weight="semibold" size="2xs">
                AWAITING ROLL UP
              </Text>
            </div>
          ) : card.status === 'eth_locked' && asset.status === 'eth' ? (
            <>
              <div className={styles.inWallet}>
                <EthIconPlain size={12} className={styles.icon} />
                <Text type="white" weight="semibold" size="2xs">
                  IN ETHEREUM WALLET
                </Text>
              </div>
              <div className={styles.buttons}>
                <Text
                  block
                  type="muted"
                  size="2xs"
                  weight="bold"
                  className={styles.status}
                >
                  ACTIONS
                </Text>
                <Button
                  block
                  immutable
                  rounded="full"
                  onClick={() => deposit(card.uuid, asset.token_id)}
                  className={styles.action}
                >
                  <Text
                    uppercase
                    size="2xs"
                    type="muted"
                    weight="bold"
                    className={styles.hoverText}
                  >
                    Transfer From Ethereum To Immutable
                  </Text>
                  <EthIconPlain size={20} className={styles.icon} />
                  <ArrowRightIcon size={18} className={styles.icon} />
                  <ImmutableIcon size={18} className={styles.icon} />
                </Button>
              </div>
            </>
          ) : (
            <div className={styles.inProcessing}>
              <Spinner size={18} className={styles.icon} />
              <Text type="white" weight="semibold" size="2xs">
                PROCESSING
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetVaultItem;
