import { MutableRefObject, useEffect, useRef } from 'react';

type Ops = {
  ref?: MutableRefObject<HTMLElement | null>;
  fireOnWindowBlur?: boolean;
  ignore?: boolean;
};

/**
 * Accepts a Ref in `ops` or returns a ref to use
 * @param cb callback fn
 * @param ops options
 * @param dependencies hooks deps
 */
export default function useOnOutsideClick(
  cb: () => void,
  ops: Ops = {
    ref: undefined,
    fireOnWindowBlur: false,
    ignore: false, // do not fire if `ignore` is truthy
  },
  dependencies: any[] = []
) {
  const internalRef = useRef<HTMLElement | Element | null>(null);
  const setRef = (x: HTMLElement | Element | null) => {
    internalRef.current = x;
  };
  const ref = internalRef || ops?.ref;

  const fn = useRef<() => void>(cb);
  useEffect(() => {
    fn.current = cb;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cb, ref, ...dependencies]);

  const clickHandler = (e: globalThis.MouseEvent): void => {
    if (ops.ignore !== false) {
      if (!ref?.current?.contains(e.target as Node)) {
        fn.current?.();
      }
    }
  };

  const blurHandler = () => fn.current?.();

  useEffect(() => {
    if (ops.ref?.current) {
      document.addEventListener('click', clickHandler);
      if (ops?.fireOnWindowBlur) {
        window.addEventListener('blur', blurHandler);
      }
    }
    // cleanup
    return () => {
      document.removeEventListener('click', clickHandler);
      if (ops?.fireOnWindowBlur)
        window.removeEventListener('blur', blurHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ops.ref, ops.fireOnWindowBlur, ...dependencies]);

  return setRef;
}
