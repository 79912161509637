import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import useNotifications from 'hooks/useNotifications';

import Text from 'shared_components/Text';

import styles from './styles.module.scss';
import { CheckIcon, CloseIcon, TimerIcon } from 'shared_components/SVGIcons';

import ReactAudioPlayer from 'react-audio-player';
import notif from 'assets/sounds/notif.mp3';

const Notifications = () => {
  const { notifications } = useNotifications();
  return notifications.length
    ? ReactDOM.createPortal(
        <div className={styles.notifications}>
          {[...notifications].reverse().map((notification) => (
            <Notification
              key={notification.id}
              id={notification.id as string}
              subtitle={notification.subtitle}
              timeout={notification.timeout}
              title={notification.title}
              type={notification.type}
              onClick={notification.onClick}
            />
          ))}
        </div>,
        document.body
      )
    : null;
};

type Props = {
  id: string;
  subtitle?: string;
  timeout?: number;
  title?: string;
  type?: 'primary' | 'secondary' | 'processing';
  onClick?: () => void;
};

const Notification = (props: Props) => {
  const {
    id,
    subtitle,
    title,
    timeout = 5000000,
    type = 'primary',
    onClick,
  } = props;
  const { removeNotification } = useNotifications();
  const handleRemove = useCallback(() => removeNotification(id), [
    id,
    removeNotification,
  ]);
  const [exiting, setExiting] = useState(false);
  useEffect(() => {
    let timeoutRemove: NodeJS.Timeout;
    const timeoutExiting = setTimeout(() => {
      timeoutRemove = setTimeout(handleRemove, 300);
      setExiting(true);
    }, timeout);
    return () => {
      clearTimeout(timeoutExiting);
      clearTimeout(timeoutRemove);
    };
  }, [handleRemove, timeout]);
  return (
    <div
      role="button"
      className={cx(styles.notification, {
        [styles.exiting]: exiting,
        [styles.primary]: type === 'primary',
        [styles.secondary]: type === 'secondary',
        [styles.processing]: type === 'processing',
      })}
      onClick={() => {
        if (onClick) onClick();
        handleRemove();
      }}
    >
      <ReactAudioPlayer src={notif} autoPlay volume={1} />
      <div className={styles.countdown}>
        <div className={styles.time}></div>
      </div>
      <div className={styles.icon}>
        {type === 'primary' ? (
          <CheckIcon type="white" size={24} />
        ) : type === 'secondary' ? (
          <CloseIcon type="white" size={24} />
        ) : (
          <TimerIcon type="white" size={24} />
        )}
      </div>

      <div className={styles.messageInfo}>
        <Text as="h2" size="base" weight="bold" className={styles.title}>
          {title}
        </Text>
        <Text as="p" size="sm" type="white" className={styles.subtitle}>
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export default Notifications;
