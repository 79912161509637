import create from 'zustand';

type CollectionsState = {
  collections: Record<number, Collection>;
  collectionsBySeason: Record<string, Collection[]>;
  getCollection: (id: number) => Collection;
  setCollection: (collection: Collection) => void;
  getCollectionsBySeason: (season: string) => Collection[];
};

const useCollectionsStore = create<CollectionsState>((set, get) => ({
  collections: {},
  collectionsBySeason: {},
  getCollection: (id) => {
    const { collections } = get();
    return collections[id];
  },
  setCollection: (collection) =>
    set((state) => {
      const newCollections = {
        ...state.collections,
        [collection.id]: collection,
      };
      const newCollectionsBySeason = { ...state.collectionsBySeason };
      collection.properties.seasons.forEach((season) => {
        if (newCollectionsBySeason[season]) {
          newCollectionsBySeason[season].push(collection);
        } else {
          newCollectionsBySeason[season] = [collection];
        }
      });
      return {
        collections: newCollections,
        collectionsBySeason: newCollectionsBySeason,
      };
    }),
  getCollectionsBySeason: (season) => {
    const { collectionsBySeason } = get();
    return collectionsBySeason[season] || [];
  },
}));

export default useCollectionsStore;
