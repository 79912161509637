import { useCallback, useState } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { addMinutes, differenceInMinutes } from 'date-fns';

import useAuth from './useAuth';
import { useSettings } from 'api/settings';
import storage from 'utils/storage';

export const PERSIST_KEY = 'persist:settings';

export const useEthUSDPrice = () =>
  useSettingsStore(useCallback((state) => state.eth?.usd.price, []));

const useSettingsStore = create<SettingsState>(
  persist(
    (set) => ({
      setSettingsState: (nextState) =>
        set((current) => ({ ...current, ...nextState })),
    }),
    {
      name: PERSIST_KEY,
      whitelist: ['eth', 'seasons', 'treatment'],
      getStorage: () => storage,
    }
  )
);

export const usePersistedSettings = () => {
  const setSettingsState = useSettingsStore((state) => state.setSettingsState);

  ///////////////////////////////////////////////////
  // eth-pricing refetch interval code
  const ethLastUpdated = useSettingsStore(
    (state) => state.eth?.usd.lastUpdated
  );
  const [skipFetching, skip] = useState<boolean>(
    Boolean(
      ethLastUpdated &&
        differenceInMinutes(new Date(), Date.parse(ethLastUpdated)) < 10
    )
  );
  const [nextFetchMs, setFetchMs] = useState(
    ethLastUpdated ? getTimeRemaining(ethLastUpdated) : mins(5)
  );

  //////////////////////////////////////////////////

  const { jwt } = useAuth();
  const { data, ...rest } = useSettings(skipFetching, jwt, {
    refreshInterval: nextFetchMs,
    onSuccess: (data) => {
      if (data?.data) {
        setSettingsState(data.data);

        ///////////////////////////////////////////////////
        // eth-pricing refetch interval code
        if (data?.data?.eth) {
          if (skipFetching) skip(false);

          setFetchMs(() => {
            const ms = data?.data?.eth?.usd?.lastUpdated
              ? getTimeRemaining(data.data.eth.usd.lastUpdated)
              : mins(5);

            // just for dev purposes
            if (process.env.NODE_ENV === 'development') {
              console.log(
                `%c[dev]%c[${new Date().toLocaleTimeString()}] Eth price updated (⏱${(
                  ms /
                  (1000 * 60)
                )
                  .toFixed(2)
                  .slice(0, 4)
                  .replace(/\.?0*$/, '')} mins)`,
                'color:turquoise',
                'color:darkgray'
              );
            }

            return ms;
          });
        }
        //////////////////////////////////////////////////
      }
    },
  });

  return { data, ...rest };
};

const mins = (n: number) => 1000 * 60 * n;

const bufferForApi = 3000; // allow API a brief moment to update itself

const getTimeRemaining = (timeStr: string) =>
  (Math.max(
    0,
    Math.min(mins(10), +addMinutes(new Date(timeStr), 10) - Date.now())
  ) || mins(10)) + bufferForApi;

type SettingsState = Partial<Settings> & {
  setSettingsState: (s: Partial<Settings>) => void;
};

export default useSettingsStore;
