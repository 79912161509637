import request from './request';
import useRequestInfinite, { InfiniteConfig } from './swrInfinite';

type OffersResponse = {
  total: number;
  offers: Offer[];
};

export const useOffersReceived = (config?: InfiniteConfig<OffersResponse>) =>
  useRequestInfinite<OffersResponse>((index, prevPageData) => {
    if (prevPageData && !prevPageData.data.offers.length) return null;
    return { url: `/offer/received`, params: { page: index + 1 } };
  }, config);

export const useOffersSent = (config?: InfiniteConfig<OffersResponse>) =>
useRequestInfinite<OffersResponse>((index, prevPageData) => {
  if (prevPageData && !prevPageData.data.offers.length) return null;
  return { url: `/offer/sent`, params: { page: index + 1 } };
}, config);

export const makeOffer = (data: {
  id: number;
  price: string;
  type: ItemType;
}) =>
  request.post<{ marketId: number }>(`/offer/new`, {
    entityType: data.type,
    entityId: data.id,
    price: data.price,
  });

export const acceptOffer = (offerId: number) =>
  request.patch<{ marketId: number }>(`/offer/accept/${offerId}`);

export const declineOffer = (offerId: number) =>
  request.patch<{ marketId: number }>(`/offer/decline/${offerId}`);

export const deleteOffer = (offerId: number) =>
request.delete<{ marketId: number }>(`/offer/${offerId}`);
