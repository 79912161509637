type Props = {
  text?: string;
  width?: number;
  height?: number;
  backgroundColor?: string; // hex-code WITHOUT the hash
  textColor?: string; // hex-code WITHOUT the hash
  format?: 'gif' | 'jpg' | 'jpeg' | 'png' | 'webp';
  block?: boolean;
  rounded?: 'px' | 'sm' | 'md' | 'lg' | 'full';
};

enum Rounded {
  'px' = '1px',
  'sm' = '4px',
  'md' = '8px',
  'lg' = '14px',
  'full' = '9999px',
}

/** reference https://placeholder.com/ */
const PlaceholderImage = ({
  text = 'placeholder_image',
  width = 100,
  height = 100,
  backgroundColor = '1f2128',
  textColor = '313645',
  format = 'gif',
  block,
  rounded,
  ...rest
}: Props) => (
  <img
    src={`https://via.placeholder.com/${width}x${height}${
      format ? '.' + format : ''
    }${backgroundColor ? '/' + backgroundColor : ''}${
      backgroundColor && textColor ? '/' + textColor : ''
    }${text ? '?text=' + text.replace(/\s/g, '+') : ''}`}
    alt="placeholder"
    style={{
      ...(block ? { display: 'block' } : {}),
      ...(rounded ? { borderRadius: Rounded[rounded] } : {}),
    }}
    {...rest}
  />
);

export default PlaceholderImage;
