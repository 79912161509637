import { JWT_HEADER_KEY } from './request';
import useRequest, { Config } from './swr';

export const useSettings = (
  skip?: boolean,
  jwt?: string,
  config?: Config<Settings>
) =>
  useRequest<Settings>(
    !skip
      ? {
          url: `/settings/web`,
          ...(jwt && { headers: { [JWT_HEADER_KEY]: jwt } }),
        }
      : null,
    config
  );
