import { useMenuState } from '@szhsin/react-menu';
import { debounce } from 'lodash';
import { useRef, useState } from 'react';
import InputText from 'shared_components/InputText';

import styles from './styles.module.scss';
import cx from 'classnames';
import SearchResults from './SearchResults';

type Props = {
  className?: string;
};

const UserSearchBar = ({ className }: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  const ref = useRef<HTMLInputElement | null>(null);
  const {
    isMounted,
    isOpen,
    menuItemFocus,
    openMenu,
    closeMenu,
  } = useMenuState();
  const controlledMenuProps = {
    isMounted,
    isOpen,
    menuItemFocus,
    anchorRef: ref,
  };

  const handleInput = debounce((input: string) => {
    setSearchInput(input);
  }, 500);

  return (
    <>
      <InputText
        className={cx(styles.UserInput, className)}
        onChange={(input) => {
          if (!input && searchInput) setSearchInput('');
          else handleInput(input);
        }}
        onFocus={() => {
          setInputFocused(true);
          if (searchInput && !isOpen) {
            openMenu();
          }
        }}
        onBlur={() => setInputFocused(false)}
        placeholder="Search users..."
        type="search"
        ref={ref}
        onKeyDown={(e) => {
          if (isOpen) {
            if (e.key === 'Escape') return closeMenu();
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              const el = document
                .getElementById('user_search')
                ?.querySelector('[role="menuitem"]') as HTMLElement;
              el?.focus?.();
            }
          } else if (searchInput) openMenu();
        }}
      />

      {searchInput && (
        <SearchResults
          input={searchInput}
          inputFocused={inputFocused}
          controlledMenuProps={controlledMenuProps}
          closeMenu={closeMenu}
          openMenu={openMenu}
        />
      )}
    </>
  );
};

export default UserSearchBar;
