import { Link } from 'react-router-dom';
import cx from 'classnames';

import Text from 'shared_components/Text';
import { SVGIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

export type Props = {
  className?: string;
  icon: SVGIcon;
  iconClassName?: string;
  label: string;
  link?: string;
  onClick?: () => void;
};

const MenuNavItem = (props: Props) => {
  const { className, icon: Icon, iconClassName, label, link, onClick } = props;
  const content = (
    <>
      <div className={cx(styles.icon, iconClassName)}>
        <Icon size={18} />
      </div>
      <Text uppercase weight="bold" className={styles.label}>
        {label}
      </Text>
    </>
  );
  if (link)
    return (
      <Link to={link} className={cx(styles.menuNavItem, className)}>
        {content}
      </Link>
    );
  return (
    <div onClick={onClick} className={cx(styles.menuNavItem, className)}>
      {content}
    </div>
  );
};

export default MenuNavItem;
