import {
  ChangeEvent,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
} from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export type InputTextProps = {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  max?: number;
  min?: number;
  name?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  required?: boolean;
  step?: number;
  type?: 'text' | 'date' | 'time' | 'email' | 'password' | 'number' | 'search';
  value?: string;
  defaultValue?: string;
  onKeyDown?: KeyboardEventHandler;
};

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (props: InputTextProps, ref) => {
    const {
      autoFocus,
      className,
      disabled,
      max,
      min,
      name,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      placeholder,
      required,
      step,
      type,
      value,
      defaultValue,
    } = props;
    const cn = cx(styles.inputText, className);
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value),
      [onChange]
    );
    return (
      <input
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        className={cn}
        disabled={disabled}
        max={max}
        min={min}
        name={name}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        required={required}
        step={step}
        type={type}
        value={value}
        defaultValue={defaultValue}
      />
    );
  }
);

InputText.displayName = 'InputText';

export default InputText;
