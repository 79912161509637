import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';
import Web3 from 'web3';

import useOrderState from 'hooks/useOrderState';
import useAlertSocket from 'hooks/useAlertSocket';
import useSettingsStore from 'hooks/useSettingsStore';
import usePrevious from 'hooks/usePrevious';
import { useSettings } from 'api/settings';
import { stringifyUrlWithParam } from 'utils/routes';
import { getEtherscanTransactionLink } from 'utils/etherscan';
import { ContractAddress } from 'constants/contract';
import storage from 'utils/storage';

import Spinner from 'shared_components/Spinner';
import Text from 'shared_components/Text';
import { CloseIcon, CheckIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

import abi from 'constants/purchaseAbi';

type PackOrderAlertData = {
  amount: number;
  orderId: number;
  packTemplateId: number;
};

const clearOrderLocalData = () => {
  storage.removeItem('orderId');
  storage.removeItem('orderTxHash');
};

const PackProcessingBar = () => {
  const history = useHistory();

  const {
    orderId,
    transactionHash,
    clearOrderId,
    clearTransactionHash,
  } = useOrderState();
  const prevOrderId = usePrevious(orderId);
  const [currentStatus, setCurrentStatus] = useState<string>();
  const [hidden, setHidden] = useState(Boolean(orderId));
  const [succeeded, setSucceeded] = useState(false);

  const skipInitialFetch = Boolean(!orderId || hidden);

  const persistedSettings = (useSettingsStore() || undefined) as
    | Settings
    | undefined;
  const { data } = useSettings(skipInitialFetch, undefined, {
    initialData: persistedSettings,
    revalidateOnFocus: !skipInitialFetch,
    revalidateOnReconnect: !skipInitialFetch,
  });
  const contractAddress = data?.eth?.depositContract
    ? data.eth.depositContract
    : ContractAddress.address;

  const poll = useRef<NodeJS.Timeout | null>();
  const clearPoll = useCallback(() => {
    if (poll.current) clearInterval(poll.current);
    poll.current = null;
  }, []);

  const clearData = useCallback(() => {
    setTimeout(() => {
      setHidden(true);
      setCurrentStatus(undefined);
      clearOrderId();
      clearTransactionHash();
      clearOrderLocalData();
    }, 5000);
  }, [clearOrderId, clearTransactionHash]);

  const handleSucceed = useCallback(() => {
    if (!succeeded) {
      setSucceeded(true);
      setCurrentStatus('success');
      history.push(
        stringifyUrlWithParam(history.location, {
          modal: 'success',
          orderId,
          tx: transactionHash,
        })
      );
      clearData();
      clearPoll();
    }
  }, [clearData, clearPoll, history, orderId, succeeded, transactionHash]);

  useEffect(() => {
    if ((!prevOrderId && orderId) || (prevOrderId && prevOrderId !== orderId))
      setSucceeded(false);
  }, [prevOrderId, orderId]);

  useEffect(() => {
    if (orderId) {
      const fn = async () => {
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);
        const response = await contract.methods.getOrderBalance(orderId).call();
        if (response === '0') {
          setHidden(false);
          setCurrentStatus('processing');
        } else handleSucceed();
      };
      fn();
      if (!poll.current) poll.current = setInterval(fn, 10000);
      return () => {
        clearPoll();
      };
    }
  }, [orderId, contractAddress, handleSucceed, clearPoll]);

  useAlertSocket<PackOrderAlertData>('pack-order-complete', (data) => {
    if (Number(orderId) === data.orderId) handleSucceed();
  });

  useAlertSocket<PackOrderAlertData>('pack-order-expired', (data) => {
    if (Number(orderId) === data.orderId) {
      setCurrentStatus('failure');
      clearData();
      clearPoll();
    }
  });

  if (!orderId || hidden) return null;

  if (currentStatus === 'failure') {
    return (
      <div className={cx(styles.bar, styles.failure)}>
        <CloseIcon size={40} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR PACK PURCHASE FAILED, PLEASE TRY AGAIN
        </Text>
      </div>
    );
  }

  if (currentStatus === 'success') {
    return (
      <div className={cx(styles.bar, styles.success)}>
        <CheckIcon size={24} className={styles.icon} />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR PACK PURCHASE WAS SUCCESSFUL
        </Text>
      </div>
    );
  }

  if (transactionHash) {
    return (
      <div className={cx(styles.bar, styles.progress)}>
        <Spinner size={24} type="white" />
        <Text className={cx(styles.barText, styles.light)}>
          YOUR PACK PURCHASE IS IN PROGRESS
        </Text>
        <a
          href={getEtherscanTransactionLink(transactionHash)}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <Text type="white" size="sm" as="p">
            View on Etherscan
          </Text>
        </a>
      </div>
    );
  }

  return (
    <div className={cx(styles.bar, styles.progress)}>
      <Spinner size={40} type="white" />
      <Text className={cx(styles.barText, styles.light)}>
        YOUR PACK PURCHASE IS IN PROGRESS
      </Text>
    </div>
  );
};

export default PackProcessingBar;
