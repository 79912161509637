import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import useOrderState from 'hooks/useOrderState';
import Text from 'shared_components/Text';
import useQueryParams from 'hooks/useQueryParams';
import { CloseIcon } from 'shared_components/SVGIcons';
import packImage from 'assets/packs/kolectiv-2021-front.png';
import sunBeam from 'assets/animation/sunbeam.png';

import styles from './styles.module.scss';
import { getEtherscanTransactionLink } from 'utils/etherscan';

const PackSuccessModal = () => {
  const { transactionHash, quantity } = useOrderState();
  const history = useHistory();
  const { modal, tx } = useQueryParams();

  const isOpen = modal === 'success';

  const handleDismiss = useCallback(() => {
    history.push({
      pathname: history.location.pathname,
      hash: history.location.hash,
    });
  }, [history]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDismiss}
      closeTimeoutMS={300}
      className={{
        base: styles.loginModal,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      overlayClassName={{
        base: styles.loginModalOverlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
    >
      <CloseIcon
        size={60}
        role="button"
        onClick={handleDismiss}
        className={styles.closeIcon}
      />

      <div className={styles.content}>
        <img alt="Packs Owned" src={packImage} className={styles.packImage} />
        <div className={styles.packCount}>
          <Text as="h2" size="xl" type="white">
            {quantity ? quantity : 1}{' '}
          </Text>
        </div>
        <div className={styles.sunBeamWrapper}>
          <img alt="Success!" src={sunBeam} className={styles.sunBeam} />
        </div>
        <Text as="h2" size="2xl" type="white" className={styles.successText}>
          Pre Order Success!
        </Text>
        <Text as="p" size="sm" type="white" className={styles.successSubtext}>
          It may take a few minutes for your pre order packs to appear in your
          pack inventory.
        </Text>

        {transactionHash || tx ? (
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href={getEtherscanTransactionLink(transactionHash || tx)}
          >
            View on Etherscan
          </a>
        ) : null}
      </div>
    </ReactModal>
  );
};

export default PackSuccessModal;
