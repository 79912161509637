import { useCallback } from 'react';

import useRequestState from 'hooks/useRequestState';
import useNotifications from 'hooks/useNotifications';
import useSettingsStore from 'hooks/useSettingsStore';
import { useCurrentUserFunds } from 'api/user';
import { acceptOffer } from 'api/offers';

import ConfirmationModal from 'shared_components/ConfirmationModal';
import CurrencyValue from 'shared_components/CurrencyValue';
import Text from 'shared_components/Text';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  offer?: Offer;
  onDismiss: () => void;
  reload: () => Promise<void>;
};

const ConfirmAcceptOfferModal = (props: Props) => {
  const { isOpen, offer, onDismiss, reload } = props;

  const state = useRequestState();
  const { addNotification } = useNotifications();
  const { mutate: reloadUserFunds } = useCurrentUserFunds();
  const { market: marketSettings } = useSettingsStore();
  const { commission } = marketSettings ?? {};

  const handleAccept = useCallback(async () => {
    if (offer) {
      try {
        state.start();
        await acceptOffer(offer.id);
        await reload();
        await reloadUserFunds();
        addNotification({
          type: 'primary',
          title: 'Offer accepted!',
          subtitle: 'You have successfully accepted this offer.',
          timeout: 5000,
        });
        onDismiss();
        state.end();
      } catch (e) {
        state.setApiError(e);
        state.end();
      }
    }
  }, [offer, state, addNotification, onDismiss, reload, reloadUserFunds]);

  if (!offer) return null;

  return (
    <ConfirmationModal
      header="Accept Offer"
      isOpen={isOpen}
      onCancel={onDismiss}
      onConfirm={handleAccept}
      confirmButtonLoading={state.loading}
      contentClassName={styles.acceptOfferModal}
    >
      <Text centered className={styles.acceptOfferMsg}>
        Below is a breakdown of the offer being made for{' '}
        {offer?.entity?.itemName}, do you accept?
      </Text>
      <div className={styles.infoRow}>
        <div className={styles.infoColumn}>
          <Text
            block
            uppercase
            size="sm"
            type="muted"
            weight="semibold"
            className={styles.infoLabel}
          >
            How much you receive
          </Text>
          <CurrencyValue
            showUSD
            size={20}
            value={
              commission
                ? (Number(offer?.price) * (1 - percent(commission))).toFixed(8)
                : '...'
            }
            className={styles.infoValue}
          />
        </div>
        <div className={styles.infoColumn}>
          <Text
            block
            uppercase
            size="sm"
            type="muted"
            weight="semibold"
            className={styles.infoLabel}
          >
            Kolectiv fee ({commission}%*)
          </Text>
          <CurrencyValue
            size={20}
            value={
              commission
                ? (Number(offer?.price) * percent(commission)).toFixed(8)
                : '...'
            }
            className={styles.infoValue}
          />
        </div>
      </div>
    </ConfirmationModal>
  );
};

const percent = (n: number) => 0.01 * n;

export default ConfirmAcceptOfferModal;
