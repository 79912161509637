import { PropsWithChildren, useEffect, useState } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import Web3 from 'web3';
import { useRouteMatch } from 'react-router-dom';

import Text from 'shared_components/Text';
import { useCurrentUser } from 'api/user';
import useAuth from 'hooks/useAuth';
import useViewport from 'hooks/useViewport';
import breakpoints from 'constants/breakpoints';
import { Routes } from 'constants/routes';

import MenuButton, { useNavMenuItems } from './MenuButton';
import NotificationsButton from './NotificationsButton';
import OffersButton from './OffersButton';
import VaultButton from './VaultButton';
import PacksButton from './PacksButton';
import UserAvatar from './UserAvatar';
import UserBalance from './UserBalance';
import { AppHeaderLoginButton } from './AppHeaderButton';
import {
  SignedInHeaderNavItems,
  MobileSignedInHeaderNavItems,
  MobileSignedInSocialNavItems,
  MobileSignedOutHeaderNavItems,
  SignedOutHeaderNavItems,
} from './HeaderNavItems';

import AppLogo from 'shared_components/AppLogo';
import AppLogoFull from 'shared_components/AppLogoFull';
import { MenuIcon } from 'shared_components/SVGIcons';

import styles from './styles.module.scss';
import UserSearchBar from './UserSearchBar';

type Props = {
  className?: string;
};

const AppHeader = (props: Props) => {
  const { className } = props;
  const { isAuth, logout: triggerLogout } = useAuth();
  const { width } = useViewport();
  const history = useHistory();

  const { data: currentUser } = useCurrentUser(!isAuth);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { profile, transactions, logout } = useNavMenuItems();

  const { search, hash, pathname } = useLocation();

  useEffect(() => {
    setMobileMenuVisible(false);
  }, [search, hash, pathname]);

  useEffect(() => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      web3.eth.getAccounts(function (err, accounts) {
        if (err) console.error(err);
        if (accounts.length === 0) {
          triggerLogout();
          history.replace(Routes.landing);
        }
      });
    }
  }, [triggerLogout, history]);

  const signedInHeaderNavItems = (
    <SignedInHeaderNavItems
      groups={currentUser?.groups}
      className={styles.navItems}
    />
  );
  const mobileSignedInSocialNavItems = (
    <MobileSignedInSocialNavItems
      groups={currentUser?.groups}
      className={styles.navItems}
    />
  );
  const mobileSignedInHeaderNavItems = (
    <MobileSignedInHeaderNavItems
      groups={currentUser?.groups}
      className={styles.navItems}
    />
  );
  const mobileSignedOutHeaderNavItems = (
    <MobileSignedOutHeaderNavItems className={styles.navItems} />
  );
  const vaultButton = <VaultButton className={styles.vault} />;
  const offersButton = <OffersButton className={styles.offers} />;
  const packsButton = <PacksButton className={styles.packs} />;
  const userBalance = <UserBalance className={styles.balance} />;
  const notificationsButton = (
    <NotificationsButton className={styles.notifications} />
  );
  const userAvatar = <UserAvatar className={styles.avatar} />;
  const menuButton = <MenuButton className={styles.menu} />;
  const userSearchBar = <UserSearchBar className={styles.searchBar} />;

  const match = useRouteMatch();
  const isLanding = match.isExact && match.path === Routes.landing;

  return (
    <header className={cx(styles.appHeader, className)}>
      <nav
        className={cx(styles.headerNav, isLanding && styles.landingNav, {
          [styles.isMobileMenuVisible]: isMobileMenuVisible,
        })}
      >
        {/********************************************
          MAKE SURE TO EDIT BOTH MOBILE AND DESKTOP
        *********************************************/}
        {isAuth ? (
          <>
            <MobileView>
              {mobileSignedInHeaderNavItems}
              {isMobileMenuVisible && (
                <MobileView.Menu>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'auto',
                    }}
                  >
                    {userAvatar}
                    {userBalance}
                  </div>
                  {/* {mobileSignedInHeaderNavItems} */}
                  {mobileSignedInSocialNavItems}
                  <div className={styles.navMenuItems}>
                    <hr />
                    <button {...profile.props}>
                      <Text type="muted" weight="bold">
                        {profile.render}
                      </Text>
                    </button>
                    <button {...transactions.props}>
                      <Text type="muted" weight="bold">
                        {transactions.render}
                      </Text>
                    </button>
                    <hr />
                    <button {...logout.props}>
                      <Text type="muted" weight="bold">
                        {logout.render}
                      </Text>
                    </button>
                  </div>
                </MobileView.Menu>
              )}
              <div
                /* spacer */
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AppLogo className={styles.appLogo} />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {offersButton}
                {vaultButton}
                {packsButton}

                {notificationsButton}
                <button
                  className={styles.menu}
                  onClick={() => setMobileMenuVisible((x) => !x)}
                >
                  <MenuIcon size={18} />
                </button>
              </div>
            </MobileView>
            <DesktopView>
              <div className={styles.logo}>
                <AppLogo className={styles.appLogoFull} />
              </div>
              {signedInHeaderNavItems}

              <div className={styles.userNavItems}>
                {userSearchBar}
                {offersButton}
                {vaultButton}
                {packsButton}
                {userBalance}
                {notificationsButton}
                {userAvatar}
                {menuButton}
              </div>
            </DesktopView>
          </>
        ) : (
          <>
            <MobileView>
              {mobileSignedOutHeaderNavItems}
              {isMobileMenuVisible && <MobileView.Menu></MobileView.Menu>}
              <div
                /* spacer */
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AppLogo className={styles.appLogo} />
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <button
                  className={styles.menu}
                  onClick={() => setMobileMenuVisible((x) => !x)}
                >
                  <MenuIcon size={20} />
                </button>
              </div>
            </MobileView>
            <DesktopView>
              <div className={styles.logo}>
                {isLanding ? (
                  <AppLogoFull className={styles.appLogoFull} />
                ) : (
                  <AppLogo className={styles.appLogoFull} />
                )}
              </div>
              <SignedOutHeaderNavItems
                className={styles.navItems}
                isMobileMenuVisible={isMobileMenuVisible}
              />
              <div
                /* spacer */
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <AppHeaderLoginButton className={styles.navButton}>
                  {width >= breakpoints.tablet ? 'CONNECT WALLET' : ''}
                </AppHeaderLoginButton>
              </div>
            </DesktopView>
          </>
        )}
      </nav>
    </header>
  );
};

const MobileView = (props: PropsWithChildren<unknown>) => {
  return <div className={styles.mobileView}>{props.children}</div>;
};

const Menu = (props: PropsWithChildren<unknown>) => {
  return <div className={styles.mobileView__menu}>{props.children}</div>;
};

MobileView.Menu = Menu;

const DesktopView = (props: PropsWithChildren<unknown>) => {
  return <div className={styles.desktopView}>{props.children}</div>;
};

export default AppHeader;
