import storage from './storage';

export const JWT_KEY = 'auth:jwt';
export const EXPIRES_KEY = 'auth:expires';
export const REFRESH_TOKEN_KEY = 'auth:refresh';

export const getToken = () => {
  const jwt = storage.getItem(JWT_KEY);
  const expires = storage.getItem(EXPIRES_KEY);
  const refreshToken = storage.getItem(REFRESH_TOKEN_KEY);
  if (!jwt || !expires || !refreshToken) return null;
  return { jwt, expires: Number(expires), refreshToken } as Token;
};

export const saveToken = (token: Token) => {
  storage.setItem(JWT_KEY, token.jwt);
  storage.setItem(EXPIRES_KEY, token.expires.toString());
  storage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
};

export const deleteToken = () => {
  storage.removeItem(JWT_KEY);
  storage.removeItem(EXPIRES_KEY);
  storage.removeItem(REFRESH_TOKEN_KEY);
};
