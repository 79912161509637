import { memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { getImageCdnUrl } from 'utils/images';

import VerifiedBadge from 'shared_components/VerifiedBadge';
import {
  FaceHappyIcon,
  FaceCoolIcon,
  FaceClosedEyesIcon,
  FaceSwirlIcon,
  FaceToothIcon,
} from 'shared_components/SVGIcons';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  isEpicsCdn?: boolean;
  groups?: [number];
  group?: number;
  loading?: 'lazy';
  size?: number;
  src?: string;
  thumbnailSize?: boolean;
  squared?: boolean;
  to?: string;
  full?: boolean;
};

const Avatar = (props: Props) => {
  const {
    className,
    groups,
    squared,
    group,
    isEpicsCdn = true,
    loading,
    size = 40,
    full = false,
    src: _src,
    to,
  } = props;

  const src =
    _src?.includes('default.png') || (_src && storedResults.has(_src))
      ? ''
      : _src;

  const [hasLoaded, setLoaded] = useState(false);
  const [hasErrored, setErrored] = useState(false);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);
  const onError = useCallback(() => {
    if (src) storeResult(src);
    setErrored(true);
  }, [src]);

  const wrapperProps = {
    className: cx(styles.avatar, className),
    style: { width: size, height: size },
  };

  const Wrapper = ({ children: x }: PropsWithChildren<unknown>) =>
    to ? (
      <Link {...wrapperProps} to={to}>
        {x}
      </Link>
    ) : (
      <div {...wrapperProps}>{x}</div>
    );

  const avatarIcons = [
    FaceHappyIcon,
    FaceCoolIcon,
    FaceClosedEyesIcon,
    FaceSwirlIcon,
    FaceToothIcon,
  ];
  const avatarColors = [
    '#8C9FDE',
    '#DEAA8C',
    '#C0DE8C',
    '#8CDEDB',
    '#DECB8C',
    '#DE8C8C',
    '#A68CDE',
    '#C08CDE',
    '#DE8CB6',
    '#D7DE8C',
    '#8CDEAD',
  ];
  const randomAvatarIcon =
    avatarIcons[Math.floor(Math.random() * avatarIcons.length)];

  const randomAvatarColor =
    avatarColors[Math.floor(Math.random() * avatarColors.length)];

  return (
    <Wrapper>
      {src && !hasErrored ? (
        <img
          alt="Avatar"
          onLoad={onLoad}
          onError={onError}
          loading={loading}
          src={getImageCdnUrl(src, props.thumbnailSize, isEpicsCdn)}
          className={cx(styles.userAvatar, squared && styles.squared, full && styles.full)}
          style={{ width: size, height: size, opacity: hasLoaded ? 1 : 0 }}
        />
      ) : (
        <div className={styles.fallbackWrapper} style={{backgroundColor: randomAvatarColor}} >
          {randomAvatarIcon === FaceHappyIcon ? (
            <FaceHappyIcon
              size={Math.floor(size * 0.95)}
              className={styles.fallbackSvg}
            />
          ) : randomAvatarIcon === FaceSwirlIcon ? (
            <FaceSwirlIcon
              size={Math.floor(size * 0.95)}
              className={styles.fallbackSvg}
            />
          ) : randomAvatarIcon === FaceClosedEyesIcon ? (
            <FaceClosedEyesIcon
              size={Math.floor(size * 0.95)}
              className={styles.fallbackSvg}
            />
          ) : randomAvatarIcon === FaceCoolIcon ? (
            <FaceCoolIcon
              size={Math.floor(size * 0.95)}
              className={styles.fallbackSvg}
            />
          ) : randomAvatarIcon === FaceToothIcon ? (
            <FaceToothIcon
              size={Math.floor(size * 0.95)}
              className={styles.fallbackSvg}
            />
          ) : null}
        </div>
      )}
      {groups ? <VerifiedBadge size={size} verifiedType={group ? [group] : groups} /> : null}
    </Wrapper>
  );
};

const storedResults = new Set<string>();
const storeResult = (str: string) => {
  if (str) {
    if (storedResults.size >= 1000) {
      storedResults.clear();
    }
    storedResults.add(str);
  }
};

export default memo(Avatar);
